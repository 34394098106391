import { HoloVideoObjectStates } from "../../HoloMediaPlayer/HoloMediaPlayer/constants";
import { IHoloVideoObjectThreeJS } from "../../HoloMediaPlayer/HoloMediaPlayer/interfaces";
import { VolumetricComponentInstance } from "../../volumetric/interfaces";

class StepByStepVolumetricPlayer {
  components: VolumetricComponentInstance[] = [];

  constructor() {
    this.openNextHVO();
  }

  addComponent = (component: VolumetricComponentInstance) => {

    this.components.push(component);

    const {
      data: { src },
      hvo
    } = component;

    if (!hvo) {
      console.error("Can not get hvo");
      return;
    }
  };

  removeComponent = (component: VolumetricComponentInstance) => {
    component.hvo?.close();

    this.components = this.components.filter(n => n !== component);
  };

  /**
   * Поочередно подгружаем данные плейеров
   */
  openNextHVO = () => {
    if (this.components.length) {
      /**
       * Проверяем есть ли уже плейер, нуждающийся в открытии
       */
      const haveOpening = this.components.findIndex(n => n.hvo?.hvo.state === HoloVideoObjectStates.Opening) !== -1;

      if (!haveOpening) {
        /**
         * Если нету, получаем пустой или закрытый
         */
        const emptyPlayerComponent = this.components.find(
          n => n?.hvo?.hvo && n.hvo.hvo.state <= HoloVideoObjectStates.Empty
        );

        if (emptyPlayerComponent) {
          const { src } = emptyPlayerComponent.data;

          emptyPlayerComponent.hvo?.open(src, {
            keepAllMeshesInMemory: true,
            autoloop: true,
            audioEnabled: true,
            autoplay: false
          });
        }
      }
    }

    /**
     * С заданным периодом будем проверять есть ли видео на подгрузку
     */
    setTimeout(this.openNextHVO, 1000);
  };

  play = (hvo: IHoloVideoObjectThreeJS) => {
    if (
      hvo.state === HoloVideoObjectStates.Empty ||
      hvo.state === HoloVideoObjectStates.Opening ||
      hvo.state === HoloVideoObjectStates.Closed
    ) {
      /**
       * Закрываем все другие волюметрики
       */
      this.components
        .filter(n => n.hvo !== hvo)
        .filter(n => n.hvo?.hvo.state === HoloVideoObjectStates.Playing)
        .forEach(n => {
          n.hvo?.close();
        });

      hvo.play();
    }
  };
}

export const stepByStepVolumetricPlayer = new StepByStepVolumetricPlayer();

// TODO Remove
// @ts-expect-error
window.stepByStepVolumetricPlayer = stepByStepVolumetricPlayer;
