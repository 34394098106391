import avatar1 from "../../../assets/custom/photshoot/avatars/avatar-1.png";
import avatar2 from "../../../assets/custom/photshoot/avatars/avatar-2.png";
import avatar3 from "../../../assets/custom/photshoot/avatars/avatar-3.png";
import avatar4 from "../../../assets/custom/photshoot/avatars/avatar-4.png";
import avatar5 from "../../../assets/custom/photshoot/avatars/avatar-5.png";
import { getAvatarIndex } from "../../../utils/identity";


const avatars = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
];


/**
 * Есть переменная avatars[0], которая является ссылкой на изображение.
 * Надо создать canvas, отрисовать в него изображение и вернуть File.
 * Написать в typescript
 */
// export function getAvatarPhoto(): Promise<File> {

//   const avatarIndex = getAvatarIndex()

//   return new Promise((resolve, reject) => {
//     const canvas = document.createElement("canvas");
//     const context = canvas.getContext("2d");
    
//     // create image element and load avatar
//     const image = new Image();

//     // https://stackoverflow.com/a/22716873/20470932
//     image.crossOrigin="anonymous"

//     image.src = avatars[avatarIndex ?? 0];
//     image.onload = () => {
//       canvas.width = image.width;
//       canvas.height = image.height;

//       // draw image onto canvas
//       context?.drawImage(image, 0, 0);

//       // convert canvas to file
//       canvas.toBlob((blob) => {
//         if(!blob) {
//           reject(new Error("Can not get blob"))
//           return;
//         }
//         // else
//         const file = new File([blob], "avatar.png", { type: "image/png" });
//         resolve(file);

//       });
//     };
//     image.onerror = (error) => {
//       reject(error);
//     };
//   });
// }

function urltoFile(url: string, filename: string, mimeType = "image/png") {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}


export function getAvatarPhoto(): Promise<File> {

  const avatarIndex = getAvatarIndex()
  const src = avatars[avatarIndex ?? 0];

  return urltoFile (src, "snap.png");
}
