import React, { useCallback, useEffect, useState } from "react";
import { AElementECS } from "aframe";
import { addComponent, removeComponent } from "bitecs";
import { Object3D } from "three";
import { RemoteHoverTarget } from "../../../bit-components";
import { MainViewportModal } from "../../react-components/modals/MainViewportModal";
import { AskBossForm } from "../AskBoss_Layout/AskBossForm";
import { isAuthed } from "../../UnauthorizedAlert/helpers";

type AskBossModelProps = {
  mesh: Object3D;
};

/**
 * Конечный интерфейс для отдельно взятой будки
 */
export const AskBossModel: React.FC<AskBossModelProps> = ({ mesh }) => {
  const [opened, openedSetter] = useState(false);

  const openModal = useCallback(() => {
    openedSetter(true);
  }, []);

  const closeModal = useCallback(() => {
    openedSetter(false);
  }, []);

  useEffect(() => {
    // @ts-expect-error
    const el: AElementECS = mesh.el;
    //  const el: AElementECS = APP.scene;

    const onClick = () => {
      /**
       * Если не авторизован, то не делаем фото
       */
      if (!isAuthed()) {
        return;
      }

      // else
      openModal();
    };

    // TODO Сейчас атрибуты и компоненты навешиваются на саму сцену
    // Надо переделать на самостоятельные объекты с экстеншеном
    addComponent(APP.world, RemoteHoverTarget, el.eid);
    el.classList.add("interactable");
    el.setAttribute("tags", { singleActionButton: true });

    mesh.addEventListener("custom-click", onClick);

    return () => {
      removeComponent(APP.world, RemoteHoverTarget, el.eid);
      el.classList.remove("interactable");
      el.setAttribute("tags", { singleActionButton: undefined });
      mesh.removeEventListener("custom-click", onClick);
    };
  }, []);

  return opened ? (
    <MainViewportModal>
      <AskBossForm handleCloseModalWindow={closeModal} />
    </MainViewportModal>
  ) : null;
};
