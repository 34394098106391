/** @jsx createElementEntity */
import { createElementEntity, createRef, renderAsEntity } from "../../utils/jsx-entity";
import buttonSrc from "../../assets/hud/button.9.png";
import buttonSrc30 from "../../assets/hud/btn_transparent_30.png";
import buttonSrc50 from "../../assets/hud/btn_transparent_50.png";
import buttonSrc80 from "../../assets/hud/btn_transparent_80.png";
import buttonSrc100 from "../../assets/hud/btn_transparent_100.png";
import { textureLoader } from "../../utils/media-utils";
import { BUTTON_TYPES } from "../../systems/single-action-button-system";
import { Layers } from "../../components/layers";
import { changeHub } from "../../change-hub";
// import { isHubsRoomUrl, isLocalHubsUrl } from "../../utils/media-url-utils";
import { getNextHub } from "../../utils/get-next-hub";
import configs from "../../utils/configs";

export const btnTextures = {
  transparent_0:   textureLoader.load(buttonSrc),
  transparent_30:  textureLoader.load(buttonSrc30),
  transparent_50:  textureLoader.load(buttonSrc50),
  transparent_80:  textureLoader.load(buttonSrc80),
  transparent_100: textureLoader.load(buttonSrc100),
};

export function ButtonSprite({
  ...props
}) {
  return (
    <entity
      name={"Button"}
      cursorRaycastable
      remoteHoverTarget
      hoverButton={BUTTON_TYPES.ACTION}
      singleActionButton
      layers={1 << Layers.CAMERA_LAYER_UI}
      {...props}
    />
  );
}

// eslint-disable-next-line react/prop-types
export function ButtonEntity({
  text = "test",
  size = [1, 1],
  texture = btnTextures.transparent_0,
  type = BUTTON_TYPES.ACTION,
  ...props
}) {
  const labelRef = createRef();
  return (
    <entity
      name={"Button"}
      slice9={{ size, insets: [64, 66, 64, 66], texture }}
      cursorRaycastable
      remoteHoverTarget
      hoverButton={{ type }}
      textButton={{ labelRef }}
      singleActionButton
      layers={1 << Layers.CAMERA_LAYER_UI}
      {...props}
    >
      <entity
        ref={labelRef}
        layers={1 << Layers.CAMERA_LAYER_UI}
        text={{
          value: text,
          side: THREE.FrontSide,
          color: "#000000",
          textAlign: "center",
          anchorX: "center",
          anchorY: "middle",
          fontUrl: `${configs.BASE_ASSETS_PATH}assets/fonts/SB-Sans-Interface.ttf`
        }}
        position={[0, 0, 0.01]}
        name={props.name ? `${props.name} Label` : "Button Label"}
        visible={text.length > 1}
      />
    </entity>
  );
}

export function Button3DObj(
  text = "test",
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  size = [1, 1],
  scale = [1, 1, 1],
  texture = btnTextures.transparent_0,
  onClick
) {
  const eid = renderAsEntity(APP.world, ButtonEntity({ text, position, rotation, size, scale, texture }));
  const obj = APP.world.eid2obj.get(eid);
  obj.prototype = Object.create(THREE.EventDispatcher.prototype);
  obj.addEventListener("custom-click", onClick);
  return obj;
}

/**
 * Ссылка для перехода в хаб
 */
export function createHubLinkButton(el, _componentName, componentData) {
  el.object3D.add(
    Button3DObj("", [0, 0, 0], [0, 0, 0], [2, 0.6], [0.5, 2, 0.2], btnTextures.transparent_80, async () => {
      if (APP.store.state.preferences.fastRoomSwitching) {
        const url = new URL(componentData.href);
        // console.log("createHubLinkButton url", url);

        const params = new URLSearchParams(url.search);

        // console.log("createHubLinkButton params", params);
        const hubName = params.get("event");

        if (hubName) {
          getNextHub(hubName)
            .then(hubId => {
              // console.log("go teleport new hubId", hubId);
              hubId && changeHub(hubId);
            })
            .catch(console.error);
        }
      } else {
        document.location = componentData.href;
      }
    })
  );

  el.visible = true;
  el.object3D.visible = true;
}
