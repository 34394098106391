export default {
  avatarConfigChanged: "avatarConfigChanged",
  exportAvatar: "exportAvatar",
  avatarExported: "avatarExported",
  resetView: "resetView",
  reactIsLoaded: "reactIsLoaded",
  renderThumbnail: "renderThumbnail",
  thumbnailResult: "thumbnailResult",
  combinedMeshName: "CombinedMesh",
};

export const NONE = "Не выбрано"