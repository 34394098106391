import { findAncestorWithComponent } from "../utils/scene-graph";
import { gltfCache, loadModel } from "./gltf-model-plus";

function resolveAsset(src) {
  // If the src attribute is a selector, get the url from the asset item.
  if (src && src.charAt(0) === "#") {
    const assetEl = document.getElementById(src.substring(1));
    if (assetEl) {
      return assetEl.getAttribute("src");
    }
  }
  return src;
}

/**
 * @component default-animations
 */
AFRAME.registerComponent("default-animations", {
  schema: {
    src: { type: "string" },
    contentType: { type: "string" },
    useCache: { default: true },
  },

  async init() {
    this.mixerEl = findAncestorWithComponent(this.el, "animation-mixer");
    this.model = findAncestorWithComponent(this.el, "gltf-model-plus");
    this.currentActions = [];
    if (!this.mixerEl) {
      this.avatar = findAncestorWithComponent(this.el, "gltf-model-plus").object3D;
      this.skeleton = this.avatar.children[0]; //this.avatar.getObjectByName("Armature");
      this.model.setAttribute("animation-mixer", {});
    }
    await this.applySrc(resolveAsset(this.data.src), this.data.contentType);
    this.mixer = this.model.components["animation-mixer"]?.mixer;
  },

  update() {},

  async applySrc(src, contentType) {
    try {
      if (src === this.lastSrc) return;

      const lastSrc = this.lastSrc;
      this.lastSrc = src;

      if (!src) {
        return;
      }

      const gltf = await loadModel(src, contentType, this.data.useCache, this.jsonPreprocessor);

      // If we started loading something else already
      // TODO: there should be a way to cancel loading instead
      if (src != this.lastSrc) return;

      if (gltf.animations.length > 0) {
        this.model.components["animation-mixer"].initMixer(gltf.animations, this.skeleton);
      }

      if (lastSrc && this.data.useCache) {
        gltfCache.release(lastSrc);
      }
    } catch (e) {
      gltfCache.release(src);
      console.error("Failed to load glTF model", e, this);
    }
  },

  destroy() {}
});
