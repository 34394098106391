// Billboard component that only updates visible objects and only those in the camera view on mobile VR.
// TODO billboarding assumes a single camera viewpoint but with video-texture-source, mirrors, and camera tools this is no longer valid
AFRAME.registerComponent("billboard-avatar", {
  schema: {
    onlyY: { type: "boolean" },
    offsetY: { type: "float" }
  },

  init: function() {
    this.targetPos = new THREE.Vector3();
    this.worldPos = new THREE.Vector3();
    this.elObject3D = this.el.object3D;
    this.targetLookAtObj = this.el.sceneEl.querySelector('#avatar-rig #avatar-model').object3D
  },

  tick() {
    if (!this.elObject3D.visible) return;

    if (this.targetLookAtObj) {
      // Set the camera world position as the target.
      this.targetPos.setFromMatrixPosition(this.targetLookAtObj.matrixWorld);

      if (this.data.onlyY) {
        this.elObject3D.getWorldPosition(this.worldPos);
        this.targetPos.y = this.worldPos.y;
      }
      
      if (this.data.offsetY) {
        this.targetPos.y+= parseFloat(this.data.offsetY)
      }

      this.elObject3D.lookAt(this.targetPos)
      this.elObject3D.matrixNeedsUpdate = true;
    }
  },
});
