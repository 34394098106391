import React from "react";
import { CollectCats2 } from "../collect-cats-2/react-components/CollectCats2";
import { Quiz } from "../Quiz/quiz-ui";
import { CollectStar } from "../StarsConstructor/CollectStar";
import { TriggableVolumetric } from "../TriggableVolumetric";
import { useCallected } from "./hooks/useCallected";
import styles from "./styles.scss";

type CollectProps = {
  /**
   * Можно включать компонент только когда мы в комнате
   */
  entered: boolean;
};

/**
 * Корневой компонент для котов и квизов
 */
export const Collect: React.FC<CollectProps> = ({ entered }) => {
  const { myVotes } = useCallected();

  if (window.APP?.hub?.slug !== "tyb1") {
    return null;
  }

  if (!global.localStorage) {
    return null;
  }

  return entered ? (
    <div className={styles.collectWrapper}>
      <CollectCats2 catsCollected={myVotes?.cats || []} />
      <Quiz myVotes={myVotes} />
      <CollectStar myVotes={myVotes} />
      <TriggableVolumetric />
    </div>
  ) : null;
};
