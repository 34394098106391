import React, { useCallback, useEffect, useState } from "react";

import styles from "./styles.scss";
import classNames from "classnames";

import { FormattedMessage, useIntl } from "react-intl";
import configs from "../../utils/configs";
import { share } from "../../utils/share";
import { getLandingPageForPhoto } from "../../utils/phoenix-utils";
import { isMessageOwner } from "../utils/isMessageOwner";
import { MainViewportModal } from "../react-components/modals/MainViewportModal";
import PhotoMessage from "../../react-components/photo-message";

import closeBtnFillerDefault from "./assets/images/cross_01.png";
import closeBtnFillerActive from "./assets/images/cross_02.png";
import { vote } from "../boss-voting";

type PhotoMessageProps = {
  name: string;
  maySpawn: boolean;
  body: {
    src: string;
  };
  className?: string;
  hubId: string;
  sessionId: string;
};

export const PhotoMessageCustom: React.FC<PhotoMessageProps> = props => {
  const [opened, openedSetter] = useState(true);

  const closeModal = useCallback(() => {
    openedSetter(false);
  }, []);

  const {
    name,
    body: { src: url },
    className,
    maySpawn,
    hubId,
    sessionId
  } = props;

  const intl = useIntl();

  const landingPageUrl = getLandingPageForPhoto(url);

  const onShareClicked = share.bind(null, {
    url: landingPageUrl,
    title: intl.formatMessage(
      {
        id: "photo-message.default-tweet",
        defaultMessage: "Taken in {shareHashtag}"
      },
      {
        shareHashtag: configs.translation("share-hashtag"),
        // url: `https://${configs.SHORTLINK_DOMAIN}/${hubId}`
        url: landingPageUrl
      }
    )
  });

  const isOwner = isMessageOwner(sessionId);

  useEffect(() => {
    if (!isOwner || !opened) {
      return;
    }

    /**
     * Отмечаем достижение Фото
     */
    vote("photo");
  }, [isOwner, opened]);

  return isOwner && opened ? (
    <MainViewportModal>
      <div className={styles.Modal}>
        <div className={styles.ModalOverlay} onClick={closeModal}></div>
        <div className={styles.ModalWrapper}>
          {/* <button className={styles.shareBtn} onClick={onShareClicked}>
            Поделиться
          </button> */}
          <button className={styles.closeBtn} onClick={closeModal} aria-label="Закрыть окно">
            <img
              className={`${styles.closeBtnFiller} ${styles.closeBtnFillerDefault}`}
              src={closeBtnFillerDefault}
              alt=""
            />
            <img
              className={`${styles.closeBtnFiller} ${styles.closeBtnFillerActive}`}
              src={closeBtnFillerActive}
              alt=""
            />
          </button>
        </div>
        <iframe src={landingPageUrl} />
      </div>
    </MainViewportModal>
  ) : (
    <PhotoMessage {...props} />
  );
};
