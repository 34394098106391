import React, { useCallback, useEffect, useState } from "react";
import { OnUnauthorizedActionEvent } from "./interfaces";
import { UnauthorizedAlertModal } from "./UnauthorizedAlertModal";

/**
 * Компонент для вывода модалки, что пользователь неавторизованный
 */
export const UnauthorizedAlert: React.FC = () => {
  const [opened, openedSetter] = useState(false);

  const handleClose = useCallback(() => {
    openedSetter(false);
  }, []);

  useEffect(() => {
    const onUnauthorized = () => {
      openedSetter(true);
    };

    window.addEventListener(OnUnauthorizedActionEvent, onUnauthorized);

    return () => {
      window.removeEventListener(OnUnauthorizedActionEvent, onUnauthorized);
    };
  }, []);

  return opened ? <UnauthorizedAlertModal handleClose={handleClose} /> : null;
};
