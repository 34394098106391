// import Cookies from "js-cookie";
// import { OnUnauthorizedActionEvent } from "./interfaces";

/**
 * Проверка авторизован пользователь или нет.
 * Если нет, вызываем модалку Зарегистрироваться.
 */
export const isAuthed = () => {
  
  /**
   * Возвращаем на все тру, потому что авторизация больше не нужна
   */
  return true;

  // const nickname = Cookies.get("nickname");

  // if (nickname) {
  //   // openSetter(false);
    
  //   return true;
  // } else {
  //   /**
  //    * Пользователь не авторизован.
  //    * Возвращаем ложь и выкидываем событие, чтобы появилась модалка "Зарегистрируйся"
  //    */
    
  //   const event = new CustomEvent(OnUnauthorizedActionEvent);
  //   window.dispatchEvent(event);

  //   // openSetter(true);
  //   return false;
  // }
};
