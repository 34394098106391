import React from "react";

import styles from "./styles.scss";

import bigFrame from "./assets/images/big-frame.png";
import bottomLeftAngle from "./assets/images/bottom-left-angle.png";
import topRightAngle from "./assets/images/top-right-angle.png";
import closeBtnFillerDefault from "./assets/images/cross_01.png";
import closeBtnFillerActive from "./assets/images/cross_02.png";
import topLightning from "./assets/images/top-lightning.png";
import topStar from "./assets/images/top-star.png";
import bottomLightning from "./assets/images/bottom-lightning.png";
import bottomStar from "./assets/images/bottom-star.png";
import { MainViewportModal } from "../../react-components/modals/MainViewportModal";
import { vote } from "../../boss-voting";

type UnauthorizedAlertModalProps = {
  handleClose: () => void;
};

export const UnauthorizedAlertModal: React.FC<UnauthorizedAlertModalProps> = ({ handleClose }) => {
  return (
    <MainViewportModal>
      <div className={styles[`auth-window`]}>
        <div className={styles["auth-window-frame"]}>
          <div className={styles["top-right-angle"]} style={{ backgroundImage: `url(${topRightAngle})` }}></div>
          <div className={styles["bottom-left-angle"]} style={{ backgroundImage: `url(${bottomLeftAngle})` }}></div>
          <div className={styles["big-frame"]} style={{ backgroundImage: `url(${bigFrame})` }}></div>

          <img className={styles["top-lightning"]} src={topLightning} alt="" />
          <img className={styles["top-star"]} src={topStar} alt="" />

          <img className={styles["bottom-lightning"]} src={bottomLightning} alt="" />
          <img className={styles["bottom-star"]} src={bottomStar} alt="" />
        </div>

        <div className={styles["auth-window-content"]}>
          <div className={styles["auth-window-overlay"]}>
            <div className={styles["auth-window-overlay-glow"]}></div>
          </div>
          <div className={styles["auth-window-flex"]}>
            <p className={styles["modal-window-text"]}>
              Зарегистрируйся, чтобы принять участие в розыгрыше мега-призов!
            </p>
            <div className={styles["modal-window-wrapper"]}>
              <a
                href="http://metavers-friday.ru/register/"
                onClick={() => vote("register")}
                className={styles["auth-window-btn"]}
              >
                <>Зарегистрироваться</>
              </a>
              <a
                href="javascript:;"
                onClick={() => {
                  handleClose();
                  vote("guest");
                }}
                className={styles["auth-window-btn"]}
              >
                Продолжить как гость
              </a>
            </div>
          </div>
        </div>

        <button className={styles["close-btn"]} onClick={handleClose} aria-label="Закрыть окно">
          <span
            className={`${styles["close-btn-filler"]} ${styles["close-btn-filler-default"]}`}
            style={{ backgroundImage: `url(${closeBtnFillerDefault})` }}
          ></span>
          <span
            className={`${styles["close-btn-filler"]} ${styles["close-btn-filler-active"]}`}
            style={{ backgroundImage: `url(${closeBtnFillerActive})` }}
          ></span>
        </button>
      </div>
    </MainViewportModal>
  );
};
