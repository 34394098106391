import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";

AFRAME.registerSystem("visual-effects-system", {
  schema: {
    toneMapped: { default: true },
    debuggable: { default: true }
  },
  init: function () {
    const sceneEl = this.sceneEl;

    const scene = sceneEl.object3D;
    const renderer = sceneEl.renderer;
    const pixelRatio = renderer.getPixelRatio();

    const size = new THREE.Vector2(1024, 1024);

    renderer.setSize(window.innerWidth, window.innerHeight);

    const camera = sceneEl.camera;

    const composer = new EffectComposer(renderer);

    this.renderPass = new RenderPass(scene, camera);
    composer.addPass(this.renderPass);

    this.bloomPass = new UnrealBloomPass(size, 2, 1, 0.5);
    composer.addPass(this.bloomPass);

    this.FXAAPass = new ShaderPass(FXAAShader);
    this.FXAAPass.material.uniforms["resolution"].value.x = 1 / (window.innerWidth * pixelRatio);
    this.FXAAPass.material.uniforms["resolution"].value.y = 1 / (window.innerHeight * pixelRatio);
    composer.addPass(this.FXAAPass);

    // this.pixelReadPass = new PixelReadPass(400, 300, 1, 1, false);
    // composer.addPass(this.pixelReadPass);

    this.renderer = renderer;
    this.composer = composer;
    sceneEl.composer = composer;

    if (sceneEl.hasLoaded) {
      this.initComposer();
    } else {
      sceneEl.addEventListener("loaded", this.init.bind(this));
      sceneEl.addEventListener("rendererresize", this.resize.bind(this));
    }
  },
  resize: function () {
    this.composer.setSize(window.innerWidth, window.innerHeight);
  },
  initComposer: function () {
    const renderer = this.sceneEl.renderer;
    const system = this;
    let composerDraw = false;
    renderer.nativeRender = renderer.render;

    renderer.render = function () {
      if (composerDraw) {
        renderer.nativeRender.apply(this, arguments);
      } else {
        composerDraw = true;
        system.composer.render(system.dt);
        composerDraw = false;
      }
    };
  },
  tick() {},
  pause() {
    this.el.sceneEl.removeEventListener("loaded", this.init);
    this.el.sceneEl.removeEventListener("rendererresize", this.resize);
  },
});
