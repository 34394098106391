import { metrikaReachGoal } from "../utils/metrika";
import { ASK_BOSS_MESSAGE_MAX_LENGTH } from "./interfaces";

type IDs = `${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10}`;
type TCats = `cat${IDs}`;
type TAnswers = `answer${IDs}`;
type TQuestions = `question${IDs}`;

const LOCAL_STORAGE_VOTES_DATA_KEY = "hubs_votes";

export type TVoteId =
  | "guest"
  | "register"
  | "boss-modal-opened"
  | TCats
  | TAnswers
  | TQuestions
  | "boss"
  | "photo"
  | "star";

type MyVotesRaw = {
  nomineeId: TVoteId;
  // timestamp: string;
  votes: number;
}[];

export type MyVotes = {
  cats: IDs[];
  // собранный вопрос
  questions: IDs[];
  // правильный ответ
  answers: IDs[];
  boss: 1 | 0;
  photo: 1 | 0;
  star: 1 | 0;
};

export const VOTED_EVENT_NAME = "voted";

/**
 * Голосуем
 */
export const vote = async (vote_id: TVoteId): Promise<void> => {

  const result = setVotesData(vote_id);

  const event = new CustomEvent(VOTED_EVENT_NAME);
  window.dispatchEvent(event);
  metrikaReachGoal(vote_id);

  return result
};

export const askBossQuestion = (message: string) => {
  if (message.length > ASK_BOSS_MESSAGE_MAX_LENGTH) {
    throw new Error(`Сообщение не должно превышать ${ASK_BOSS_MESSAGE_MAX_LENGTH} символов с пробелами`);
  }

  metrikaReachGoal("boss");

  return fetch("/foreign-token/message", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify({ message })
  });
};

function setVotesData(nomineeId: TVoteId): void {
  const data = getStoreVotesData();

  if (data.findIndex(n => n.nomineeId === nomineeId) === -1) {
    data.push({
      nomineeId,
      votes: 1
    });
  }

  localStorage.setItem(LOCAL_STORAGE_VOTES_DATA_KEY, JSON.stringify(data));
}

function getStoreVotesData(): MyVotesRaw {
  let data: MyVotesRaw = [];

  const dataStr = localStorage.getItem(LOCAL_STORAGE_VOTES_DATA_KEY);

  if (dataStr) {
    data = JSON.parse(dataStr);
  }

  return data;
}

export const getMyVotes: () => Promise<MyVotes> = async () => {
  const result = getStoreVotesData();

  return result.reduce<MyVotes>(
    (a, b) => {
      if (b.nomineeId?.startsWith("cat")) {
        const id = b.nomineeId.replace("cat", "") as IDs;
        id && a.cats.push(id);
      } else if (b.nomineeId?.startsWith("question")) {
        const id = b.nomineeId.replace("question", "") as IDs;
        id && a.questions.push(id);
      } else if (b.nomineeId?.startsWith("answer")) {
        const id = b.nomineeId.replace("answer", "") as IDs;
        id && a.answers.push(id);
      } else if (b.nomineeId === "boss") {
        a.boss = 1;
      } else if (b.nomineeId === "photo") {
        a.photo = 1;
      } else if (b.nomineeId === "star") {
        a.star = 1;
      }

      return a;
    },
    {
      cats: [],
      questions: [],
      answers: [],
      boss: 0,
      photo: 0,
      star: 0
    }
  );
};
