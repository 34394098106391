import React from "react";

import styles from "../assets/stylesheets/quiz-ui.scss";
import { SvgCloseButton } from "../../react-components/modals/SvgCloseButton";
import QuestionImg from "../assets/images/Quiz.png";
import closeBtnFillerDefault from "../assets/images/cross_01.png";
import closeBtnFillerActive from "../assets/images/cross_02.png";
import { ReactComponent as ZvukLogo } from "../../../react-components/icons/custom/l-sber.svg";
import QuestionImgZvuk from "../assets/images/icon_zvuk_quiz.png";

import { Answer } from "./Answer";

import { AnsterItem } from "../interfaces";

type QuestionProps = {
  id: string;
  quiz: AnsterItem;
  isClicked: boolean;
  onClick: (
    id: string,
    // Фактический ответ пользователя
    answer: boolean
  ) => void;
  onClose: () => void;
};

export const Question: React.FC<QuestionProps> = ({ quiz, id, isClicked, onClick, onClose }) => {
  const { prompt, question, answer, category, isZvuk } = quiz;

  return (
    <div className={styles.quizContainer}>
      {isClicked && (
        <button className={styles.closeBtn} onClick={onClose} aria-label="Закрыть окно">
          <img
            className={`${styles.closeBtnFiller} ${styles.closeBtnFillerDefault}`}
            src={closeBtnFillerDefault}
            alt=""
          />
          <img
            className={`${styles.closeBtnFiller} ${styles.closeBtnFillerActive}`}
            src={closeBtnFillerActive}
            alt=""
          />
        </button>
      )}
      <div
        style={{
          position: "relative"
        }}
      >
        <img
          className={`${isZvuk ? styles.iconQuestionZvuk : styles.iconQuestion}`}
          src={isZvuk ? QuestionImgZvuk : QuestionImg}
        />
        {isZvuk && (
          <div
            className={styles.zvukLogo}
            style={{
              position: "absolute",
              top: 25
            }}
          >
            <ZvukLogo />
          </div>
        )}
        <div className={`${isZvuk ? styles.quizTitleZvuk : styles.quizTitle}`}>
          {isClicked ? (
            `${answer ? "Правда" : "Неправда"}! ${prompt}`
          ) : (
            <>
              <b>{category}</b> <br /><br />
              {question}
            </>
          )}
        </div>
      </div>

      <div className={styles.answersContainer}>
        <Answer
          isZvuk={isZvuk}
          correct={answer}
          isClicked={isClicked}
          onClick={() => onClick(id, answer)}
          value="Правда"
        />
        <Answer
          isZvuk={isZvuk}
          correct={!answer}
          isClicked={isClicked}
          onClick={() => onClick(id, !answer)}
          value="Неправда"
        />
      </div>
    </div>
  );
};
