import React from "react";
import PropTypes from "prop-types";
import { ApplyServiceButton } from "../input/Button";
import styles from "./AvatarSettingsContent.scss";
import { Column } from "../layout/Column";
import { useBadgeData } from "../../custom/hooks/useBadgeData";
import { AvatarSettingsContentCustom } from "../../custom/avatar/AvatarSettingsContent";

export function AvatarSettingsContentSide({
  displayName,
  displayNameInputRef,
  disableDisplayNameInput,
  onChangeDisplayName,
  avatarPreview,
  displayNamePattern,
  onEditAvatar,
  // eslint-disable-next-line no-unused-vars, react/prop-types
  onChangeAvatar,
  // eslint-disable-next-line no-unused-vars, react/prop-types
  onSaveAvatar,
  uploadingState,
  ...rest
}) {
  const badgeFields = useBadgeData({
    disableDisplayNameInput,
    displayName,
    onChangeDisplayName,
    styles,
    displayNameInputRef,
    displayNamePattern
  });

  return (
    <Column as="form" className={styles.content} {...rest}>
      <AvatarSettingsContentCustom
        avatarPreview={avatarPreview}
        uploadingState={uploadingState}
        onEditAvatar={onEditAvatar}
      />

      {badgeFields}
      <ApplyServiceButton disabled={uploadingState} preset="accept" type="submit" />
    </Column>
  );
}

AvatarSettingsContentSide.propTypes = {
  className: PropTypes.string,
  displayName: PropTypes.string,
  displayNameInputRef: PropTypes.func,
  disableDisplayNameInput: PropTypes.bool,
  displayNamePattern: PropTypes.string,
  onChangeDisplayName: PropTypes.func,
  avatarPreview: PropTypes.node,
  onChangeAvatar: PropTypes.func,
  uploadingState: PropTypes.bool,
  onEditAvatar: PropTypes.func
};
