import { AElementECS } from "aframe";
import { AnimationClip, Group, Mesh, Object3D } from "three";
import { findAncestorWithComponent } from "../../../utils/scene-graph";

type CollisionObject = (Mesh | Group) & {
  el?: AElementECS;
};

export type CollisionEventDetail = {
  mesh: CollisionObject;
};

type FindedId = `${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10}`;

export type onCollisionCollectProps = {
  event: CustomEvent<CollisionEventDetail>;
  collectAnimationName: string | undefined;
  finded: string[];
  // findedSetter: React.Dispatch<React.SetStateAction<string[]>>;
  // onCollected: (findedId: string, finded: string[]) => void;
  onCollected: (findedId: FindedId) => void;
  isAuthed: () => boolean;
};

export function onCollisionCollect({
  event,
  collectAnimationName,
  finded,
  // findedSetter,
  isAuthed,
  onCollected
}: onCollisionCollectProps) {
  const { mesh } = event.detail;

  if (mesh) {
    const objectId: FindedId = mesh.userData.objectId?.toString();

    if (!objectId) {
      console.error("Can not get objectId");
      return;
    }

    if (mesh.el) {
      /**
       * Чтобы исключить повторный вызов, устанавливаем флаг
       */
      if (mesh.el.getAttribute("collected-processing")) {
        return;
      } else {
        mesh.el.setAttribute("collected-processing", "true");
      }

      /**
       * Если пользователь не авторизован, прерываем выполнение.
       * При этом не снимаем отметку коллизии, так как или пользователь пойдет регистрироваться,
       * и придет на обновленное окно с перезагрузкой, или не надо больше с этим объектом взаимодействовать.
       */
      if (!isAuthed()) {
        return;
      }

      const node = mesh.el;

      if (node instanceof HTMLElement) {
        /**
         * Удаляем текущие анимации
         */
        node.removeAttribute("loop-animation");

        // Find animations
        const mixerEl = findAncestorWithComponent(mesh.el, "animation-mixer");

        const animations: AnimationClip[] = mixerEl.components["animation-mixer"]?.animations || [];

        // const collectedAnimation = animations.find(n => n.name === CAT_COLLECTED_ANIMATION_NAME);
        const collectedAnimation = collectAnimationName && animations.find(n => n.name === collectAnimationName);

        // const findedNew = [...new Set([objectId, ...finded])];

        // findedSetter(findedNew);

        const removeNode = () => {
          node.remove();

          // onCollected(objectId, findedNew);
          onCollected(objectId);
        };

        if (collectedAnimation) {
          node.setAttribute("loop-animation", {
            activeClipIndices: [animations.indexOf(collectedAnimation)],
            paused: false,
            activeClipIndex: 0,
            startOffset: 0,
            timeScale: 1
          });
          setTimeout(removeNode, 1000 * ((collectedAnimation.duration || 0.5) - 0.1));
        } else {
          removeNode();
        }
      }
    }
  }
}
