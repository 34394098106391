import React, { useCallback, useEffect, useState } from "react";
import { MainViewportModal } from "../react-components/modals/MainViewportModal";
import { StarConstructorModal } from "./StarConstructorModal";

import { StarConstructorGenerateEmageEventName } from "./interfaces";
import { vote } from "../boss-voting";

/**
 * Конструктор Создать звезду
 */
export const StarConstructor: React.FC = () => {
  // const [opened, openedSetter] = useState(false);

  const [img, imgSetter] = useState<string>();

  useEffect(() => {
    const callback = () => {
      const imageIndex = Math.round(Math.random() * 21) + 1;

      const img = `${location.origin}/star/index.html?s=${imageIndex}`;

      imgSetter(img);

      vote("star");
    };

    window.addEventListener(StarConstructorGenerateEmageEventName, callback);

    return () => {
      window.removeEventListener(StarConstructorGenerateEmageEventName, callback);
    };
  }, []);

  const closeHandler = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    imgSetter(undefined);
  }, []);

  return img ? (
    <MainViewportModal>
      <StarConstructorModal pathToPhoto={img} closeHandler={closeHandler} />
    </MainViewportModal>
  ) : null;
};
