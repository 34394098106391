import React from "react";
import PropTypes from "prop-types";
import styles from "./LoadingScreenLayout.scss";
import { Column } from "../layout/Column";
// import { ReactComponent as LoadingScreen } from "../icons/LoadingScreen.svg";
// import { AppLogo } from "../misc/AppLogo";

export function LoadingScreenLayout({ center, bottom }) {
  return (
    <div className={styles.loadingScreenLayout}>
        {/*<LoadingScreen />*/}
      <Column center padding gap="lg" className={styles.center}>
        {/*<AppLogo className={styles.logo} />*/}
        {center}
      </Column>
      {bottom && (
        <Column center className={styles.bottom}>
          {bottom}
        </Column>
      )}
    </div>
  );
}

LoadingScreenLayout.propTypes = {
  center: PropTypes.node,
  bottom: PropTypes.node
};
