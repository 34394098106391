import React, { useEffect, useState } from "react";
import AuthChannel from "../../../utils/auth-channel";
import HubChannel from "../../../utils/hub-channel";

type AutoSignupProps = {
  authChannel: AuthChannel;
  hubChannel: HubChannel;
  token?: string;
};

/**
 * Автоматическая регистрация новых пользователей
 */
export const AutoSignup: React.FC<AutoSignupProps> = ({ authChannel, hubChannel, token }) => {
  /**
   * Сохраняем в стейт токен, чтобы не вызывалась попытка авторегистрации после логаута
   */
  const [initialToken] = useState(token);

  useEffect(() => {
    // Если токен уже есть, не выполняем автоматическую регистрацию
    if (initialToken) {
      return;
    }

    const signup = async () => {
      const email = "auto@localhost";

      const { authComplete } = await authChannel.startAuthentication(email, hubChannel);

      await authComplete;
    };

    signup();
  }, [initialToken]);

  return <></>;
};
