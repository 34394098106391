/* global APP*/
import { getReticulumFetchUrl, hubUrl } from "./utils/phoenix-utils";
import { updateEnvironmentForHub, getSceneUrlForHub, updateUIForHub, remountUI } from "./hub";
import { onHubChange } from "./custom/hub/mic";
import {getCurrentHubId} from "./utils/hub-utils";

function unloadRoomObjects() {
  document.querySelectorAll("[pinnable]").forEach(el => {
    if (el.components.pinnable.data.pinned) {
      el.parentNode.removeChild(el);
    }
  });

  // Clean up empty object.gltf nodes
  document.querySelectorAll("#objects-scene .Room_Objects").forEach(el => {
    if (!el.children.length) {
      el.parentNode.parentNode.removeChild(el.parentNode);
    }
  });
}

function loadRoomObjects(hubId) {
  const objectsScene = document.querySelector("#objects-scene");
  const objectsUrl = getReticulumFetchUrl(`/${hubId}/objects.gltf`);
  const objectsEl = document.createElement("a-entity");
  objectsEl.setAttribute("gltf-model-plus", { src: objectsUrl, useCache: false, inflate: true });
  objectsScene.appendChild(objectsEl);
}

export async function changeHub(hubId, addToHistory = true) {
  if(!APP || !hubId || !APP.hubChannel || !NAF.connection.adapter || !APP.dialog){
    return;
  }
  if (hubId === APP.hub?.hub_id) {
    // console.log("Change hub called with the current hub id. This is a noop.");
    return;
  }
  // Suppress on-screen join and leave messages until we receive a sync.
  APP.hideHubPresenceEvents = true;
  const scene = AFRAME.scenes[0];

  let data;
  try {
    data = await APP.hubChannel.migrateToHub(hubId);
  } catch (e) {
    console.warn(`Failed to join hub ${hubId}: ${e.reason}|${e.message}`);
    APP.messageDispatch?.log("joinFailed", { message: e.message });
    return;
  }

  const hub = data.hubs[0];

  if (addToHistory) {
    window.history.pushState(null, null, hubUrl(hubId, {}, hub.slug));
  }

  APP.hub = hub;
  updateUIForHub(hub, APP.hubChannel);
  scene.emit("hub_updated", { hub });

  APP.subscriptions.setSubscribed(data.subscriptions.web_push);

  remountUI({
    hubIsBound: data.hub_requires_oauth,
    initialIsFavorited: data.subscriptions.favorites
  });

  NAF.entities.removeRemoteEntities();
  await NAF.connection.adapter.disconnect();
  await APP.dialog.disconnect();
  unloadRoomObjects();
  NAF.connection.connectedClients = {};
  NAF.connection.activeDataChannels = {};

  NAF.room = hub.hub_id;

  if (
    document.querySelector("#environment-scene").childNodes[0].components["gltf-model-plus"].data.src !==
    (await getSceneUrlForHub(hub))
  ) {
    const fader = document.getElementById("viewing-camera").components["fader"];
    fader.fadeOut().then(() => {
      scene.emit("reset_scene");
      updateEnvironmentForHub(hub, APP.entryManager);
    });
  }

  APP.retChannel.push("change_hub", { hub_id: hub.hub_id });

  let balance_host = hub.host;

  /**
     * Не делать подмену хостов, если локальная разработка
     */
  if (1 === 0 && location.hostname !== "hubs.local" && location.hostname !== "localhost") try{

    const server_list = [
      'metaverse01.newradio.ru',
      'metaverse02.newradio.ru',
      'metaverse03.newradio.ru',
      'metaverse04.newradio.ru',
      'metaverse05.newradio.ru',
      'metaverse06.newradio.ru',
      'metaverse07.newradio.ru',
      'metaverse08.newradio.ru',
      'metaverse09.newradio.ru',
      'metaverse10.newradio.ru'
    ]


    function cyrb128(str) {
      let h1 = 1779033703, h2 = 3144134277,
          h3 = 1013904242, h4 = 2773480762;
      for (let i = 0, k; i < str.length; i++) {
        k = str.charCodeAt(i);
        h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
        h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
        h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
        h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
      }
      h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
      h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
      h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
      h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
      return [(h1 ^ h2 ^ h3 ^ h4) >>> 0, (h2 ^ h1) >>> 0, (h3 ^ h1) >>> 0, (h4 ^ h1) >>> 0];
    }

    const hashSEEDroot = cyrb128('');

    function random(seed) {
      const x = Math.sin(seed++) * 10000;
      return x - Math.floor(x);
    }

    function sfc32(a, b, c, d) {
      return function () {
        a >>>= 0;
        b >>>= 0;
        c >>>= 0;
        d >>>= 0;
        var t = (a + b) | 0;
        a = b ^ b >>> 9;
        b = c + (c << 3) | 0;
        c = (c << 21 | c >>> 11);
        d = d + 1 | 0;
        t = t + d | 0;
        c = c + t | 0;
        return (t >>> 0) / 4294967296;
      }
    }

    let room = getCurrentHubId();

    console.log('Room: ' + room);

    const seed = cyrb128(room);

    let random_pos = -1;

    if (seed[0] === hashSEEDroot[0] &&
        seed[1] === hashSEEDroot[1] &&
        seed[2] === hashSEEDroot[2] &&
        seed[3] === hashSEEDroot[3]) {
      console.log('Root')
      random_pos = Math.random();
    } else {
      const rand = sfc32(seed[0], seed[1], seed[2], seed[3]);

      random_pos = rand();

      console.log(random_pos);
    }

    console.log(random_pos);
    const server_list_pos = Math.floor(random_pos * server_list.length);
    console.log(server_list_pos);
    const redirectServer = server_list[server_list_pos];

    console.log(redirectServer);

    balance_host = redirectServer;

  } catch (ex){
    console.error(ex);
  }

  await Promise.all([
    APP.dialog.connect({
      serverUrl: `wss://${balance_host}:${hub.port}`,
      roomId: hub.hub_id,
      serverParams: { host: hub.host, port: hub.port, turn: hub.turn },
      scene,
      clientId: APP.dialog._clientId,
      forceTcp: APP.dialog._forceTcp,
      forceTurn: APP.dialog._forceTurn,
      iceTransportPolicy: APP.dialog._iceTransportPolicy
    }),

    NAF.connection.adapter.connect()
  ]);

  loadRoomObjects(hubId);

  APP.hubChannel.sendEnteredEvent();

  APP.messageDispatch.receive({
    type: "hub_changed",
    hubName: hub.name,
    showLineBreak: true
  });

  onHubChange()
}
window.changeHub = changeHub;

// TODO see if there is a better way to do this with react router
window.addEventListener("popstate", function() {
  if (!APP.store.state.preferences.fastRoomSwitching) return;
  const qs = new URLSearchParams(location.search);
  const newHubId = qs.get("hub_id") || document.location.pathname.substring(1).split("/")[0];
  if (newHubId && newHubId !== APP.hub?.hub_id) {
    changeHub(newHubId, false);
  }
});
