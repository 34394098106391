import React, { useEffect, useState } from "react";
import { Object3D } from "three";
import { AskBossModel } from "./AskBossModel";

export const AskBoss: React.FC = () => {
  const [meshes, meshesSetter] = useState<Object3D[]>([]);

  // console.log("meshes", meshes);

  useEffect(() => {
    // let askBossMesh: Object3D | undefined;

    const meshes: Object3D[] = [];

    APP.scene?.object3D.traverse(n => {
      // n.name === "Object096" && console.log(n);
      // n.name && console.log("scene object3D n", n);

      // TODO Сейчас это на один объект, но по факту их два
      if (["Object0961", "screen_que_right", "Object0962", "Object0962_1", "screen_que_right4"].includes(n.name)) {
        // askBossMesh = n;
        /**
         * Если нашли, то удаляем событие на загрузку сцены
         */
        // this.sceneEl.removeEventListener("model-loaded", this.onSceneLoaded);
        // askBossMesh = n;
        // setTimeout(() => {
        //   this.onModelFinded(n);
        // }, 3000);

        meshes.push(n);
      }
    });

    console.log("useEffect meshes", meshes);

    // TODO Remove
    // @ts-expect-error
    window.meshes = meshes;

    if (!meshes.length) {
      return;
    }

    // // const el: AElementECS = askBossMesh.el;
    // const el: AElementECS = APP.scene;

    // // TODO Сейчас атрибуты и компоненты навешиваются на саму сцену
    // // Надо переделать на самостоятельные объекты с экстеншеном
    // addComponent(APP.world, RemoteHoverTarget, el.eid);
    // el.classList.add("interactable");
    // el.setAttribute("tags", { singleActionButton: true });

    // askBossMesh.addEventListener("custom-click", openModal);

    // return () => {
    //   removeComponent(APP.world, RemoteHoverTarget, el.eid);
    //   el.classList.remove("interactable");
    //   el.setAttribute("tags", { singleActionButton: undefined });
    // };

    meshesSetter(meshes);
  }, []);

  return (
    <>
      {meshes.map(n => (
        <AskBossModel key={n.uuid} mesh={n} />
      ))}
    </>
  );
};
