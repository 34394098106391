// import water from "../../assets/custom/textures/water.png";
import perlin_noise from "../../assets/custom/textures/perlin_noise.png";
import { getHashValue } from "../../custom/utils/url";

function materialPatchWater(mat) {
  const shaderVaryings = `
    varying vec2 vuv;
    varying vec3 vp;
    varying vec3 vn;
    varying vec4 vpd;
  `;

  const shaderVaryingsSet = `
    vuv = uv;
    vp = position;
    vn = normal;
    vpd = modelViewMatrix * vec4( position, 1.0 );
  `;

  const shaderUniforms = `
    uniform float time;
    uniform vec2 mouse;
  `;

  const shaderFunctions = `

    #define ONE_PI 3.14159265359             
    #define TWO_PI 6.28318530718

    #define msin(x) sin(mod(x,TWO_PI))
    #define mcos(x) cos(mod(x,TWO_PI))

    #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))

    /* Grid to hash vec2 (Sinus, Rotation)   */
    /* Mobile tested for 0.1...10.0 range    */
    vec2  uv2h2(vec2 uv) {
      float a = 291.7567292;
      float b = 94.32862056;   
      float c = 143.6456534;
      float r = 0.314;
            uv *= mat2(cos(r), sin(r), -sin(r), cos(r));
      float x = sin(mod(uv.x*a,6.283))*b;
            r = (uv.y-uv.x)*c*uv.y;
            uv *= mat2(cos(r), sin(r), -sin(r), cos(r));
      float y = sin(mod(uv.x*a,6.283))*b;
            r = (uv.x-uv.y)*c*uv.x;
            uv *= mat2(cos(r), sin(r), -sin(r), cos(r));
      float z = sin(mod(uv.x*a,6.283))*b;
            return vec2(fract(x+y),fract(y+z)); }


    float uv2voronoi(vec2 uv, float time) {

      float dist = 100.0;
      vec2  grid = fract(uv);
      vec2  id = floor(uv);
      vec2  cell = vec2(0.0);

      for (float y=-1.0; y<=1.0; y+=1.0 ) {
        for (float x=-1.0; x<=1.0; x+=1.0 ) {
          vec2  shift = vec2(x,y);
          vec2  n = uv2h2(id+shift); 
          vec2  p = shift + sin(n*time)*0.5+0.5 - grid;
          float d = dot(p,p);
          if (d<dist) {
            dist = d;
            cell = id + shift;
          }
        }
      }

      return dist;
    }


    /* Grid to hash float (Sinus, Rotation)  */
    /* Mobile tested for 0.1...10.0 range    */
    float uv2frandsin(vec2 uv) {
      float a = 291.7567292;
      float b = 94.32862056;   
      float c = 143.6456534;
      float r = 0.314;
            uv *= mat2(cos(r), sin(r), -sin(r), cos(r));
      float x = sin(mod(uv.x*a,6.283))*b;
            r = (uv.y-uv.x)*c*uv.y;
            uv *= mat2(cos(r), sin(r), -sin(r), cos(r));
      float y = sin(mod(uv.x*a,6.283))*b;
            return fract(x+y); }

    float uv2noise(vec2 uv) {
      float s = 10.0;
      vec2  uvp = fract(uv*s);
            uvp = smoothstep(0.0,1.0,uvp);
      vec2  id = floor(uv*s);
      float k = 1.0;
      float ul = uv2frandsin(id+vec2(0.0,0.0));
      float ur = uv2frandsin(id+vec2(1.0,0.0));
      float dl = uv2frandsin(id+vec2(0.0,1.0));
      float dr = uv2frandsin(id+vec2(1.0,1.0));
      float us = mix(ul,ur,uvp.x);
      float ds = mix(dl,dr,uvp.x);
      float ns = mix(us,ds,uvp.y);
      return ns;
    }

      float uv2onoise(vec2 uv) {
        float c = 0.0;
              c += uv2noise(uv);
        for (float i=1.0; i<6.0; i+=1.0) {
              c += uv2noise(uv*pow(2.0,i))*0.5/i;
        }
              c /= 2.0;
        return c;
      }

  `;

  mat.onBeforeCompile = shader => {
    console.log("Input Material Data:", [shader, shader.vertexShader, shader.fragmentShader]);

    const basicVertex = shader.vertexShader.split("void main() {");
    const basicVertexDef = basicVertex[0];
    const basicVertexMain = basicVertex[1].split("}")[0];

    const basicFragment = shader.fragmentShader.split("void main() {");
    const basicFragmentDef = basicFragment[0];
    const basicFragmentMain = basicFragment[1].split("}")[0];

    // console.log(basicVertexDef);
    // console.log(basicVertexMain);

    const shaderVert = `

      ${shaderVaryings}
      ${shaderUniforms}

// #define USE_LOGDEPTHBUF

      #define MATCAP
      varying vec3 vViewPosition;

// -------------------

//     #include <common>

#define PI 3.141592653589793
#define PI2 6.283185307179586
#define PI_HALF 1.5707963267948966
#define RECIPROCAL_PI 0.3183098861837907
#define RECIPROCAL_PI2 0.15915494309189535
#define EPSILON 1e-6
#ifndef saturate
// <tonemapping_pars_fragment> may have defined saturate() already
#define saturate( a ) clamp( a, 0.0, 1.0 )
#endif
#define whiteComplement( a ) ( 1.0 - saturate( a ) )
float pow2( const in float x ) { return x*x; }
vec3 pow2( const in vec3 x ) { return x*x; }
float pow3( const in float x ) { return x*x*x; }
float pow4( const in float x ) { float x2 = x*x; return x2*x2; }
float max3( const in vec3 v ) { return max( max( v.x, v.y ), v.z ); }
float average( const in vec3 v ) { return dot( v, vec3( 0.3333333 ) ); }
// expects values in the range of [0,1]x[0,1], returns values in the [0,1] range.
// do not collapse into a single function per: http://byteblacksmith.com/improvements-to-the-canonical-one-liner-glsl-rand-for-opengl-es-2-0/
highp float rand( const in vec2 uv ) {
	const highp float a = 12.9898, b = 78.233, c = 43758.5453;
	highp float dt = dot( uv.xy, vec2( a,b ) ), sn = mod( dt, PI );
	return fract( sin( sn ) * c );
}
#ifdef HIGH_PRECISION
	float precisionSafeLength( vec3 v ) { return length( v ); }
#else
	float precisionSafeLength( vec3 v ) {
		float maxComponent = max3( abs( v ) );
		return length( v / maxComponent ) * maxComponent;
	}
#endif
struct IncidentLight {
	vec3 color;
	vec3 direction;
	bool visible;
};
struct ReflectedLight {
	vec3 directDiffuse;
	vec3 directSpecular;
	vec3 indirectDiffuse;
	vec3 indirectSpecular;
};
struct GeometricContext {
	vec3 position;
	vec3 normal;
	vec3 viewDir;
#ifdef USE_CLEARCOAT
	vec3 clearcoatNormal;
#endif
};
vec3 transformDirection( in vec3 dir, in mat4 matrix ) {
	return normalize( ( matrix * vec4( dir, 0.0 ) ).xyz );
}
vec3 inverseTransformDirection( in vec3 dir, in mat4 matrix ) {
	// dir can be either a direction vector or a normal vector
	// upper-left 3x3 of matrix is assumed to be orthogonal
	return normalize( ( vec4( dir, 0.0 ) * matrix ).xyz );
}
mat3 transposeMat3( const in mat3 m ) {
	mat3 tmp;
	tmp[ 0 ] = vec3( m[ 0 ].x, m[ 1 ].x, m[ 2 ].x );
	tmp[ 1 ] = vec3( m[ 0 ].y, m[ 1 ].y, m[ 2 ].y );
	tmp[ 2 ] = vec3( m[ 0 ].z, m[ 1 ].z, m[ 2 ].z );
	return tmp;
}
float luminance( const in vec3 rgb ) {
	// assumes rgb is in linear color space with sRGB primaries and D65 white point
	const vec3 weights = vec3( 0.2126729, 0.7151522, 0.0721750 );
	return dot( weights, rgb );
}
bool isPerspectiveMatrix( mat4 m ) {
	return m[ 2 ][ 3 ] == - 1.0;
}
vec2 equirectUv( in vec3 dir ) {
	// dir is assumed to be unit length
	float u = atan( dir.z, dir.x ) * RECIPROCAL_PI2 + 0.5;
	float v = asin( clamp( dir.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
	return vec2( u, v );
}
// Mipped Bicubic Texture Filtering by N8
// https://www.shadertoy.com/view/Dl2SDW
float w0( float a ) {
	return ( 1.0 / 6.0 ) * ( a * ( a * ( - a + 3.0 ) - 3.0 ) + 1.0 );
}
float w1( float a ) {
	return ( 1.0 / 6.0 ) * ( a *  a * ( 3.0 * a - 6.0 ) + 4.0 );
}
float w2( float a ){
    return ( 1.0 / 6.0 ) * ( a * ( a * ( - 3.0 * a + 3.0 ) + 3.0 ) + 1.0 );
}
float w3( float a ) {
	return ( 1.0 / 6.0 ) * ( a * a * a );
}
// g0 and g1 are the two amplitude functions
float g0( float a ) {
	return w0( a ) + w1( a );
}
float g1( float a ) {
	return w2( a ) + w3( a );
}
// h0 and h1 are the two offset functions
float h0( float a ) {
	return - 1.0 + w1( a ) / ( w0( a ) + w1( a ) );
}
float h1( float a ) {
    return 1.0 + w3( a ) / ( w2( a ) + w3( a ) );
}
vec4 bicubic( sampler2D tex, vec2 uv, vec4 texelSize, vec2 fullSize, float lod ) {
	uv = uv * texelSize.zw + 0.5;
	vec2 iuv = floor( uv );
    vec2 fuv = fract( uv );
    float g0x = g0( fuv.x );
    float g1x = g1( fuv.x );
    float h0x = h0( fuv.x );
    float h1x = h1( fuv.x );
    float h0y = h0( fuv.y );
    float h1y = h1( fuv.y );
    vec2 p0 = ( vec2( iuv.x + h0x, iuv.y + h0y ) - 0.5 ) * texelSize.xy;
    vec2 p1 = ( vec2( iuv.x + h1x, iuv.y + h0y ) - 0.5 ) * texelSize.xy;
    vec2 p2 = ( vec2( iuv.x + h0x, iuv.y + h1y ) - 0.5 ) * texelSize.xy;
    vec2 p3 = ( vec2( iuv.x + h1x, iuv.y + h1y ) - 0.5 ) * texelSize.xy;
    
    vec2 lodFudge = pow( 1.95, lod ) / fullSize;
	return g0( fuv.y ) * ( g0x * textureLod( tex, p0, lod ) + g1x * textureLod( tex, p1, lod ) ) +
		   g1( fuv.y ) * ( g0x * textureLod( tex, p2, lod ) + g1x * textureLod( tex, p3, lod ) );
}
vec4 textureBicubic( sampler2D sampler, vec2 uv, float lod ) {
	vec2 fLodSize = vec2( textureSize( sampler, int( lod ) ) );
	vec2 cLodSize = vec2( textureSize( sampler, int( lod + 1.0 ) ) );
	vec2 fLodSizeInv = 1.0 / fLodSize;
	vec2 cLodSizeInv = 1.0 / cLodSize;
	vec2 fullSize = vec2( textureSize( sampler, 0 ) );
	vec4 fSample = bicubic( sampler, uv, vec4( fLodSizeInv, fLodSize ), fullSize, floor( lod ) );
	vec4 cSample = bicubic( sampler, uv, vec4( cLodSizeInv, cLodSize ), fullSize, ceil( lod ) );
	return mix( fSample, cSample, fract( lod ) );
}

// --------------------

    //  #include <uv_pars_vertex>

#ifdef USE_UV
	#ifdef UVS_VERTEX_ONLY
		vec2 vUv;
	#else
		varying vec2 vUv;
	#endif
	uniform mat3 uvTransform;
#endif

// --------------------

    //  #include <normal_pars_vertex>

#ifndef FLAT_SHADED
	varying vec3 vNormal;
	#ifdef USE_TANGENT
		varying vec3 vTangent;
		varying vec3 vBitangent;
	#endif
#endif

// --------------------

//      #include <logdepthbuf_pars_vertex>

#ifdef USE_LOGDEPTHBUF
	#ifdef USE_LOGDEPTHBUF_EXT
		varying float vFragDepth;
		varying float vIsPerspective;
	#else
		uniform float logDepthBufFC;
	#endif
#endif

// --------------------

      void main() {

        ${shaderVaryingsSet}

// ---------------------

        // #include <uv_vertex>

#ifdef USE_UV
	vUv = ( uvTransform * vec3( uv, 1 ) ).xy;
#endif

// ---------------------

       // #include <beginnormal_vertex>

vec3 objectNormal = vec3( normal );
#ifdef USE_TANGENT
	vec3 objectTangent = vec3( tangent.xyz );
#endif

// ---------------------

        // #include <defaultnormal_vertex>

vec3 transformedNormal = objectNormal;
#ifdef USE_INSTANCING
	// this is in lieu of a per-instance normal-matrix
	// shear transforms in the instance matrix are not supported
	mat3 m = mat3( instanceMatrix );
	transformedNormal /= vec3( dot( m[ 0 ], m[ 0 ] ), dot( m[ 1 ], m[ 1 ] ), dot( m[ 2 ], m[ 2 ] ) );
	transformedNormal = m * transformedNormal;
#endif
transformedNormal = normalMatrix * transformedNormal;
#ifdef FLIP_SIDED
	transformedNormal = - transformedNormal;
#endif
#ifdef USE_TANGENT
	vec3 transformedTangent = ( modelViewMatrix * vec4( objectTangent, 0.0 ) ).xyz;
	#ifdef FLIP_SIDED
		transformedTangent = - transformedTangent;
	#endif
#endif

// ---------------------

        // #include <normal_vertex>

#ifndef FLAT_SHADED // normal is computed with derivatives when FLAT_SHADED
	vNormal = normalize( transformedNormal );
	#ifdef USE_TANGENT
		vTangent = normalize( transformedTangent );
		vBitangent = normalize( cross( vNormal, vTangent ) * tangent.w );
	#endif
#endif

// ---------------------

        // #include <begin_vertex>

vec3 transformed = vec3( position );

// ---------------------

        // #include <project_vertex>

vec4 mvPosition = vec4( transformed, 1.0 );
#ifdef USE_INSTANCING
	mvPosition = instanceMatrix * mvPosition;
#endif
mvPosition = modelViewMatrix * mvPosition;
gl_Position = projectionMatrix * mvPosition;

// ---------------------

        // #include <logdepthbuf_vertex>

#ifdef USE_LOGDEPTHBUF
	#ifdef USE_LOGDEPTHBUF_EXT
		vFragDepth = 1.0 + gl_Position.w;
		vIsPerspective = float( isPerspectiveMatrix( projectionMatrix ) );
	#else
		if ( isPerspectiveMatrix( projectionMatrix ) ) {
			gl_Position.z = log2( max( EPSILON, gl_Position.w + 1.0 ) ) * logDepthBufFC - 1.0;
			gl_Position.z *= gl_Position.w;
		}
	#endif
#endif

// ---------------------

        vViewPosition = - mvPosition.xyz;

      }

    `;

    // -------------------------------------------------------------------------------------------------------

    // -------------------------------------------------------------------------------------------------------

    // -------------------------------------------------------------------------------------------------------

    const shaderFrag = `

    ${shaderVaryings}
    ${shaderUniforms}
    ${shaderFunctions}
    
// #define USE_LOGDEPTHBUF
    #define MATCAP
    uniform vec3 diffuse;
    uniform float opacity;
    uniform sampler2D matcap;
    varying vec3 vViewPosition;

// ------------------

    //#include <common>

#define PI 3.141592653589793
#define PI2 6.283185307179586
#define PI_HALF 1.5707963267948966
#define RECIPROCAL_PI 0.3183098861837907
#define RECIPROCAL_PI2 0.15915494309189535
#define EPSILON 1e-6
#ifndef saturate
// <tonemapping_pars_fragment> may have defined saturate() already
#define saturate( a ) clamp( a, 0.0, 1.0 )
#endif
#define whiteComplement( a ) ( 1.0 - saturate( a ) )
float pow2( const in float x ) { return x*x; }
vec3 pow2( const in vec3 x ) { return x*x; }
float pow3( const in float x ) { return x*x*x; }
float pow4( const in float x ) { float x2 = x*x; return x2*x2; }
float max3( const in vec3 v ) { return max( max( v.x, v.y ), v.z ); }
float average( const in vec3 v ) { return dot( v, vec3( 0.3333333 ) ); }
// expects values in the range of [0,1]x[0,1], returns values in the [0,1] range.
// do not collapse into a single function per: http://byteblacksmith.com/improvements-to-the-canonical-one-liner-glsl-rand-for-opengl-es-2-0/
highp float rand( const in vec2 uv ) {
	const highp float a = 12.9898, b = 78.233, c = 43758.5453;
	highp float dt = dot( uv.xy, vec2( a,b ) ), sn = mod( dt, PI );
	return fract( sin( sn ) * c );
}
#ifdef HIGH_PRECISION
	float precisionSafeLength( vec3 v ) { return length( v ); }
#else
	float precisionSafeLength( vec3 v ) {
		float maxComponent = max3( abs( v ) );
		return length( v / maxComponent ) * maxComponent;
	}
#endif
struct IncidentLight {
	vec3 color;
	vec3 direction;
	bool visible;
};
struct ReflectedLight {
	vec3 directDiffuse;
	vec3 directSpecular;
	vec3 indirectDiffuse;
	vec3 indirectSpecular;
};
struct GeometricContext {
	vec3 position;
	vec3 normal;
	vec3 viewDir;
#ifdef USE_CLEARCOAT
	vec3 clearcoatNormal;
#endif
};
vec3 transformDirection( in vec3 dir, in mat4 matrix ) {
	return normalize( ( matrix * vec4( dir, 0.0 ) ).xyz );
}
vec3 inverseTransformDirection( in vec3 dir, in mat4 matrix ) {
	// dir can be either a direction vector or a normal vector
	// upper-left 3x3 of matrix is assumed to be orthogonal
	return normalize( ( vec4( dir, 0.0 ) * matrix ).xyz );
}
mat3 transposeMat3( const in mat3 m ) {
	mat3 tmp;
	tmp[ 0 ] = vec3( m[ 0 ].x, m[ 1 ].x, m[ 2 ].x );
	tmp[ 1 ] = vec3( m[ 0 ].y, m[ 1 ].y, m[ 2 ].y );
	tmp[ 2 ] = vec3( m[ 0 ].z, m[ 1 ].z, m[ 2 ].z );
	return tmp;
}
float luminance( const in vec3 rgb ) {
	// assumes rgb is in linear color space with sRGB primaries and D65 white point
	const vec3 weights = vec3( 0.2126729, 0.7151522, 0.0721750 );
	return dot( weights, rgb );
}
bool isPerspectiveMatrix( mat4 m ) {
	return m[ 2 ][ 3 ] == - 1.0;
}
vec2 equirectUv( in vec3 dir ) {
	// dir is assumed to be unit length
	float u = atan( dir.z, dir.x ) * RECIPROCAL_PI2 + 0.5;
	float v = asin( clamp( dir.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
	return vec2( u, v );
}
// Mipped Bicubic Texture Filtering by N8
// https://www.shadertoy.com/view/Dl2SDW
float w0( float a ) {
	return ( 1.0 / 6.0 ) * ( a * ( a * ( - a + 3.0 ) - 3.0 ) + 1.0 );
}
float w1( float a ) {
	return ( 1.0 / 6.0 ) * ( a *  a * ( 3.0 * a - 6.0 ) + 4.0 );
}
float w2( float a ){
    return ( 1.0 / 6.0 ) * ( a * ( a * ( - 3.0 * a + 3.0 ) + 3.0 ) + 1.0 );
}
float w3( float a ) {
	return ( 1.0 / 6.0 ) * ( a * a * a );
}
// g0 and g1 are the two amplitude functions
float g0( float a ) {
	return w0( a ) + w1( a );
}
float g1( float a ) {
	return w2( a ) + w3( a );
}
// h0 and h1 are the two offset functions
float h0( float a ) {
	return - 1.0 + w1( a ) / ( w0( a ) + w1( a ) );
}
float h1( float a ) {
    return 1.0 + w3( a ) / ( w2( a ) + w3( a ) );
}
vec4 bicubic( sampler2D tex, vec2 uv, vec4 texelSize, vec2 fullSize, float lod ) {
	uv = uv * texelSize.zw + 0.5;
	vec2 iuv = floor( uv );
    vec2 fuv = fract( uv );
    float g0x = g0( fuv.x );
    float g1x = g1( fuv.x );
    float h0x = h0( fuv.x );
    float h1x = h1( fuv.x );
    float h0y = h0( fuv.y );
    float h1y = h1( fuv.y );
    vec2 p0 = ( vec2( iuv.x + h0x, iuv.y + h0y ) - 0.5 ) * texelSize.xy;
    vec2 p1 = ( vec2( iuv.x + h1x, iuv.y + h0y ) - 0.5 ) * texelSize.xy;
    vec2 p2 = ( vec2( iuv.x + h0x, iuv.y + h1y ) - 0.5 ) * texelSize.xy;
    vec2 p3 = ( vec2( iuv.x + h1x, iuv.y + h1y ) - 0.5 ) * texelSize.xy;
    
    vec2 lodFudge = pow( 1.95, lod ) / fullSize;
	return g0( fuv.y ) * ( g0x * textureLod( tex, p0, lod ) + g1x * textureLod( tex, p1, lod ) ) +
		   g1( fuv.y ) * ( g0x * textureLod( tex, p2, lod ) + g1x * textureLod( tex, p3, lod ) );
}
vec4 textureBicubic( sampler2D sampler, vec2 uv, float lod ) {
	vec2 fLodSize = vec2( textureSize( sampler, int( lod ) ) );
	vec2 cLodSize = vec2( textureSize( sampler, int( lod + 1.0 ) ) );
	vec2 fLodSizeInv = 1.0 / fLodSize;
	vec2 cLodSizeInv = 1.0 / cLodSize;
	vec2 fullSize = vec2( textureSize( sampler, 0 ) );
	vec4 fSample = bicubic( sampler, uv, vec4( fLodSizeInv, fLodSize ), fullSize, floor( lod ) );
	vec4 cSample = bicubic( sampler, uv, vec4( cLodSizeInv, cLodSize ), fullSize, ceil( lod ) );
	return mix( fSample, cSample, fract( lod ) );
}


// ------------------

    // #include <uv_pars_fragment>

#if ( defined( USE_UV ) && ! defined( UVS_VERTEX_ONLY ) )
	varying vec2 vUv;
#endif

// ------------------

    varying vec3 vNormal;

// ------------------

    //#include <normalmap_pars_fragment>

#ifdef USE_NORMALMAP
	uniform sampler2D normalMap;
	uniform vec2 normalScale;
#endif
#ifdef OBJECTSPACE_NORMALMAP
	uniform mat3 normalMatrix;
#endif
	// Normal Mapping Without Precomputed Tangents
	// http://www.thetenthplanet.de/archives/1180
	vec3 perturbNormal2Arb( vec3 eye_pos, vec3 surf_norm, vec3 mapN, float faceDirection ) {
		vec3 q0 = dFdx( eye_pos.xyz );
		vec3 q1 = dFdy( eye_pos.xyz );
		vec2 st0 = dFdx( vUv.st );
		vec2 st1 = dFdy( vUv.st );
		vec3 N = surf_norm; // normalized
		vec3 q1perp = cross( q1, N );
		vec3 q0perp = cross( N, q0 );
		vec3 T = q1perp * st0.x + q0perp * st1.x;
		vec3 B = q1perp * st0.y + q0perp * st1.y;
		float det = max( dot( T, T ), dot( B, B ) );
		float scale = ( det == 0.0 ) ? 0.0 : faceDirection * inversesqrt( det );
		return normalize( T * ( mapN.x * scale ) + B * ( mapN.y * scale ) + N * mapN.z );
	}

// ------------------

#if defined( USE_LOGDEPTHBUF ) && defined( USE_LOGDEPTHBUF_EXT )
	uniform float logDepthBufFC;
	varying float vFragDepth;
	varying float vIsPerspective;
#endif


    void main() {

      #if defined( USE_LOGDEPTHBUF ) && defined( USE_LOGDEPTHBUF_EXT )
        // Doing a strict comparison with == 1.0 can cause noise artifacts
        // on some platforms. See issue #17623.
        gl_FragDepthEXT = vIsPerspective == 0.0 ? gl_FragCoord.z : log2( vFragDepth ) * logDepthBufFC * 0.5;
      #endif

      float faceDirection = gl_FrontFacing ? 1.0 : - 1.0;
      vec3  normal = normalize( vNormal );

      // --------------------------------------------------------------------------
      // --------------------------------------------------------------------------
      // --------------------------------------------------------------------------

      float scale = 0.1;
      float minDist = 5.0;
      float maxDist = 10.0;
      float speed = 0.01;

      // --------------------------------------------------------------------------

      vec2  coords = vp.xy*scale;                       // vp.xz !!!
            coords += 0.01*sin(coords.yx*20.0+time);

      float t = time * speed;

      vec2  uvs1 = 0.40*coords.xy-t*0.8;
      vec2  uvs2 = 0.35*coords.yx+t*0.5;

      float n1 = texture2D( normalMap, fract((uvs1+t)*1.0) ).r;
      float n2 = texture2D( normalMap, fract((uvs2-t)*1.5) ).r;
      float o1 = n1 - n2;

      float viewDist = mix( 0.35, 1.0, smoothstep( maxDist, minDist, length(vViewPosition.xz)) );
      vec3  mapN = vec3( -1.0, 1.0, o1*0.3*viewDist );

      vec3  viewDir = normalize( vViewPosition );
      vec3  x = normalize( vec3( viewDir.z, 0.0, - viewDir.x ) );
      vec3  y = cross( viewDir, x );

            normal = perturbNormal2Arb( - vViewPosition, normal, mapN, faceDirection );
      float d = 50.0;
      vec2  uv = vec2( dot( x, normal ), dot( y, normal ) ) * d*0.5 - d*0.5;

      vec4  matcapColor = texture2D( matcap, uv.yy*0.33 );
      // vec3  col = vec3(102.0,161.0,239.0)/255.0; // original
      vec3  col = vec3(70.0,105.0,225.0)/255.0;
            matcapColor.rgb = mix(vec3(1.3),col,(matcapColor.g-0.33)*5.0);

        gl_FragColor = matcapColor;	

    }

    `;

    shader.uniforms.time = { value: 0 };
    shader.uniforms.mouse = { value: [0, 0] };
    mat.userData.shader = shader;

    shader.vertexShader = shaderVert;
    shader.fragmentShader = shaderFrag;
  };
}
function materialPatchNoise(mat) {
  const shaderVaryings = `
      varying vec2 vuv;
      varying vec3 vp;
      varying vec3 vn;
    `;

  const shaderVaryingsSet = `
      vuv = uv;
      vp = position;
      vn = normal;
    `;

  const shaderUniforms = `
      uniform float time;
      uniform vec2 mouse;
      uniform sampler2D noise;
    `;

  const shaderFunctions = `
  
      #define ONE_PI 3.14159265359             
      #define TWO_PI 6.28318530718
  
      #define msin(x) sin(mod(x,TWO_PI))
      #define mcos(x) cos(mod(x,TWO_PI))
  
      #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))
  
    /* Grid to hash float (Sinus, Rotation)  */
    /* Mobile tested for 0.1...10.0 range    */
    float h21(vec2 uv) {
      float a = 291.7567292;
      float b = 94.32862056;   
      float c = 143.6456534;
      float r = 0.314;
            uv *= mat2(cos(r), sin(r), -sin(r), cos(r));
      float x = sin(mod(uv.x*a,6.283))*b;
            r = (uv.y-uv.x)*c*uv.y;
            uv *= mat2(cos(r), sin(r), -sin(r), cos(r));
      float y = sin(mod(uv.x*a,6.283))*b;
            return fract(x+y); }
  
    vec3 img2blend(vec3 a, vec3 b, float mixval, float tune) {
      vec3 m = mix(a, pow(1.0-(1.0-a)*(1.0-b),vec3(1.0+tune)), mixval);    
      return m; }
  
    `;

  mat.onBeforeCompile = shader => {
    const basicVertex = shader.vertexShader.split("void main() {");
    const basicVertexDef = basicVertex[0];
    const basicVertexMain = basicVertex[1].split("}")[0];

    const basicFragment = shader.fragmentShader.split("void main() {");
    const basicFragmentDef = basicFragment[0];
    const basicFragmentMain = basicFragment[1].split("}")[0];

    const shaderVert = `
  
        ${shaderVaryings}
        ${shaderUniforms}
  
  #define STANDARD
  varying vec3 vViewPosition;
  #ifdef USE_TRANSMISSION
      varying vec3 vWorldPosition;
  #endif
  #define PI 3.141592653589793
  #define PI2 6.283185307179586
  #define PI_HALF 1.5707963267948966
  #define RECIPROCAL_PI 0.3183098861837907
  #define RECIPROCAL_PI2 0.15915494309189535
  #define EPSILON 1e-6
  #ifndef saturate
      #define saturate( a ) clamp( a, 0.0, 1.0 )
  #endif
  #define whiteComplement( a ) ( 1.0 - saturate( a ) )
  float pow2( const in float x ) {
      return x*x;
  }
  vec3 pow2( const in vec3 x ) {
      return x*x;
  }
  float pow3( const in float x ) {
      return x*x*x;
  }
  float pow4( const in float x ) {
      float x2 = x*x;
      return x2*x2;
  }
  float max3( const in vec3 v ) {
      return max( max( v.x, v.y ), v.z );
  }
  float average( const in vec3 v ) {
      return dot( v, vec3( 0.3333333 ) );
  }
  highp float rand( const in vec2 uv ) {
      const highp float a = 12.9898, b = 78.233, c = 43758.5453;
      highp float dt = dot( uv.xy, vec2( a, b ) ), sn = mod( dt, PI );
      return fract( sin( sn ) * c );
  }
  #ifdef HIGH_PRECISION
      float precisionSafeLength( vec3 v ) {
          return length( v );
      }
  #else
      float precisionSafeLength( vec3 v ) {
          float maxComponent = max3( abs( v ) );
          return length( v / maxComponent ) * maxComponent;
      }
  #endif
  struct IncidentLight {
      vec3 color;
      vec3 direction;
      bool visible;
  };
  struct ReflectedLight {
      vec3 directDiffuse;
      vec3 directSpecular;
      vec3 indirectDiffuse;
      vec3 indirectSpecular;
  };
  struct GeometricContext {
      vec3 position;
      vec3 normal;
      vec3 viewDir;
      #ifdef USE_CLEARCOAT
          vec3 clearcoatNormal;
      #endif
  };
  vec3 transformDirection( in vec3 dir, in mat4 matrix ) {
      return normalize( ( matrix * vec4( dir, 0.0 ) ).xyz );
  }
  vec3 inverseTransformDirection( in vec3 dir, in mat4 matrix ) {
      return normalize( ( vec4( dir, 0.0 ) * matrix ).xyz );
  }
  mat3 transposeMat3( const in mat3 m ) {
      mat3 tmp;
      tmp[ 0 ] = vec3( m[ 0 ].x, m[ 1 ].x, m[ 2 ].x );
      tmp[ 1 ] = vec3( m[ 0 ].y, m[ 1 ].y, m[ 2 ].y );
      tmp[ 2 ] = vec3( m[ 0 ].z, m[ 1 ].z, m[ 2 ].z );
      return tmp;
  }
  float luminance( const in vec3 rgb ) {
      const vec3 weights = vec3( 0.2126729, 0.7151522, 0.0721750 );
      return dot( weights, rgb );
  }
  bool isPerspectiveMatrix( mat4 m ) {
      return m[ 2 ][ 3 ] == - 1.0;
  }
  vec2 equirectUv( in vec3 dir ) {
      float u = atan( dir.z, dir.x ) * RECIPROCAL_PI2 + 0.5;
      float v = asin( clamp( dir.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
      return vec2( u, v );
  }
  float w0( float a ) {
      return ( 1.0 / 6.0 ) * ( a * ( a * ( - a + 3.0 ) - 3.0 ) + 1.0 );
  }
  float w1( float a ) {
      return ( 1.0 / 6.0 ) * ( a *  a * ( 3.0 * a - 6.0 ) + 4.0 );
  }
  float w2( float a ) {
      return ( 1.0 / 6.0 ) * ( a * ( a * ( - 3.0 * a + 3.0 ) + 3.0 ) + 1.0 );
  }
  float w3( float a ) {
      return ( 1.0 / 6.0 ) * ( a * a * a );
  }
  float g0( float a ) {
      return w0( a ) + w1( a );
  }
  float g1( float a ) {
      return w2( a ) + w3( a );
  }
  float h0( float a ) {
      return - 1.0 + w1( a ) / ( w0( a ) + w1( a ) );
  }
  float h1( float a ) {
      return 1.0 + w3( a ) / ( w2( a ) + w3( a ) );
  }
  vec4 bicubic( sampler2D tex, vec2 uv, vec4 texelSize, vec2 fullSize, float lod ) {
      uv = uv * texelSize.zw + 0.5;
      vec2 iuv = floor( uv );
      vec2 fuv = fract( uv );
      float g0x = g0( fuv.x );
      float g1x = g1( fuv.x );
      float h0x = h0( fuv.x );
      float h1x = h1( fuv.x );
      float h0y = h0( fuv.y );
      float h1y = h1( fuv.y );
      vec2 p0 = ( vec2( iuv.x + h0x, iuv.y + h0y ) - 0.5 ) * texelSize.xy;
      vec2 p1 = ( vec2( iuv.x + h1x, iuv.y + h0y ) - 0.5 ) * texelSize.xy;
      vec2 p2 = ( vec2( iuv.x + h0x, iuv.y + h1y ) - 0.5 ) * texelSize.xy;
      vec2 p3 = ( vec2( iuv.x + h1x, iuv.y + h1y ) - 0.5 ) * texelSize.xy;
      vec2 lodFudge = pow( 1.95, lod ) / fullSize;
      return g0( fuv.y ) * ( g0x * textureLod( tex, p0, lod ) + g1x * textureLod( tex, p1, lod ) ) +
      g1( fuv.y ) * ( g0x * textureLod( tex, p2, lod ) + g1x * textureLod( tex, p3, lod ) );
  }
  vec4 textureBicubic( sampler2D sampler, vec2 uv, float lod ) {
      vec2 fLodSize = vec2( textureSize( sampler, int( lod ) ) );
      vec2 cLodSize = vec2( textureSize( sampler, int( lod + 1.0 ) ) );
      vec2 fLodSizeInv = 1.0 / fLodSize;
      vec2 cLodSizeInv = 1.0 / cLodSize;
      vec2 fullSize = vec2( textureSize( sampler, 0 ) );
      vec4 fSample = bicubic( sampler, uv, vec4( fLodSizeInv, fLodSize ), fullSize, floor( lod ) );
      vec4 cSample = bicubic( sampler, uv, vec4( cLodSizeInv, cLodSize ), fullSize, ceil( lod ) );
      return mix( fSample, cSample, fract( lod ) );
  }
  #ifdef USE_UV
      #ifdef UVS_VERTEX_ONLY
          vec2 vUv;
      #else
          varying vec2 vUv;
      #endif
      uniform mat3 uvTransform;
  #endif
  #if defined( USE_LIGHTMAP ) || defined( USE_AOMAP )
      attribute vec2 uv2;
      varying vec2 vUv2;
      uniform mat3 uv2Transform;
  #endif
  #ifdef USE_DISPLACEMENTMAP
      uniform sampler2D displacementMap;
      uniform float displacementScale;
      uniform float displacementBias;
  #endif
  #if defined( USE_COLOR_ALPHA )
      varying vec4 vColor;
      #elif defined( USE_COLOR ) || defined( USE_INSTANCING_COLOR )
      varying vec3 vColor;
  #endif
  #ifdef USE_FOG
      varying float vFogDepth;
  #endif
  #ifndef FLAT_SHADED
      varying vec3 vNormal;
      #ifdef USE_TANGENT
          varying vec3 vTangent;
          varying vec3 vBitangent;
      #endif
  #endif
  #ifdef USE_MORPHTARGETS
      uniform float morphTargetBaseInfluence;
      #ifdef MORPHTARGETS_TEXTURE
          uniform float morphTargetInfluences[ MORPHTARGETS_COUNT ];
          uniform sampler2DArray morphTargetsTexture;
          uniform ivec2 morphTargetsTextureSize;
          vec4 getMorph( const in int vertexIndex, const in int morphTargetIndex, const in int offset ) {
              int texelIndex = vertexIndex * MORPHTARGETS_TEXTURE_STRIDE + offset;
              int y = texelIndex / morphTargetsTextureSize.x;
              int x = texelIndex - y * morphTargetsTextureSize.x;
              ivec3 morphUV = ivec3( x, y, morphTargetIndex );
              return texelFetch( morphTargetsTexture, morphUV, 0 );
          }
      #else
          #ifndef USE_MORPHNORMALS
              uniform float morphTargetInfluences[ 8 ];
          #else
              uniform float morphTargetInfluences[ 4 ];
          #endif
      #endif
  #endif
  #ifdef USE_SKINNING
      uniform mat4 bindMatrix;
      uniform mat4 bindMatrixInverse;
      uniform highp sampler2D boneTexture;
      uniform int boneTextureSize;
      mat4 getBoneMatrix( const in float i ) {
          float j = i * 4.0;
          float x = mod( j, float( boneTextureSize ) );
          float y = floor( j / float( boneTextureSize ) );
          float dx = 1.0 / float( boneTextureSize );
          float dy = 1.0 / float( boneTextureSize );
          y = dy * ( y + 0.5 );
          vec4 v1 = texture2D( boneTexture, vec2( dx * ( x + 0.5 ), y ) );
          vec4 v2 = texture2D( boneTexture, vec2( dx * ( x + 1.5 ), y ) );
          vec4 v3 = texture2D( boneTexture, vec2( dx * ( x + 2.5 ), y ) );
          vec4 v4 = texture2D( boneTexture, vec2( dx * ( x + 3.5 ), y ) );
          mat4 bone = mat4( v1, v2, v3, v4 );
          return bone;
      }
  #endif
  #if 0 > 0
      uniform mat4 spotLightMatrix[ 0 ];
      varying vec4 vSpotLightCoord[ 0 ];
  #endif
  #ifdef USE_SHADOWMAP
      #if 0 > 0
          uniform mat4 directionalShadowMatrix[ 0 ];
          varying vec4 vDirectionalShadowCoord[ 0 ];
          struct DirectionalLightShadow {
              float shadowBias;
              float shadowNormalBias;
              float shadowRadius;
              vec2 shadowMapSize;
          };
          uniform DirectionalLightShadow directionalLightShadows[ 0 ];
      #endif
      #if 0 > 0
          struct SpotLightShadow {
              float shadowBias;
              float shadowNormalBias;
              float shadowRadius;
              vec2 shadowMapSize;
          };
          uniform SpotLightShadow spotLightShadows[ 0 ];
      #endif
      #if 0 > 0
          uniform mat4 pointShadowMatrix[ 0 ];
          varying vec4 vPointShadowCoord[ 0 ];
          struct PointLightShadow {
              float shadowBias;
              float shadowNormalBias;
              float shadowRadius;
              vec2 shadowMapSize;
              float shadowCameraNear;
              float shadowCameraFar;
          };
          uniform PointLightShadow pointLightShadows[ 0 ];
      #endif
  #endif
  #ifdef USE_LOGDEPTHBUF
      #ifdef USE_LOGDEPTHBUF_EXT
          varying float vFragDepth;
          varying float vIsPerspective;
      #else
          uniform float logDepthBufFC;
      #endif
  #endif
  #if 0 > 0
      varying vec3 vClipPosition;
  #endif
  
  
  void main() {
      vuv = uv;
      vp = position;
      vn = normal;
      #ifdef USE_UV
          vUv = ( uvTransform * vec3( uv, 1 ) ).xy;
      #endif
      #if defined( USE_LIGHTMAP ) || defined( USE_AOMAP )
          vUv2 = ( uv2Transform * vec3( uv2, 1 ) ).xy;
      #endif
      #if defined( USE_COLOR_ALPHA )
          vColor = vec4( 1.0 );
          #elif defined( USE_COLOR ) || defined( USE_INSTANCING_COLOR )
          vColor = vec3( 1.0 );
      #endif
      #ifdef USE_COLOR
          vColor *= color;
      #endif
      #ifdef USE_INSTANCING_COLOR
          vColor.xyz *= instanceColor.xyz;
      #endif
      #if defined( USE_MORPHCOLORS ) && defined( MORPHTARGETS_TEXTURE )
          vColor *= morphTargetBaseInfluence;
          for ( int i = 0; i < MORPHTARGETS_COUNT; i ++ ) {
              #if defined( USE_COLOR_ALPHA )
                  if ( morphTargetInfluences[ i ] ! = 0.0 ) vColor += getMorph( gl_VertexID, i, 2 ) * morphTargetInfluences[ i ];
                  #elif defined( USE_COLOR )
                  if ( morphTargetInfluences[ i ] ! = 0.0 ) vColor += getMorph( gl_VertexID, i, 2 ).rgb * morphTargetInfluences[ i ];
              #endif
          }
      #endif
      vec3 objectNormal = vec3( normal );
      #ifdef USE_TANGENT
          vec3 objectTangent = vec3( tangent.xyz );
      #endif
      #ifdef USE_MORPHNORMALS
          objectNormal *= morphTargetBaseInfluence;
          #ifdef MORPHTARGETS_TEXTURE
              for ( int i = 0; i < MORPHTARGETS_COUNT; i ++ ) {
                  if ( morphTargetInfluences[ i ] ! = 0.0 ) objectNormal += getMorph( gl_VertexID, i, 1 ).xyz * morphTargetInfluences[ i ];
              }
          #else
              objectNormal += morphNormal0 * morphTargetInfluences[ 0 ];
              objectNormal += morphNormal1 * morphTargetInfluences[ 1 ];
              objectNormal += morphNormal2 * morphTargetInfluences[ 2 ];
              objectNormal += morphNormal3 * morphTargetInfluences[ 3 ];
          #endif
      #endif
      #ifdef USE_SKINNING
          mat4 boneMatX = getBoneMatrix( skinIndex.x );
          mat4 boneMatY = getBoneMatrix( skinIndex.y );
          mat4 boneMatZ = getBoneMatrix( skinIndex.z );
          mat4 boneMatW = getBoneMatrix( skinIndex.w );
      #endif
      #ifdef USE_SKINNING
          mat4 skinMatrix = mat4( 0.0 );
          skinMatrix += skinWeight.x * boneMatX;
          skinMatrix += skinWeight.y * boneMatY;
          skinMatrix += skinWeight.z * boneMatZ;
          skinMatrix += skinWeight.w * boneMatW;
          skinMatrix = bindMatrixInverse * skinMatrix * bindMatrix;
          objectNormal = vec4( skinMatrix * vec4( objectNormal, 0.0 ) ).xyz;
          #ifdef USE_TANGENT
              objectTangent = vec4( skinMatrix * vec4( objectTangent, 0.0 ) ).xyz;
          #endif
      #endif
      vec3 transformedNormal = objectNormal;
      #ifdef USE_INSTANCING
          mat3 m = mat3( instanceMatrix );
          transformedNormal /= vec3( dot( m[ 0 ], m[ 0 ] ), dot( m[ 1 ], m[ 1 ] ), dot( m[ 2 ], m[ 2 ] ) );
          transformedNormal = m * transformedNormal;
      #endif
      transformedNormal = normalMatrix * transformedNormal;
      #ifdef FLIP_SIDED
          transformedNormal = - transformedNormal;
      #endif
      #ifdef USE_TANGENT
          vec3 transformedTangent = ( modelViewMatrix * vec4( objectTangent, 0.0 ) ).xyz;
          #ifdef FLIP_SIDED
              transformedTangent = - transformedTangent;
          #endif
      #endif
      #ifndef FLAT_SHADED
          vNormal = normalize( transformedNormal );
          #ifdef USE_TANGENT
              vTangent = normalize( transformedTangent );
              vBitangent = normalize( cross( vNormal, vTangent ) * tangent.w );
          #endif
      #endif
      vec3 transformed = vec3( position );
      #ifdef USE_MORPHTARGETS
          transformed *= morphTargetBaseInfluence;
          #ifdef MORPHTARGETS_TEXTURE
              for ( int i = 0; i < MORPHTARGETS_COUNT; i ++ ) {
                  if ( morphTargetInfluences[ i ] ! = 0.0 ) transformed += getMorph( gl_VertexID, i, 0 ).xyz * morphTargetInfluences[ i ];
              }
          #else
              transformed += morphTarget0 * morphTargetInfluences[ 0 ];
              transformed += morphTarget1 * morphTargetInfluences[ 1 ];
              transformed += morphTarget2 * morphTargetInfluences[ 2 ];
              transformed += morphTarget3 * morphTargetInfluences[ 3 ];
              #ifndef USE_MORPHNORMALS
                  transformed += morphTarget4 * morphTargetInfluences[ 4 ];
                  transformed += morphTarget5 * morphTargetInfluences[ 5 ];
                  transformed += morphTarget6 * morphTargetInfluences[ 6 ];
                  transformed += morphTarget7 * morphTargetInfluences[ 7 ];
              #endif
          #endif
      #endif
      #ifdef USE_SKINNING
          vec4 skinVertex = bindMatrix * vec4( transformed, 1.0 );
          vec4 skinned = vec4( 0.0 );
          skinned += boneMatX * skinVertex * skinWeight.x;
          skinned += boneMatY * skinVertex * skinWeight.y;
          skinned += boneMatZ * skinVertex * skinWeight.z;
          skinned += boneMatW * skinVertex * skinWeight.w;
          transformed = ( bindMatrixInverse * skinned ).xyz;
      #endif
      #ifdef USE_DISPLACEMENTMAP
          transformed += normalize( objectNormal ) * ( texture2D( displacementMap, vUv ).x * displacementScale + displacementBias );
      #endif
      vec4 mvPosition = vec4( transformed, 1.0 );
      #ifdef USE_INSTANCING
          mvPosition = instanceMatrix * mvPosition;
      #endif
      mvPosition = modelViewMatrix * mvPosition;
      gl_Position = projectionMatrix * mvPosition;
      #ifdef USE_LOGDEPTHBUF
          #ifdef USE_LOGDEPTHBUF_EXT
              vFragDepth = 1.0 + gl_Position.w;
              vIsPerspective = float( isPerspectiveMatrix( projectionMatrix ) );
          #else
              if ( isPerspectiveMatrix( projectionMatrix ) ) {
                  gl_Position.z = log2( max( EPSILON, gl_Position.w + 1.0 ) ) * logDepthBufFC - 1.0;
                  gl_Position.z *= gl_Position.w;
              }
          #endif
      #endif
      #if 0 > 0
          vClipPosition = - mvPosition.xyz;
      #endif
      vViewPosition = - mvPosition.xyz;
      #if defined( USE_ENVMAP ) || defined( DISTANCE ) || defined ( USE_SHADOWMAP ) || defined ( USE_TRANSMISSION ) || 0 > 0
          vec4 worldPosition = vec4( transformed, 1.0 );
          #ifdef USE_INSTANCING
              worldPosition = instanceMatrix * worldPosition;
          #endif
          worldPosition = modelMatrix * worldPosition;
      #endif
      #if ( defined( USE_SHADOWMAP ) && ( 0 > 0 || 0 > 0 ) ) || ( 0 > 0 )
          vec3 shadowWorldNormal = inverseTransformDirection( transformedNormal, viewMatrix );
          vec4 shadowWorldPosition;
      #endif
      #if defined( USE_SHADOWMAP )
          #if 0 > 0
              
          #endif
          #if 0 > 0
              
          #endif
      #endif
      #if 0 > 0
          
      #endif
      #ifdef USE_FOG
          vFogDepth = - mvPosition.z;
      #endif
      #ifdef USE_TRANSMISSION
          vWorldPosition = worldPosition.xyz;
      #endif
  }
  
  
      `;

    const shaderFrag = `
  
        ${shaderVaryings}
        ${shaderUniforms}
        ${shaderFunctions}
  
  // ---------------------------------------------------------
  
  #define STANDARD
  #ifdef PHYSICAL
      #define IOR
      #define SPECULAR
  #endif
  uniform vec3 diffuse;
  uniform vec3 emissive;
  uniform float roughness;
  uniform float metalness;
  uniform float opacity;
  #ifdef IOR
      uniform float ior;
  #endif
  #ifdef SPECULAR
      uniform float specularIntensity;
      uniform vec3 specularColor;
      #ifdef USE_SPECULARINTENSITYMAP
          uniform sampler2D specularIntensityMap;
      #endif
      #ifdef USE_SPECULARCOLORMAP
          uniform sampler2D specularColorMap;
      #endif
  #endif
  #ifdef USE_CLEARCOAT
      uniform float clearcoat;
      uniform float clearcoatRoughness;
  #endif
  #ifdef USE_IRIDESCENCE
      uniform float iridescence;
      uniform float iridescenceIOR;
      uniform float iridescenceThicknessMinimum;
      uniform float iridescenceThicknessMaximum;
  #endif
  #ifdef USE_SHEEN
      uniform vec3 sheenColor;
      uniform float sheenRoughness;
      #ifdef USE_SHEENCOLORMAP
          uniform sampler2D sheenColorMap;
      #endif
      #ifdef USE_SHEENROUGHNESSMAP
          uniform sampler2D sheenRoughnessMap;
      #endif
  #endif
  varying vec3 vViewPosition;
  #define PI 3.141592653589793
  #define PI2 6.283185307179586
  #define PI_HALF 1.5707963267948966
  #define RECIPROCAL_PI 0.3183098861837907
  #define RECIPROCAL_PI2 0.15915494309189535
  #define EPSILON 1e-6
  #ifndef saturate
      #define saturate( a ) clamp( a, 0.0, 1.0 )
  #endif
  #define whiteComplement( a ) ( 1.0 - saturate( a ) )
  float pow2( const in float x ) {
      return x*x;
  }
  vec3 pow2( const in vec3 x ) {
      return x*x;
  }
  float pow3( const in float x ) {
      return x*x*x;
  }
  float pow4( const in float x ) {
      float x2 = x*x;
      return x2*x2;
  }
  float max3( const in vec3 v ) {
      return max( max( v.x, v.y ), v.z );
  }
  float average( const in vec3 v ) {
      return dot( v, vec3( 0.3333333 ) );
  }
  highp float rand( const in vec2 uv ) {
      const highp float a = 12.9898, b = 78.233, c = 43758.5453;
      highp float dt = dot( uv.xy, vec2( a, b ) ), sn = mod( dt, PI );
      return fract( sin( sn ) * c );
  }
  #ifdef HIGH_PRECISION
      float precisionSafeLength( vec3 v ) {
          return length( v );
      }
  #else
      float precisionSafeLength( vec3 v ) {
          float maxComponent = max3( abs( v ) );
          return length( v / maxComponent ) * maxComponent;
      }
  #endif
  struct IncidentLight {
      vec3 color;
      vec3 direction;
      bool visible;
  };
  struct ReflectedLight {
      vec3 directDiffuse;
      vec3 directSpecular;
      vec3 indirectDiffuse;
      vec3 indirectSpecular;
  };
  struct GeometricContext {
      vec3 position;
      vec3 normal;
      vec3 viewDir;
      #ifdef USE_CLEARCOAT
          vec3 clearcoatNormal;
      #endif
  };
  vec3 transformDirection( in vec3 dir, in mat4 matrix ) {
      return normalize( ( matrix * vec4( dir, 0.0 ) ).xyz );
  }
  vec3 inverseTransformDirection( in vec3 dir, in mat4 matrix ) {
      return normalize( ( vec4( dir, 0.0 ) * matrix ).xyz );
  }
  mat3 transposeMat3( const in mat3 m ) {
      mat3 tmp;
      tmp[ 0 ] = vec3( m[ 0 ].x, m[ 1 ].x, m[ 2 ].x );
      tmp[ 1 ] = vec3( m[ 0 ].y, m[ 1 ].y, m[ 2 ].y );
      tmp[ 2 ] = vec3( m[ 0 ].z, m[ 1 ].z, m[ 2 ].z );
      return tmp;
  }
  float luminance( const in vec3 rgb ) {
      const vec3 weights = vec3( 0.2126729, 0.7151522, 0.0721750 );
      return dot( weights, rgb );
  }
  bool isPerspectiveMatrix( mat4 m ) {
      return m[ 2 ][ 3 ] == - 1.0;
  }
  vec2 equirectUv( in vec3 dir ) {
      float u = atan( dir.z, dir.x ) * RECIPROCAL_PI2 + 0.5;
      float v = asin( clamp( dir.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
      return vec2( u, v );
  }
  float w0( float a ) {
      return ( 1.0 / 6.0 ) * ( a * ( a * ( - a + 3.0 ) - 3.0 ) + 1.0 );
  }
  float w1( float a ) {
      return ( 1.0 / 6.0 ) * ( a *  a * ( 3.0 * a - 6.0 ) + 4.0 );
  }
  float w2( float a ) {
      return ( 1.0 / 6.0 ) * ( a * ( a * ( - 3.0 * a + 3.0 ) + 3.0 ) + 1.0 );
  }
  float w3( float a ) {
      return ( 1.0 / 6.0 ) * ( a * a * a );
  }
  float g0( float a ) {
      return w0( a ) + w1( a );
  }
  float g1( float a ) {
      return w2( a ) + w3( a );
  }
  float h0( float a ) {
      return - 1.0 + w1( a ) / ( w0( a ) + w1( a ) );
  }
  float h1( float a ) {
      return 1.0 + w3( a ) / ( w2( a ) + w3( a ) );
  }
  vec4 bicubic( sampler2D tex, vec2 uv, vec4 texelSize, vec2 fullSize, float lod ) {
      uv = uv * texelSize.zw + 0.5;
      vec2 iuv = floor( uv );
      vec2 fuv = fract( uv );
      float g0x = g0( fuv.x );
      float g1x = g1( fuv.x );
      float h0x = h0( fuv.x );
      float h1x = h1( fuv.x );
      float h0y = h0( fuv.y );
      float h1y = h1( fuv.y );
      vec2 p0 = ( vec2( iuv.x + h0x, iuv.y + h0y ) - 0.5 ) * texelSize.xy;
      vec2 p1 = ( vec2( iuv.x + h1x, iuv.y + h0y ) - 0.5 ) * texelSize.xy;
      vec2 p2 = ( vec2( iuv.x + h0x, iuv.y + h1y ) - 0.5 ) * texelSize.xy;
      vec2 p3 = ( vec2( iuv.x + h1x, iuv.y + h1y ) - 0.5 ) * texelSize.xy;
      vec2 lodFudge = pow( 1.95, lod ) / fullSize;
      return g0( fuv.y ) * ( g0x * textureLod( tex, p0, lod ) + g1x * textureLod( tex, p1, lod ) ) +
      g1( fuv.y ) * ( g0x * textureLod( tex, p2, lod ) + g1x * textureLod( tex, p3, lod ) );
  }
  vec4 textureBicubic( sampler2D sampler, vec2 uv, float lod ) {
      vec2 fLodSize = vec2( textureSize( sampler, int( lod ) ) );
      vec2 cLodSize = vec2( textureSize( sampler, int( lod + 1.0 ) ) );
      vec2 fLodSizeInv = 1.0 / fLodSize;
      vec2 cLodSizeInv = 1.0 / cLodSize;
      vec2 fullSize = vec2( textureSize( sampler, 0 ) );
      vec4 fSample = bicubic( sampler, uv, vec4( fLodSizeInv, fLodSize ), fullSize, floor( lod ) );
      vec4 cSample = bicubic( sampler, uv, vec4( cLodSizeInv, cLodSize ), fullSize, ceil( lod ) );
      return mix( fSample, cSample, fract( lod ) );
  }
  #if ( defined( USE_UV ) && ! defined( UVS_VERTEX_ONLY ) )
      varying vec2 vUv;
  #endif
  #ifdef USE_MAP
      uniform sampler2D map;
  #endif
  #ifdef USE_LIGHTMAP
      uniform sampler2D lightMap;
      uniform float lightMapIntensity;
  #endif
  vec3 BRDF_Lambert( const in vec3 diffuseColor ) {
      return RECIPROCAL_PI * diffuseColor;
  }
  vec3 F_Schlick( const in vec3 f0, const in float f90, const in float dotVH ) {
      float fresnel = exp2( ( - 5.55473 * dotVH - 6.98316 ) * dotVH );
      return f0 * ( 1.0 - fresnel ) + ( f90 * fresnel );
  }
  float F_Schlick( const in float f0, const in float f90, const in float dotVH ) {
      float fresnel = exp2( ( - 5.55473 * dotVH - 6.98316 ) * dotVH );
      return f0 * ( 1.0 - fresnel ) + ( f90 * fresnel );
  }
  vec3 Schlick_to_F0( const in vec3 f, const in float f90, const in float dotVH ) {
      float x = clamp( 1.0 - dotVH, 0.0, 1.0 );
      float x2 = x * x;
      float x5 = clamp( x * x2 * x2, 0.0, 0.9999 );
      return ( f - vec3( f90 ) * x5 ) / ( 1.0 - x5 );
  }
  float V_GGX_SmithCorrelated( const in float alpha, const in float dotNL, const in float dotNV ) {
      float a2 = pow2( alpha );
      float gv = dotNL * sqrt( a2 + ( 1.0 - a2 ) * pow2( dotNV ) );
      float gl = dotNV * sqrt( a2 + ( 1.0 - a2 ) * pow2( dotNL ) );
      return 0.5 / max( gv + gl, EPSILON );
  }
  float D_GGX( const in float alpha, const in float dotNH ) {
      float a2 = pow2( alpha );
      float denom = pow2( dotNH ) * ( a2 - 1.0 ) + 1.0;
      return RECIPROCAL_PI * a2 / pow2( denom );
  }
  vec3 BRDF_GGX( const in vec3 lightDir, const in vec3 viewDir, const in vec3 normal, const in vec3 f0, const in float f90, const in float roughness ) {
      float alpha = pow2( roughness );
      vec3 halfDir = normalize( lightDir + viewDir );
      float dotNL = saturate( dot( normal, lightDir ) );
      float dotNV = saturate( dot( normal, viewDir ) );
      float dotNH = saturate( dot( normal, halfDir ) );
      float dotVH = saturate( dot( viewDir, halfDir ) );
      vec3 F = F_Schlick( f0, f90, dotVH );
      float V = V_GGX_SmithCorrelated( alpha, dotNL, dotNV );
      float D = D_GGX( alpha, dotNH );
      return F * ( V * D );
  }
  #ifdef USE_IRIDESCENCE
      vec3 BRDF_GGX_Iridescence( const in vec3 lightDir, const in vec3 viewDir, const in vec3 normal, const in vec3 f0, const in float f90, const in float iridescence, const in vec3 iridescenceFresnel, const in float roughness ) {
          float alpha = pow2( roughness );
          vec3 halfDir = normalize( lightDir + viewDir );
          float dotNL = saturate( dot( normal, lightDir ) );
          float dotNV = saturate( dot( normal, viewDir ) );
          float dotNH = saturate( dot( normal, halfDir ) );
          float dotVH = saturate( dot( viewDir, halfDir ) );
          vec3 F = mix( F_Schlick( f0, f90, dotVH ), iridescenceFresnel, iridescence );
          float V = V_GGX_SmithCorrelated( alpha, dotNL, dotNV );
          float D = D_GGX( alpha, dotNH );
          return F * ( V * D );
      }
  #endif
  vec2 LTC_Uv( const in vec3 N, const in vec3 V, const in float roughness ) {
      const float LUT_SIZE = 64.0;
      const float LUT_SCALE = ( LUT_SIZE - 1.0 ) / LUT_SIZE;
      const float LUT_BIAS = 0.5 / LUT_SIZE;
      float dotNV = saturate( dot( N, V ) );
      vec2 uv = vec2( roughness, sqrt( 1.0 - dotNV ) );
      uv = uv * LUT_SCALE + LUT_BIAS;
      return uv;
  }
  float LTC_ClippedSphereFormFactor( const in vec3 f ) {
      float l = length( f );
      return max( ( l * l + f.z ) / ( l + 1.0 ), 0.0 );
  }
  vec3 LTC_EdgeVectorFormFactor( const in vec3 v1, const in vec3 v2 ) {
      float x = dot( v1, v2 );
      float y = abs( x );
      float a = 0.8543985 + ( 0.4965155 + 0.0145206 * y ) * y;
      float b = 3.4175940 + ( 4.1616724 + y ) * y;
      float v = a / b;
      float theta_sintheta = ( x > 0.0 ) ? v : 0.5 * inversesqrt( max( 1.0 - x * x, 1e-7 ) ) - v;
      return cross( v1, v2 ) * theta_sintheta;
  }
  vec3 LTC_Evaluate( const in vec3 N, const in vec3 V, const in vec3 P, const in mat3 mInv, const in vec3 rectCoords[ 4 ] ) {
      vec3 v1 = rectCoords[ 1 ] - rectCoords[ 0 ];
      vec3 v2 = rectCoords[ 3 ] - rectCoords[ 0 ];
      vec3 lightNormal = cross( v1, v2 );
      if( dot( lightNormal, P - rectCoords[ 0 ] ) < 0.0 ) return vec3( 0.0 );
      vec3 T1, T2;
      T1 = normalize( V - N * dot( V, N ) );
      T2 = - cross( N, T1 );
      mat3 mat = mInv * transposeMat3( mat3( T1, T2, N ) );
      vec3 coords[ 4 ];
      coords[ 0 ] = mat * ( rectCoords[ 0 ] - P );
      coords[ 1 ] = mat * ( rectCoords[ 1 ] - P );
      coords[ 2 ] = mat * ( rectCoords[ 2 ] - P );
      coords[ 3 ] = mat * ( rectCoords[ 3 ] - P );
      coords[ 0 ] = normalize( coords[ 0 ] );
      coords[ 1 ] = normalize( coords[ 1 ] );
      coords[ 2 ] = normalize( coords[ 2 ] );
      coords[ 3 ] = normalize( coords[ 3 ] );
      vec3 vectorFormFactor = vec3( 0.0 );
      vectorFormFactor += LTC_EdgeVectorFormFactor( coords[ 0 ], coords[ 1 ] );
      vectorFormFactor += LTC_EdgeVectorFormFactor( coords[ 1 ], coords[ 2 ] );
      vectorFormFactor += LTC_EdgeVectorFormFactor( coords[ 2 ], coords[ 3 ] );
      vectorFormFactor += LTC_EdgeVectorFormFactor( coords[ 3 ], coords[ 0 ] );
      float result = LTC_ClippedSphereFormFactor( vectorFormFactor );
      return vec3( result );
  }
  float G_BlinnPhong_Implicit( ) {
      return 0.25;
  }
  float D_BlinnPhong( const in float shininess, const in float dotNH ) {
      return RECIPROCAL_PI * ( shininess * 0.5 + 1.0 ) * pow( dotNH, shininess );
  }
  vec3 BRDF_BlinnPhong( const in vec3 lightDir, const in vec3 viewDir, const in vec3 normal, const in vec3 specularColor, const in float shininess ) {
      vec3 halfDir = normalize( lightDir + viewDir );
      float dotNH = saturate( dot( normal, halfDir ) );
      float dotVH = saturate( dot( viewDir, halfDir ) );
      vec3 F = F_Schlick( specularColor, 1.0, dotVH );
      float G = G_BlinnPhong_Implicit( );
      float D = D_BlinnPhong( shininess, dotNH );
      return F * ( G * D );
  }
  #if defined( USE_SHEEN )
      float D_Charlie( float roughness, float dotNH ) {
          float alpha = pow2( roughness );
          float invAlpha = 1.0 / alpha;
          float cos2h = dotNH * dotNH;
          float sin2h = max( 1.0 - cos2h, 0.0078125 );
          return ( 2.0 + invAlpha ) * pow( sin2h, invAlpha * 0.5 ) / ( 2.0 * PI );
      }
      float V_Neubelt( float dotNV, float dotNL ) {
          return saturate( 1.0 / ( 4.0 * ( dotNL + dotNV - dotNL * dotNV ) ) );
      }
      vec3 BRDF_Sheen( const in vec3 lightDir, const in vec3 viewDir, const in vec3 normal, vec3 sheenColor, const in float sheenRoughness ) {
          vec3 halfDir = normalize( lightDir + viewDir );
          float dotNL = saturate( dot( normal, lightDir ) );
          float dotNV = saturate( dot( normal, viewDir ) );
          float dotNH = saturate( dot( normal, halfDir ) );
          float D = D_Charlie( sheenRoughness, dotNH );
          float V = V_Neubelt( dotNV, dotNL );
          return sheenColor * ( D * V );
      }
  #endif
  uniform bool receiveShadow;
  uniform vec3 ambientLightColor;
  uniform vec3 lightProbe[ 9 ];
  vec3 shGetIrradianceAt( in vec3 normal, in vec3 shCoefficients[ 9 ] ) {
      float x = normal.x, y = normal.y, z = normal.z;
      vec3 result = shCoefficients[ 0 ] * 0.886227;
      result += shCoefficients[ 1 ] * 2.0 * 0.511664 * y;
      result += shCoefficients[ 2 ] * 2.0 * 0.511664 * z;
      result += shCoefficients[ 3 ] * 2.0 * 0.511664 * x;
      result += shCoefficients[ 4 ] * 2.0 * 0.429043 * x * y;
      result += shCoefficients[ 5 ] * 2.0 * 0.429043 * y * z;
      result += shCoefficients[ 6 ] * ( 0.743125 * z * z - 0.247708 );
      result += shCoefficients[ 7 ] * 2.0 * 0.429043 * x * z;
      result += shCoefficients[ 8 ] * 0.429043 * ( x * x - y * y );
      return result;
  }
  vec3 getLightProbeIrradiance( const in vec3 lightProbe[ 9 ], const in vec3 normal ) {
      vec3 worldNormal = inverseTransformDirection( normal, viewMatrix );
      vec3 irradiance = shGetIrradianceAt( worldNormal, lightProbe );
      return irradiance;
  }
  vec3 getAmbientLightIrradiance( const in vec3 ambientLightColor ) {
      vec3 irradiance = ambientLightColor;
      return irradiance;
  }
  float getDistanceAttenuation( const in float lightDistance, const in float cutoffDistance, const in float decayExponent ) {
      #if defined ( LEGACY_LIGHTS )
          if ( cutoffDistance > 0.0 && decayExponent > 0.0 ) {
              return pow( saturate( - lightDistance / cutoffDistance + 1.0 ), decayExponent );
          }
          return 1.0;
      #else
          float distanceFalloff = 1.0 / max( pow( lightDistance, decayExponent ), 0.01 );
          if ( cutoffDistance > 0.0 ) {
              distanceFalloff *= pow2( saturate( 1.0 - pow4( lightDistance / cutoffDistance ) ) );
          }
          return distanceFalloff;
      #endif
  }
  float getSpotAttenuation( const in float coneCosine, const in float penumbraCosine, const in float angleCosine ) {
      return smoothstep( coneCosine, penumbraCosine, angleCosine );
  }
  #if 1 > 0
      struct DirectionalLight {
          vec3 direction;
          vec3 color;
      };
      uniform DirectionalLight directionalLights[ 1 ];
      void getDirectionalLightInfo( const in DirectionalLight directionalLight, const in GeometricContext geometry, out IncidentLight light ) {
          light.color = directionalLight.color;
          light.direction = directionalLight.direction;
          light.visible = true;
      }
  #endif
  #if 0 > 0
      struct PointLight {
          vec3 position;
          vec3 color;
          float distance;
          float decay;
      };
      uniform PointLight pointLights[ 0 ];
      void getPointLightInfo( const in PointLight pointLight, const in GeometricContext geometry, out IncidentLight light ) {
          vec3 lVector = pointLight.position - geometry.position;
          light.direction = normalize( lVector );
          float lightDistance = length( lVector );
          light.color = pointLight.color;
          light.color *= getDistanceAttenuation( lightDistance, pointLight.distance, pointLight.decay );
          light.visible = ( light.color ! = vec3( 0.0 ) );
      }
  #endif
  #if 0 > 0
      struct SpotLight {
          vec3 position;
          vec3 direction;
          vec3 color;
          float distance;
          float decay;
          float coneCos;
          float penumbraCos;
      };
      uniform SpotLight spotLights[ 0 ];
      void getSpotLightInfo( const in SpotLight spotLight, const in GeometricContext geometry, out IncidentLight light ) {
          vec3 lVector = spotLight.position - geometry.position;
          light.direction = normalize( lVector );
          float angleCos = dot( light.direction, spotLight.direction );
          float spotAttenuation = getSpotAttenuation( spotLight.coneCos, spotLight.penumbraCos, angleCos );
          if ( spotAttenuation > 0.0 ) {
              float lightDistance = length( lVector );
              light.color = spotLight.color * spotAttenuation;
              light.color *= getDistanceAttenuation( lightDistance, spotLight.distance, spotLight.decay );
              light.visible = ( light.color ! = vec3( 0.0 ) );
          }
          else {
              light.color = vec3( 0.0 );
              light.visible = false;
          }
      
      }
  #endif
  #if 0 > 0
      struct RectAreaLight {
          vec3 color;
          vec3 position;
          vec3 halfWidth;
          vec3 halfHeight;
      };
      uniform sampler2D ltc_1;
      uniform sampler2D ltc_2;
      uniform RectAreaLight rectAreaLights[ 0 ];
  #endif
  #if 0 > 0
      struct HemisphereLight {
          vec3 direction;
          vec3 skyColor;
          vec3 groundColor;
      };
      uniform HemisphereLight hemisphereLights[ 0 ];
      vec3 getHemisphereLightIrradiance( const in HemisphereLight hemiLight, const in vec3 normal ) {
          float dotNL = dot( normal, hemiLight.direction );
          float hemiDiffuseWeight = 0.5 * dotNL + 0.5;
          vec3 irradiance = mix( hemiLight.groundColor, hemiLight.skyColor, hemiDiffuseWeight );
          return irradiance;
      }
  #endif
  #ifndef FLAT_SHADED
      varying vec3 vNormal;
      #ifdef USE_TANGENT
          varying vec3 vTangent;
          varying vec3 vBitangent;
      #endif
  #endif
  struct PhysicalMaterial {
      vec3 diffuseColor;
      float roughness;
      vec3 specularColor;
      float specularF90;
      #ifdef USE_CLEARCOAT
          float clearcoat;
          float clearcoatRoughness;
          vec3 clearcoatF0;
          float clearcoatF90;
      #endif
      #ifdef USE_IRIDESCENCE
          float iridescence;
          float iridescenceIOR;
          float iridescenceThickness;
          vec3 iridescenceFresnel;
          vec3 iridescenceF0;
      #endif
      #ifdef USE_SHEEN
          vec3 sheenColor;
          float sheenRoughness;
      #endif
      #ifdef IOR
          float ior;
      #endif
      #ifdef USE_TRANSMISSION
          float transmission;
          float transmissionAlpha;
          float thickness;
          float attenuationDistance;
          vec3 attenuationColor;
      #endif
  };
  vec3 clearcoatSpecular = vec3( 0.0 );
  vec3 sheenSpecular = vec3( 0.0 );
  float IBLSheenBRDF( const in vec3 normal, const in vec3 viewDir, const in float roughness ) {
      float dotNV = saturate( dot( normal, viewDir ) );
      float r2 = roughness * roughness;
      float a = roughness < 0.25 ? -339.2 * r2 + 161.4 * roughness - 25.9 : -8.48 * r2 + 14.3 * roughness - 9.95;
      float b = roughness < 0.25 ? 44.0 * r2 - 23.7 * roughness + 3.26 : 1.97 * r2 - 3.27 * roughness + 0.72;
      float DG = exp( a * dotNV + b ) + ( roughness < 0.25 ? 0.0 : 0.1 * ( roughness - 0.25 ) );
      return saturate( DG * RECIPROCAL_PI );
  }
  vec2 DFGApprox( const in vec3 normal, const in vec3 viewDir, const in float roughness ) {
      float dotNV = saturate( dot( normal, viewDir ) );
      const vec4 c0 = vec4( - 1, - 0.0275, - 0.572, 0.022 );
      const vec4 c1 = vec4( 1, 0.0425, 1.04, - 0.04 );
      vec4 r = roughness * c0 + c1;
      float a004 = min( r.x * r.x, exp2( - 9.28 * dotNV ) ) * r.x + r.y;
      vec2 fab = vec2( - 1.04, 1.04 ) * a004 + r.zw;
      return fab;
  }
  vec3 EnvironmentBRDF( const in vec3 normal, const in vec3 viewDir, const in vec3 specularColor, const in float specularF90, const in float roughness ) {
      vec2 fab = DFGApprox( normal, viewDir, roughness );
      return specularColor * fab.x + specularF90 * fab.y;
  }
  #ifdef USE_IRIDESCENCE
      void computeMultiscatteringIridescence( const in vec3 normal, const in vec3 viewDir, const in vec3 specularColor, const in float specularF90, const in float iridescence, const in vec3 iridescenceF0, const in float roughness, inout vec3 singleScatter, inout vec3 multiScatter ) {
  #else
      void computeMultiscattering( const in vec3 normal, const in vec3 viewDir, const in vec3 specularColor, const in float specularF90, const in float roughness, inout vec3 singleScatter, inout vec3 multiScatter ) {
      #endif
      vec2 fab = DFGApprox( normal, viewDir, roughness );
      #ifdef USE_IRIDESCENCE
          vec3 Fr = mix( specularColor, iridescenceF0, iridescence );
      #else
          vec3 Fr = specularColor;
      #endif
      vec3 FssEss = Fr * fab.x + specularF90 * fab.y;
      float Ess = fab.x + fab.y;
      float Ems = 1.0 - Ess;
      vec3 Favg = Fr + ( 1.0 - Fr ) * 0.047619;
      vec3 Fms = FssEss * Favg / ( 1.0 - Ems * Favg );
      singleScatter += FssEss;
      multiScatter += Fms * Ems;
  }
  #if 0 > 0
      void RE_Direct_RectArea_Physical( const in RectAreaLight rectAreaLight, const in GeometricContext geometry, const in PhysicalMaterial material, inout ReflectedLight reflectedLight ) {
          vec3 normal = geometry.normal;
          vec3 viewDir = geometry.viewDir;
          vec3 position = geometry.position;
          vec3 lightPos = rectAreaLight.position;
          vec3 halfWidth = rectAreaLight.halfWidth;
          vec3 halfHeight = rectAreaLight.halfHeight;
          vec3 lightColor = rectAreaLight.color;
          float roughness = material.roughness;
          vec3 rectCoords[ 4 ];
          rectCoords[ 0 ] = lightPos + halfWidth - halfHeight;
          rectCoords[ 1 ] = lightPos - halfWidth - halfHeight;
          rectCoords[ 2 ] = lightPos - halfWidth + halfHeight;
          rectCoords[ 3 ] = lightPos + halfWidth + halfHeight;
          vec2 uv = LTC_Uv( normal, viewDir, roughness );
          vec4 t1 = texture2D( ltc_1, uv );
          vec4 t2 = texture2D( ltc_2, uv );
          mat3 mInv = mat3(
          vec3( t1.x, 0, t1.y ), vec3(    0, 1, 0 ), vec3( t1.z, 0, t1.w )
          );
          vec3 fresnel = ( material.specularColor * t2.x + ( vec3( 1.0 ) - material.specularColor ) * t2.y );
          reflectedLight.directSpecular += lightColor * fresnel * LTC_Evaluate( normal, viewDir, position, mInv, rectCoords );
          reflectedLight.directDiffuse += lightColor * material.diffuseColor * LTC_Evaluate( normal, viewDir, position, mat3( 1.0 ), rectCoords );
      }
  #endif
  void RE_Direct_Physical( const in IncidentLight directLight, const in GeometricContext geometry, const in PhysicalMaterial material, inout ReflectedLight reflectedLight ) {
      float dotNL = saturate( dot( geometry.normal, directLight.direction ) );
      vec3 irradiance = dotNL * directLight.color;
      #ifdef USE_CLEARCOAT
          float dotNLcc = saturate( dot( geometry.clearcoatNormal, directLight.direction ) );
          vec3 ccIrradiance = dotNLcc * directLight.color;
          clearcoatSpecular += ccIrradiance * BRDF_GGX( directLight.direction, geometry.viewDir, geometry.clearcoatNormal, material.clearcoatF0, material.clearcoatF90, material.clearcoatRoughness );
      #endif
      #ifdef USE_SHEEN
          sheenSpecular += irradiance * BRDF_Sheen( directLight.direction, geometry.viewDir, geometry.normal, material.sheenColor, material.sheenRoughness );
      #endif
      #ifdef USE_IRIDESCENCE
          reflectedLight.directSpecular += irradiance * BRDF_GGX_Iridescence( directLight.direction, geometry.viewDir, geometry.normal, material.specularColor, material.specularF90, material.iridescence, material.iridescenceFresnel, material.roughness );
      #else
          reflectedLight.directSpecular += irradiance * BRDF_GGX( directLight.direction, geometry.viewDir, geometry.normal, material.specularColor, material.specularF90, material.roughness );
      #endif
      reflectedLight.directDiffuse += irradiance * BRDF_Lambert( material.diffuseColor );
  }
  void RE_IndirectDiffuse_Physical( const in vec3 irradiance, const in GeometricContext geometry, const in PhysicalMaterial material, inout ReflectedLight reflectedLight ) {
      reflectedLight.indirectDiffuse += irradiance * BRDF_Lambert( material.diffuseColor );
  }
  void RE_IndirectSpecular_Physical( const in vec3 radiance, const in vec3 irradiance, const in vec3 clearcoatRadiance, const in GeometricContext geometry, const in PhysicalMaterial material, inout ReflectedLight reflectedLight) {
      #ifdef USE_CLEARCOAT
          clearcoatSpecular += clearcoatRadiance * EnvironmentBRDF( geometry.clearcoatNormal, geometry.viewDir, material.clearcoatF0, material.clearcoatF90, material.clearcoatRoughness );
      #endif
      #ifdef USE_SHEEN
          sheenSpecular += irradiance * material.sheenColor * IBLSheenBRDF( geometry.normal, geometry.viewDir, material.sheenRoughness );
      #endif
      vec3 singleScattering = vec3( 0.0 );
      vec3 multiScattering = vec3( 0.0 );
      vec3 cosineWeightedIrradiance = irradiance * RECIPROCAL_PI;
      #ifdef USE_IRIDESCENCE
          computeMultiscatteringIridescence( geometry.normal, geometry.viewDir, material.specularColor, material.specularF90, material.iridescence, material.iridescenceFresnel, material.roughness, singleScattering, multiScattering );
      #else
          computeMultiscattering( geometry.normal, geometry.viewDir, material.specularColor, material.specularF90, material.roughness, singleScattering, multiScattering );
      #endif
      vec3 totalScattering = singleScattering + multiScattering;
      vec3 diffuse = material.diffuseColor * ( 1.0 - max( max( totalScattering.r, totalScattering.g ), totalScattering.b ) );
      reflectedLight.indirectSpecular += radiance * singleScattering;
      reflectedLight.indirectSpecular += multiScattering * cosineWeightedIrradiance;
      reflectedLight.indirectDiffuse += diffuse * cosineWeightedIrradiance;
  }
  #define RE_Direct				RE_Direct_Physical
  #define RE_Direct_RectArea		RE_Direct_RectArea_Physical
  #define RE_IndirectDiffuse		RE_IndirectDiffuse_Physical
  #define RE_IndirectSpecular		RE_IndirectSpecular_Physical
  float computeSpecularOcclusion( const in float dotNV, const in float ambientOcclusion, const in float roughness ) {
      return saturate( pow( dotNV + ambientOcclusion, exp2( - 16.0 * roughness - 1.0 ) ) - 1.0 + ambientOcclusion );
  }
  #ifdef USE_NORMALMAP
      uniform sampler2D normalMap;
      uniform vec2 normalScale;
  #endif
  #ifdef OBJECTSPACE_NORMALMAP
      uniform mat3 normalMatrix;
  #endif
  #if ! defined ( USE_TANGENT ) && ( defined ( TANGENTSPACE_NORMALMAP ) || defined ( USE_CLEARCOAT_NORMALMAP ) )
      vec3 perturbNormal2Arb( vec3 eye_pos, vec3 surf_norm, vec3 mapN, float faceDirection ) {
          vec3 q0 = dFdx( eye_pos.xyz );
          vec3 q1 = dFdy( eye_pos.xyz );
          vec2 st0 = dFdx( vUv.st );
          vec2 st1 = dFdy( vUv.st );
          vec3 N = surf_norm;
          vec3 q1perp = cross( q1, N );
          vec3 q0perp = cross( N, q0 );
          vec3 T = q1perp * st0.x + q0perp * st1.x;
          vec3 B = q1perp * st0.y + q0perp * st1.y;
          float det = max( dot( T, T ), dot( B, B ) );
          float scale = ( det == 0.0 ) ? 0.0 : faceDirection * inversesqrt( det );
          return normalize( T * ( mapN.x * scale ) + B * ( mapN.y * scale ) + N * mapN.z );
      }
  #endif
  #ifdef USE_ROUGHNESSMAP
      uniform sampler2D roughnessMap;
  #endif
  #ifdef USE_METALNESSMAP
      uniform sampler2D metalnessMap;
  #endif
  #if defined( USE_LOGDEPTHBUF ) && defined( USE_LOGDEPTHBUF_EXT )
      uniform float logDepthBufFC;
      varying float vFragDepth;
      varying float vIsPerspective;
  #endif
  
  // ---------------------------------------------------------
  
  
  
  void main() {
      // ----------------------------------------------------------------------------
      
      vec4 diffuseColor = vec4( diffuse, opacity );
      ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
      vec3 totalEmissiveRadiance = emissive;
      #if defined( USE_LOGDEPTHBUF ) && defined( USE_LOGDEPTHBUF_EXT )
          gl_FragDepthEXT = vIsPerspective == 0.0 ? gl_FragCoord.z : log2( vFragDepth ) * logDepthBufFC * 0.5;
      #endif
      #ifdef USE_MAP
          vec4 sampledDiffuseColor = texture2D( map, vUv );
          #ifdef DECODE_VIDEO_TEXTURE
              sampledDiffuseColor = vec4( mix( pow( sampledDiffuseColor.rgb * 0.9478672986 + vec3( 0.0521327014 ), vec3( 2.4 ) ), sampledDiffuseColor.rgb * 0.0773993808, vec3( lessThanEqual( sampledDiffuseColor.rgb, vec3( 0.04045 ) ) ) ), sampledDiffuseColor.w );
          #endif
          diffuseColor *= sampledDiffuseColor;
      #endif
      float roughnessFactor = roughness;
      #ifdef USE_ROUGHNESSMAP
          vec4 texelRoughness = texture2D( roughnessMap, vUv );
          roughnessFactor *= texelRoughness.g;
      #endif
      float metalnessFactor = metalness;
      #ifdef USE_METALNESSMAP
          vec4 texelMetalness = texture2D( metalnessMap, vUv );
          metalnessFactor *= texelMetalness.b;
      #endif
      // ---------------------------------
      // #include <normal_fragment_begin>
      
      float faceDirection = gl_FrontFacing ? 1.0 : - 1.0;
      #ifdef FLAT_SHADED
          vec3 fdx = dFdx( vViewPosition );
          vec3 fdy = dFdy( vViewPosition );
          vec3 normal = normalize( cross( fdx, fdy ) );
      #else
          vec3 normal = normalize( vNormal );
          #ifdef DOUBLE_SIDED
              normal = normal * faceDirection;
          #endif
          #ifdef USE_TANGENT
              vec3 tangent = normalize( vTangent );
              vec3 bitangent = normalize( vBitangent );
              #ifdef DOUBLE_SIDED
                  tangent = tangent * faceDirection;
                  bitangent = bitangent * faceDirection;
              #endif
              #if defined( TANGENTSPACE_NORMALMAP ) || defined( USE_CLEARCOAT_NORMALMAP )
                  mat3 vTBN = mat3( tangent, bitangent, normal );
              #endif
          #endif
      #endif
      // non perturbed normal for clearcoat among others
      vec3 geometryNormal = normal;
      
      
      // ---------------------------------
      
      // ---------------------------------
      // ---------------------------------
      // ---------------------------------
      // ---------------------------------
      // ---------------------------------
      // ---------------------------------
      // ---------------------------------
      // ---------------------------------
      // ---------------------------------
      
      // #include <normal_fragment_maps>
      
      #ifdef OBJECTSPACE_NORMALMAP
          // normal = texture2D( normalMap, vUv ).xyz * 2.0 - 1.0; // overrides both flatShading and attribute normals
          #ifdef FLIP_SIDED
              normal = - normal;
          #endif
          #ifdef DOUBLE_SIDED
              normal = normal * faceDirection;
          #endif
          normal = normalize( normalMatrix * normal );
          #elif defined( TANGENTSPACE_NORMALMAP )
          
          
          
          
          
          
          // float n = 0.0;
          // for (float i = 1.0; i <= 5.0; i += 1.0) {
          //   n += texture2D(noise, fract(vp.xy/5.0*i*rot(i)+i*0.3)).r / i;
          // } n *= 0.5;
          
          float texN = 0.5*texture2D( normalMap, fract(vUv*2.0)).r;
          texN += 0.5*texture2D( normalMap, fract(vUv*3.0*rot(3.14*0.33))).r;
          // texN += 0.2*texture2D( normalMap, fract(vUv*6.0*rot(3.14*0.66)) ).r;
          
          
          vec3 mapN;
          mapN.g = texN * 2.0 - 1.0;
          
          //  mapN.r = 0.5*texture2D( normalMap, fract(vUv*rot((vUv.x+vUv.y)*3.33)*vec2(10.0, 1.0))).r;
          
          // vec3 mapN = texture2D( normalMap, fract(vp.xy) ).xyz * 2.0 - 1.0;
          // vec3 mapN = vec3( n * 2.0 - 1.0);
          
          
          mapN.r = 0.0;
          mapN.g *= 4.5;
          mapN.b = 1.0+3.0;          // ***
          // mapN.xy *= normalScale;
          
          #ifdef USE_TANGENT
              normal = normalize( vTBN * mapN );
          #else
              normal = perturbNormal2Arb( - vViewPosition, normal, mapN, faceDirection );
              normal *= 0.95;
          #endif
          #elif defined( USE_BUMPMAP )
          normal = perturbNormalArb( - vViewPosition, normal, dHdxy_fwd(), faceDirection );
      #endif
      
      // ----------------------------------
      
      //	#include <lights_physical_fragment>
      
      PhysicalMaterial material;
      // material.diffuseColor = diffuseColor.rgb * ( 1.0 - metalnessFactor );
      
      vec3 dxy = max( abs( dFdx( geometryNormal ) ), abs( dFdy( geometryNormal ) ) );
      float geometryRoughness = max( max( dxy.x, dxy.y ), dxy.z );
      material.roughness = max( roughnessFactor, 0.0525 );// 0.0525 corresponds to the base mip of a 256 cubemap.
      
      material.roughness += geometryRoughness;
      material.roughness = min( material.roughness, 1.0 );
      material.roughness = 0.5;  // <<<
      material.roughness = 0.5;  // <<<
      
      #ifdef IOR
          material.ior = ior;
          #ifdef SPECULAR
              float specularIntensityFactor = specularIntensity;
              vec3 specularColorFactor = specularColor;
              #ifdef USE_SPECULARINTENSITYMAP
                  // specularIntensityFactor *= texture2D( specularIntensityMap, vUv ).a;
              #endif
              #ifdef USE_SPECULARCOLORMAP
                  // specularColorFactor *= texture2D( specularColorMap, vUv ).rgb;
              #endif
              // material.specularF90 = mix( specularIntensityFactor, 1.0, metalnessFactor );
          #else
              float specularIntensityFactor = 1.0;
              vec3 specularColorFactor = vec3( 1.0 );
              // material.specularF90 = 1.0;
              
          #endif
          material.specularColor = mix( min( pow2( ( material.ior - 1.0 ) / ( material.ior + 1.0 ) ) * specularColorFactor, vec3( 1.0 ) ) * specularIntensityFactor, diffuseColor.rgb, metalnessFactor );
      #else
          material.specularColor = mix( vec3( 0.04 ), diffuseColor.rgb, metalnessFactor+0.5 ); // <<<
          
          // material.specularF90 = 1.0;
      #endif
      #ifdef USE_CLEARCOAT
          material.clearcoat = clearcoat;
          material.clearcoatRoughness = clearcoatRoughness;
          material.clearcoatF0 = vec3( 0.04 );
          material.clearcoatF90 = 1.0;
          #ifdef USE_CLEARCOATMAP
              material.clearcoat *= texture2D( clearcoatMap, vUv ).x;
          #endif
          #ifdef USE_CLEARCOAT_ROUGHNESSMAP
              material.clearcoatRoughness *= texture2D( clearcoatRoughnessMap, vUv ).y;
          #endif
          material.clearcoat = saturate( material.clearcoat ); // Burley clearcoat model
          
          material.clearcoatRoughness = max( material.clearcoatRoughness, 0.0525 );
          material.clearcoatRoughness += geometryRoughness;
          material.clearcoatRoughness = min( material.clearcoatRoughness, 1.0 );
      #endif
      #ifdef USE_IRIDESCENCE
          material.iridescence = iridescence;
          material.iridescenceIOR = iridescenceIOR;
          #ifdef USE_IRIDESCENCEMAP
              material.iridescence *= texture2D( iridescenceMap, vUv ).r;
          #endif
          #ifdef USE_IRIDESCENCE_THICKNESSMAP
              material.iridescenceThickness = (iridescenceThicknessMaximum - iridescenceThicknessMinimum) * texture2D( iridescenceThicknessMap, vUv ).g + iridescenceThicknessMinimum;
          #else
              material.iridescenceThickness = iridescenceThicknessMaximum;
          #endif
      #endif
      #ifdef USE_SHEEN
          material.sheenColor = sheenColor;
          #ifdef USE_SHEENCOLORMAP
              material.sheenColor *= texture2D( sheenColorMap, vUv ).rgb;
          #endif
          material.sheenRoughness = clamp( sheenRoughness, 0.07, 1.0 );
          #ifdef USE_SHEENROUGHNESSMAP
              material.sheenRoughness *= texture2D( sheenRoughnessMap, vUv ).a;
          #endif
      #endif
      
      //--------------------------------------
      // #include <lights_fragment_begin>
      
      GeometricContext geometry;
      geometry.position = - vViewPosition;
      geometry.normal = normal;
      geometry.viewDir = ( isOrthographic ) ? vec3( 0, 0, 1 ) : normalize( vViewPosition );
      #ifdef USE_CLEARCOAT
          geometry.clearcoatNormal = clearcoatNormal;
      #endif
      #ifdef USE_IRIDESCENCE
          float dotNVi = saturate( dot( normal, geometry.viewDir ) );
          if ( material.iridescenceThickness == 0.0 ) {
              material.iridescence = 0.0;
          }
          else {
              material.iridescence = saturate( material.iridescence );
          }
          if ( material.iridescence > 0.0 ) {
              material.iridescenceFresnel = evalIridescence( 1.0, material.iridescenceIOR, dotNVi, material.iridescenceThickness, material.specularColor );
              // Iridescence F0 approximation
              
              material.iridescenceF0 = Schlick_to_F0( material.iridescenceFresnel, 1.0, dotNVi );
          }
      #endif
      IncidentLight directLight;
      #if ( 0 > 0 ) && defined( RE_Direct )
          PointLight pointLight;
          #if defined( USE_SHADOWMAP ) && 0 > 0
              PointLightShadow pointLightShadow;
          #endif
          
      #endif
      #if ( 0 > 0 ) && defined( RE_Direct )
          SpotLight spotLight;
          vec4 spotColor;
          vec3 spotLightCoord;
          bool inSpotLightMap;
          #if defined( USE_SHADOWMAP ) && 0 > 0
              SpotLightShadow spotLightShadow;
          #endif
          
      #endif
      #if ( 1 > 0 ) && defined( RE_Direct )
          DirectionalLight directionalLight;
          #if defined( USE_SHADOWMAP ) && 0 > 0
              DirectionalLightShadow directionalLightShadow;
          #endif
          
          directionalLight = directionalLights[ 0 ];
          // DirectionalLight directionalLight;
          // directionalLight.direction = vec3(0.0,10.0,0.0);
          // directionalLight.color = vec3(20.0);
  
          getDirectionalLightInfo( directionalLight, geometry, directLight );
          #if defined( USE_SHADOWMAP ) && ( 0 < 0 )
              directionalLightShadow = directionalLightShadows[ 0 ];
              directLight.color *= ( directLight.visible && receiveShadow ) ? getShadow( directionalShadowMap[ 0 ], directionalLightShadow.shadowMapSize, directionalLightShadow.shadowBias, directionalLightShadow.shadowRadius, vDirectionalShadowCoord[ 0 ] ) : 1.0;
          #endif
          RE_Direct( directLight, geometry, material, reflectedLight );
      #endif
      #if ( 0 > 0 ) && defined( RE_Direct_RectArea )
          RectAreaLight rectAreaLight;
      #endif
      #if defined( RE_IndirectDiffuse )
          vec3 iblIrradiance = vec3( 0.0 );
          vec3 irradiance = getAmbientLightIrradiance( ambientLightColor );
          irradiance += getLightProbeIrradiance( lightProbe, geometry.normal );
          #if ( 0 > 0 )
              
          #endif
      #endif
      #if defined( RE_IndirectSpecular )
          vec3 radiance = vec3( 0.0 );
          vec3 clearcoatRadiance = vec3( 0.0 );
      #endif
      
      //--------------------------------
      
      vec3 totalDiffuse = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse;
      vec3 totalSpecular = reflectedLight.directSpecular + reflectedLight.indirectSpecular;
      #ifdef USE_TRANSMISSION
          material.transmission = transmission;
          material.transmissionAlpha = 1.0;
          material.thickness = thickness;
          material.attenuationDistance = attenuationDistance;
          material.attenuationColor = attenuationColor;
          #ifdef USE_TRANSMISSIONMAP
              material.transmission *= texture2D( transmissionMap, vUv ).r;
          #endif
          #ifdef USE_THICKNESSMAP
              material.thickness *= texture2D( thicknessMap, vUv ).g;
          #endif
          vec3 pos = vWorldPosition;
          vec3 v = normalize( cameraPosition - pos );
          vec3 n = inverseTransformDirection( normal, viewMatrix );
          vec4 transmission = getIBLVolumeRefraction(
          n, v, material.roughness, material.diffuseColor, material.specularColor, material.specularF90, pos, modelMatrix, viewMatrix, projectionMatrix, material.ior, material.thickness, material.attenuationColor, material.attenuationDistance );
          material.transmissionAlpha = mix( material.transmissionAlpha, transmission.a, material.transmission );
          totalDiffuse = mix( totalDiffuse, transmission.rgb, material.transmission );
      #endif


      vec3 outgoingLight = totalDiffuse  + totalSpecular + totalEmissiveRadiance;


      #ifdef USE_SHEEN
          float sheenEnergyComp = 1.0 - 0.157 * max3( material.sheenColor );
          outgoingLight = outgoingLight * sheenEnergyComp + sheenSpecular;
      #endif
      #ifdef USE_CLEARCOAT
          float dotNVcc = saturate( dot( geometry.clearcoatNormal, geometry.viewDir ) );
          vec3 Fcc = F_Schlick( material.clearcoatF0, material.clearcoatF90, dotNVcc );
          outgoingLight = outgoingLight * ( 1.0 - material.clearcoat * Fcc ) + clearcoatSpecular * material.clearcoat;
      #endif
      #ifdef OPAQUE
          diffuseColor.a = 1.0;
      #endif
      #ifdef USE_TRANSMISSION
          diffuseColor.a *= material.transmissionAlpha + 0.1;
      #endif
      gl_FragColor = vec4( outgoingLight, diffuseColor.a );
      #if defined( TONE_MAPPING )
          gl_FragColor.rgb = toneMapping( gl_FragColor.rgb );
      #endif
      gl_FragColor = linearToOutputTexel( gl_FragColor );
      #ifdef USE_FOG
          #ifdef FOG_EXP2
              float fogFactor = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );
          #else
              float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );
          #endif
          gl_FragColor.rgb = mix( gl_FragColor.rgb, fogColor, fogFactor );
      #endif
      #ifdef PREMULTIPLIED_ALPHA
          gl_FragColor.rgb *= gl_FragColor.a;
      #endif
      #ifdef DITHERING
          gl_FragColor.rgb = dithering( gl_FragColor.rgb );
      #endif
      
      // ----------------------------------------------------------------------------

      vec3 col = outgoingLight * 7.5 * 0.6 * 0.75 * 1.0; // ***
      gl_FragColor = vec4(col, 1.0);
        }
  
      `;

    shader.uniforms.time = { value: 0 };
    shader.uniforms.noise = { value: 0 };
    shader.uniforms.mouse = { value: [0, 0] };
    mat.userData.shader = shader;

    shader.vertexShader = shaderVert;
    shader.fragmentShader = shaderFrag;
  };
}
function materialPatchNoise2(mat) {
  const shaderVaryings = `
    varying vec2 vuv;
    varying vec3 vp;    // global position
    varying vec3 vn;    // global normals
    varying vec3 vpc;   // position to camera
    varying vec3 vnc;   // normals to camera
  `;

  const shaderVaryingsSet = `
    vuv = uv;
    vp = position;
    vn = normal;
    vpc = (modelViewMatrix * vec4(position, 1.0)).xyz;
    vnc = normalize(normalMatrix * normal);
  `;

  const shaderUniforms = `
      uniform float time;
      uniform vec2 mouse;
      uniform sampler2D noise;
    `;

  const shaderFunctions = `
  
      #define ONE_PI 3.14159265359             
      #define TWO_PI 6.28318530718
  
      #define msin(x) sin(mod(x,TWO_PI))
      #define mcos(x) cos(mod(x,TWO_PI))
  
      #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))
  
    /* Grid to hash float (Sinus, Rotation)  */
    /* Mobile tested for 0.1...10.0 range    */
    float h21(vec2 uv) {
      float a = 291.7567292;
      float b = 94.32862056;   
      float c = 143.6456534;
      float r = 0.314;
            uv *= mat2(cos(r), sin(r), -sin(r), cos(r));
      float x = sin(mod(uv.x*a,6.283))*b;
            r = (uv.y-uv.x)*c*uv.y;
            uv *= mat2(cos(r), sin(r), -sin(r), cos(r));
      float y = sin(mod(uv.x*a,6.283))*b;
            return fract(x+y); }
  
    vec3 img2blend(vec3 a, vec3 b, float mixval, float tune) {
      vec3 m = mix(a, pow(1.0-(1.0-a)*(1.0-b),vec3(1.0+tune)), mixval);    
      return m; }
  
    `;

  mat.onBeforeCompile = shader => {
    const basicVertex = shader.vertexShader.split("void main() {");
    const basicVertexDef = basicVertex[0];
    const basicVertexMain = basicVertex[1].split("}")[0];

    const basicFragment = shader.fragmentShader.split("void main() {");
    const basicFragmentDef = basicFragment[0];
    const basicFragmentMain = basicFragment[1].split("}")[0];

    const shaderVert = `
  
        ${shaderVaryings}
        ${shaderUniforms}
  
  #define STANDARD
  varying vec3 vViewPosition;
  #ifdef USE_TRANSMISSION
      varying vec3 vWorldPosition;
  #endif
  #define PI 3.141592653589793
  #define PI2 6.283185307179586
  #define PI_HALF 1.5707963267948966
  #define RECIPROCAL_PI 0.3183098861837907
  #define RECIPROCAL_PI2 0.15915494309189535
  #define EPSILON 1e-6
  #ifndef saturate
      #define saturate( a ) clamp( a, 0.0, 1.0 )
  #endif
  #define whiteComplement( a ) ( 1.0 - saturate( a ) )
  float pow2( const in float x ) {
      return x*x;
  }
  vec3 pow2( const in vec3 x ) {
      return x*x;
  }
  float pow3( const in float x ) {
      return x*x*x;
  }
  float pow4( const in float x ) {
      float x2 = x*x;
      return x2*x2;
  }
  float max3( const in vec3 v ) {
      return max( max( v.x, v.y ), v.z );
  }
  float average( const in vec3 v ) {
      return dot( v, vec3( 0.3333333 ) );
  }
  highp float rand( const in vec2 uv ) {
      const highp float a = 12.9898, b = 78.233, c = 43758.5453;
      highp float dt = dot( uv.xy, vec2( a, b ) ), sn = mod( dt, PI );
      return fract( sin( sn ) * c );
  }
  #ifdef HIGH_PRECISION
      float precisionSafeLength( vec3 v ) {
          return length( v );
      }
  #else
      float precisionSafeLength( vec3 v ) {
          float maxComponent = max3( abs( v ) );
          return length( v / maxComponent ) * maxComponent;
      }
  #endif
  struct IncidentLight {
      vec3 color;
      vec3 direction;
      bool visible;
  };
  struct ReflectedLight {
      vec3 directDiffuse;
      vec3 directSpecular;
      vec3 indirectDiffuse;
      vec3 indirectSpecular;
  };
  struct GeometricContext {
      vec3 position;
      vec3 normal;
      vec3 viewDir;
      #ifdef USE_CLEARCOAT
          vec3 clearcoatNormal;
      #endif
  };
  vec3 transformDirection( in vec3 dir, in mat4 matrix ) {
      return normalize( ( matrix * vec4( dir, 0.0 ) ).xyz );
  }
  vec3 inverseTransformDirection( in vec3 dir, in mat4 matrix ) {
      return normalize( ( vec4( dir, 0.0 ) * matrix ).xyz );
  }
  mat3 transposeMat3( const in mat3 m ) {
      mat3 tmp;
      tmp[ 0 ] = vec3( m[ 0 ].x, m[ 1 ].x, m[ 2 ].x );
      tmp[ 1 ] = vec3( m[ 0 ].y, m[ 1 ].y, m[ 2 ].y );
      tmp[ 2 ] = vec3( m[ 0 ].z, m[ 1 ].z, m[ 2 ].z );
      return tmp;
  }
  float luminance( const in vec3 rgb ) {
      const vec3 weights = vec3( 0.2126729, 0.7151522, 0.0721750 );
      return dot( weights, rgb );
  }
  bool isPerspectiveMatrix( mat4 m ) {
      return m[ 2 ][ 3 ] == - 1.0;
  }
  vec2 equirectUv( in vec3 dir ) {
      float u = atan( dir.z, dir.x ) * RECIPROCAL_PI2 + 0.5;
      float v = asin( clamp( dir.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
      return vec2( u, v );
  }
  float w0( float a ) {
      return ( 1.0 / 6.0 ) * ( a * ( a * ( - a + 3.0 ) - 3.0 ) + 1.0 );
  }
  float w1( float a ) {
      return ( 1.0 / 6.0 ) * ( a *  a * ( 3.0 * a - 6.0 ) + 4.0 );
  }
  float w2( float a ) {
      return ( 1.0 / 6.0 ) * ( a * ( a * ( - 3.0 * a + 3.0 ) + 3.0 ) + 1.0 );
  }
  float w3( float a ) {
      return ( 1.0 / 6.0 ) * ( a * a * a );
  }
  float g0( float a ) {
      return w0( a ) + w1( a );
  }
  float g1( float a ) {
      return w2( a ) + w3( a );
  }
  float h0( float a ) {
      return - 1.0 + w1( a ) / ( w0( a ) + w1( a ) );
  }
  float h1( float a ) {
      return 1.0 + w3( a ) / ( w2( a ) + w3( a ) );
  }
  vec4 bicubic( sampler2D tex, vec2 uv, vec4 texelSize, vec2 fullSize, float lod ) {
      uv = uv * texelSize.zw + 0.5;
      vec2 iuv = floor( uv );
      vec2 fuv = fract( uv );
      float g0x = g0( fuv.x );
      float g1x = g1( fuv.x );
      float h0x = h0( fuv.x );
      float h1x = h1( fuv.x );
      float h0y = h0( fuv.y );
      float h1y = h1( fuv.y );
      vec2 p0 = ( vec2( iuv.x + h0x, iuv.y + h0y ) - 0.5 ) * texelSize.xy;
      vec2 p1 = ( vec2( iuv.x + h1x, iuv.y + h0y ) - 0.5 ) * texelSize.xy;
      vec2 p2 = ( vec2( iuv.x + h0x, iuv.y + h1y ) - 0.5 ) * texelSize.xy;
      vec2 p3 = ( vec2( iuv.x + h1x, iuv.y + h1y ) - 0.5 ) * texelSize.xy;
      vec2 lodFudge = pow( 1.95, lod ) / fullSize;
      return g0( fuv.y ) * ( g0x * textureLod( tex, p0, lod ) + g1x * textureLod( tex, p1, lod ) ) +
      g1( fuv.y ) * ( g0x * textureLod( tex, p2, lod ) + g1x * textureLod( tex, p3, lod ) );
  }
  vec4 textureBicubic( sampler2D sampler, vec2 uv, float lod ) {
      vec2 fLodSize = vec2( textureSize( sampler, int( lod ) ) );
      vec2 cLodSize = vec2( textureSize( sampler, int( lod + 1.0 ) ) );
      vec2 fLodSizeInv = 1.0 / fLodSize;
      vec2 cLodSizeInv = 1.0 / cLodSize;
      vec2 fullSize = vec2( textureSize( sampler, 0 ) );
      vec4 fSample = bicubic( sampler, uv, vec4( fLodSizeInv, fLodSize ), fullSize, floor( lod ) );
      vec4 cSample = bicubic( sampler, uv, vec4( cLodSizeInv, cLodSize ), fullSize, ceil( lod ) );
      return mix( fSample, cSample, fract( lod ) );
  }
  #ifdef USE_UV
      #ifdef UVS_VERTEX_ONLY
          vec2 vUv;
      #else
          varying vec2 vUv;
      #endif
      uniform mat3 uvTransform;
  #endif
  #if defined( USE_LIGHTMAP ) || defined( USE_AOMAP )
      attribute vec2 uv2;
      varying vec2 vUv2;
      uniform mat3 uv2Transform;
  #endif
  #ifdef USE_DISPLACEMENTMAP
      uniform sampler2D displacementMap;
      uniform float displacementScale;
      uniform float displacementBias;
  #endif
  #if defined( USE_COLOR_ALPHA )
      varying vec4 vColor;
      #elif defined( USE_COLOR ) || defined( USE_INSTANCING_COLOR )
      varying vec3 vColor;
  #endif
  #ifdef USE_FOG
      varying float vFogDepth;
  #endif
  #ifndef FLAT_SHADED
      varying vec3 vNormal;
      #ifdef USE_TANGENT
          varying vec3 vTangent;
          varying vec3 vBitangent;
      #endif
  #endif
  #ifdef USE_MORPHTARGETS
      uniform float morphTargetBaseInfluence;
      #ifdef MORPHTARGETS_TEXTURE
          uniform float morphTargetInfluences[ MORPHTARGETS_COUNT ];
          uniform sampler2DArray morphTargetsTexture;
          uniform ivec2 morphTargetsTextureSize;
          vec4 getMorph( const in int vertexIndex, const in int morphTargetIndex, const in int offset ) {
              int texelIndex = vertexIndex * MORPHTARGETS_TEXTURE_STRIDE + offset;
              int y = texelIndex / morphTargetsTextureSize.x;
              int x = texelIndex - y * morphTargetsTextureSize.x;
              ivec3 morphUV = ivec3( x, y, morphTargetIndex );
              return texelFetch( morphTargetsTexture, morphUV, 0 );
          }
      #else
          #ifndef USE_MORPHNORMALS
              uniform float morphTargetInfluences[ 8 ];
          #else
              uniform float morphTargetInfluences[ 4 ];
          #endif
      #endif
  #endif
  #ifdef USE_SKINNING
      uniform mat4 bindMatrix;
      uniform mat4 bindMatrixInverse;
      uniform highp sampler2D boneTexture;
      uniform int boneTextureSize;
      mat4 getBoneMatrix( const in float i ) {
          float j = i * 4.0;
          float x = mod( j, float( boneTextureSize ) );
          float y = floor( j / float( boneTextureSize ) );
          float dx = 1.0 / float( boneTextureSize );
          float dy = 1.0 / float( boneTextureSize );
          y = dy * ( y + 0.5 );
          vec4 v1 = texture2D( boneTexture, vec2( dx * ( x + 0.5 ), y ) );
          vec4 v2 = texture2D( boneTexture, vec2( dx * ( x + 1.5 ), y ) );
          vec4 v3 = texture2D( boneTexture, vec2( dx * ( x + 2.5 ), y ) );
          vec4 v4 = texture2D( boneTexture, vec2( dx * ( x + 3.5 ), y ) );
          mat4 bone = mat4( v1, v2, v3, v4 );
          return bone;
      }
  #endif
  #if 0 > 0
      uniform mat4 spotLightMatrix[ 0 ];
      varying vec4 vSpotLightCoord[ 0 ];
  #endif
  #ifdef USE_SHADOWMAP
      #if 0 > 0
          uniform mat4 directionalShadowMatrix[ 0 ];
          varying vec4 vDirectionalShadowCoord[ 0 ];
          struct DirectionalLightShadow {
              float shadowBias;
              float shadowNormalBias;
              float shadowRadius;
              vec2 shadowMapSize;
          };
          uniform DirectionalLightShadow directionalLightShadows[ 0 ];
      #endif
      #if 0 > 0
          struct SpotLightShadow {
              float shadowBias;
              float shadowNormalBias;
              float shadowRadius;
              vec2 shadowMapSize;
          };
          uniform SpotLightShadow spotLightShadows[ 0 ];
      #endif
      #if 0 > 0
          uniform mat4 pointShadowMatrix[ 0 ];
          varying vec4 vPointShadowCoord[ 0 ];
          struct PointLightShadow {
              float shadowBias;
              float shadowNormalBias;
              float shadowRadius;
              vec2 shadowMapSize;
              float shadowCameraNear;
              float shadowCameraFar;
          };
          uniform PointLightShadow pointLightShadows[ 0 ];
      #endif
  #endif
  #ifdef USE_LOGDEPTHBUF
      #ifdef USE_LOGDEPTHBUF_EXT
          varying float vFragDepth;
          varying float vIsPerspective;
      #else
          uniform float logDepthBufFC;
      #endif
  #endif
  #if 0 > 0
      varying vec3 vClipPosition;
  #endif
  
  
  void main() {
      ${shaderVaryingsSet}
      #ifdef USE_UV
          vUv = ( uvTransform * vec3( uv, 1 ) ).xy;
      #endif
      #if defined( USE_LIGHTMAP ) || defined( USE_AOMAP )
          vUv2 = ( uv2Transform * vec3( uv2, 1 ) ).xy;
      #endif
      #if defined( USE_COLOR_ALPHA )
          vColor = vec4( 1.0 );
          #elif defined( USE_COLOR ) || defined( USE_INSTANCING_COLOR )
          vColor = vec3( 1.0 );
      #endif
      #ifdef USE_COLOR
          vColor *= color;
      #endif
      #ifdef USE_INSTANCING_COLOR
          vColor.xyz *= instanceColor.xyz;
      #endif
      #if defined( USE_MORPHCOLORS ) && defined( MORPHTARGETS_TEXTURE )
          vColor *= morphTargetBaseInfluence;
          for ( int i = 0; i < MORPHTARGETS_COUNT; i ++ ) {
              #if defined( USE_COLOR_ALPHA )
                  if ( morphTargetInfluences[ i ] ! = 0.0 ) vColor += getMorph( gl_VertexID, i, 2 ) * morphTargetInfluences[ i ];
                  #elif defined( USE_COLOR )
                  if ( morphTargetInfluences[ i ] ! = 0.0 ) vColor += getMorph( gl_VertexID, i, 2 ).rgb * morphTargetInfluences[ i ];
              #endif
          }
      #endif
      vec3 objectNormal = vec3( normal );
      #ifdef USE_TANGENT
          vec3 objectTangent = vec3( tangent.xyz );
      #endif
      #ifdef USE_MORPHNORMALS
          objectNormal *= morphTargetBaseInfluence;
          #ifdef MORPHTARGETS_TEXTURE
              for ( int i = 0; i < MORPHTARGETS_COUNT; i ++ ) {
                  if ( morphTargetInfluences[ i ] ! = 0.0 ) objectNormal += getMorph( gl_VertexID, i, 1 ).xyz * morphTargetInfluences[ i ];
              }
          #else
              objectNormal += morphNormal0 * morphTargetInfluences[ 0 ];
              objectNormal += morphNormal1 * morphTargetInfluences[ 1 ];
              objectNormal += morphNormal2 * morphTargetInfluences[ 2 ];
              objectNormal += morphNormal3 * morphTargetInfluences[ 3 ];
          #endif
      #endif
      #ifdef USE_SKINNING
          mat4 boneMatX = getBoneMatrix( skinIndex.x );
          mat4 boneMatY = getBoneMatrix( skinIndex.y );
          mat4 boneMatZ = getBoneMatrix( skinIndex.z );
          mat4 boneMatW = getBoneMatrix( skinIndex.w );
      #endif
      #ifdef USE_SKINNING
          mat4 skinMatrix = mat4( 0.0 );
          skinMatrix += skinWeight.x * boneMatX;
          skinMatrix += skinWeight.y * boneMatY;
          skinMatrix += skinWeight.z * boneMatZ;
          skinMatrix += skinWeight.w * boneMatW;
          skinMatrix = bindMatrixInverse * skinMatrix * bindMatrix;
          objectNormal = vec4( skinMatrix * vec4( objectNormal, 0.0 ) ).xyz;
          #ifdef USE_TANGENT
              objectTangent = vec4( skinMatrix * vec4( objectTangent, 0.0 ) ).xyz;
          #endif
      #endif
      vec3 transformedNormal = objectNormal;
      #ifdef USE_INSTANCING
          mat3 m = mat3( instanceMatrix );
          transformedNormal /= vec3( dot( m[ 0 ], m[ 0 ] ), dot( m[ 1 ], m[ 1 ] ), dot( m[ 2 ], m[ 2 ] ) );
          transformedNormal = m * transformedNormal;
      #endif
      transformedNormal = normalMatrix * transformedNormal;
      #ifdef FLIP_SIDED
          transformedNormal = - transformedNormal;
      #endif
      #ifdef USE_TANGENT
          vec3 transformedTangent = ( modelViewMatrix * vec4( objectTangent, 0.0 ) ).xyz;
          #ifdef FLIP_SIDED
              transformedTangent = - transformedTangent;
          #endif
      #endif
      #ifndef FLAT_SHADED
          vNormal = normalize( transformedNormal );
          #ifdef USE_TANGENT
              vTangent = normalize( transformedTangent );
              vBitangent = normalize( cross( vNormal, vTangent ) * tangent.w );
          #endif
      #endif
      vec3 transformed = vec3( position );
      #ifdef USE_MORPHTARGETS
          transformed *= morphTargetBaseInfluence;
          #ifdef MORPHTARGETS_TEXTURE
              for ( int i = 0; i < MORPHTARGETS_COUNT; i ++ ) {
                  if ( morphTargetInfluences[ i ] ! = 0.0 ) transformed += getMorph( gl_VertexID, i, 0 ).xyz * morphTargetInfluences[ i ];
              }
          #else
              transformed += morphTarget0 * morphTargetInfluences[ 0 ];
              transformed += morphTarget1 * morphTargetInfluences[ 1 ];
              transformed += morphTarget2 * morphTargetInfluences[ 2 ];
              transformed += morphTarget3 * morphTargetInfluences[ 3 ];
              #ifndef USE_MORPHNORMALS
                  transformed += morphTarget4 * morphTargetInfluences[ 4 ];
                  transformed += morphTarget5 * morphTargetInfluences[ 5 ];
                  transformed += morphTarget6 * morphTargetInfluences[ 6 ];
                  transformed += morphTarget7 * morphTargetInfluences[ 7 ];
              #endif
          #endif
      #endif
      #ifdef USE_SKINNING
          vec4 skinVertex = bindMatrix * vec4( transformed, 1.0 );
          vec4 skinned = vec4( 0.0 );
          skinned += boneMatX * skinVertex * skinWeight.x;
          skinned += boneMatY * skinVertex * skinWeight.y;
          skinned += boneMatZ * skinVertex * skinWeight.z;
          skinned += boneMatW * skinVertex * skinWeight.w;
          transformed = ( bindMatrixInverse * skinned ).xyz;
      #endif
      #ifdef USE_DISPLACEMENTMAP
          transformed += normalize( objectNormal ) * ( texture2D( displacementMap, vUv ).x * displacementScale + displacementBias );
      #endif
      vec4 mvPosition = vec4( transformed, 1.0 );
      #ifdef USE_INSTANCING
          mvPosition = instanceMatrix * mvPosition;
      #endif
      mvPosition = modelViewMatrix * mvPosition;
      gl_Position = projectionMatrix * mvPosition;
      #ifdef USE_LOGDEPTHBUF
          #ifdef USE_LOGDEPTHBUF_EXT
              vFragDepth = 1.0 + gl_Position.w;
              vIsPerspective = float( isPerspectiveMatrix( projectionMatrix ) );
          #else
              if ( isPerspectiveMatrix( projectionMatrix ) ) {
                  gl_Position.z = log2( max( EPSILON, gl_Position.w + 1.0 ) ) * logDepthBufFC - 1.0;
                  gl_Position.z *= gl_Position.w;
              }
          #endif
      #endif
      #if 0 > 0
          vClipPosition = - mvPosition.xyz;
      #endif
      vViewPosition = - mvPosition.xyz;
      #if defined( USE_ENVMAP ) || defined( DISTANCE ) || defined ( USE_SHADOWMAP ) || defined ( USE_TRANSMISSION ) || 0 > 0
          vec4 worldPosition = vec4( transformed, 1.0 );
          #ifdef USE_INSTANCING
              worldPosition = instanceMatrix * worldPosition;
          #endif
          worldPosition = modelMatrix * worldPosition;
      #endif
      #if ( defined( USE_SHADOWMAP ) && ( 0 > 0 || 0 > 0 ) ) || ( 0 > 0 )
          vec3 shadowWorldNormal = inverseTransformDirection( transformedNormal, viewMatrix );
          vec4 shadowWorldPosition;
      #endif
      #if defined( USE_SHADOWMAP )
          #if 0 > 0
              
          #endif
          #if 0 > 0
              
          #endif
      #endif
      #if 0 > 0
          
      #endif
      #ifdef USE_FOG
          vFogDepth = - mvPosition.z;
      #endif
      #ifdef USE_TRANSMISSION
          vWorldPosition = worldPosition.xyz;
      #endif
  }
  
  
      `;

    const shaderFrag = `
  
        ${shaderVaryings}
        ${shaderUniforms}
        ${shaderFunctions}
  
  // ---------------------------------------------------------
  
  #define STANDARD
  #ifdef PHYSICAL
      #define IOR
      #define SPECULAR
  #endif
  uniform vec3 diffuse;
  uniform vec3 emissive;
  uniform float roughness;
  uniform float metalness;
  uniform float opacity;
  #ifdef IOR
      uniform float ior;
  #endif
  #ifdef SPECULAR
      uniform float specularIntensity;
      uniform vec3 specularColor;
      #ifdef USE_SPECULARINTENSITYMAP
          uniform sampler2D specularIntensityMap;
      #endif
      #ifdef USE_SPECULARCOLORMAP
          uniform sampler2D specularColorMap;
      #endif
  #endif
  #ifdef USE_CLEARCOAT
      uniform float clearcoat;
      uniform float clearcoatRoughness;
  #endif
  #ifdef USE_IRIDESCENCE
      uniform float iridescence;
      uniform float iridescenceIOR;
      uniform float iridescenceThicknessMinimum;
      uniform float iridescenceThicknessMaximum;
  #endif
  #ifdef USE_SHEEN
      uniform vec3 sheenColor;
      uniform float sheenRoughness;
      #ifdef USE_SHEENCOLORMAP
          uniform sampler2D sheenColorMap;
      #endif
      #ifdef USE_SHEENROUGHNESSMAP
          uniform sampler2D sheenRoughnessMap;
      #endif
  #endif
  varying vec3 vViewPosition;
  #define PI 3.141592653589793
  #define PI2 6.283185307179586
  #define PI_HALF 1.5707963267948966
  #define RECIPROCAL_PI 0.3183098861837907
  #define RECIPROCAL_PI2 0.15915494309189535
  #define EPSILON 1e-6
  #ifndef saturate
      #define saturate( a ) clamp( a, 0.0, 1.0 )
  #endif
  #define whiteComplement( a ) ( 1.0 - saturate( a ) )
  float pow2( const in float x ) {
      return x*x;
  }
  vec3 pow2( const in vec3 x ) {
      return x*x;
  }
  float pow3( const in float x ) {
      return x*x*x;
  }
  float pow4( const in float x ) {
      float x2 = x*x;
      return x2*x2;
  }
  float max3( const in vec3 v ) {
      return max( max( v.x, v.y ), v.z );
  }
  float average( const in vec3 v ) {
      return dot( v, vec3( 0.3333333 ) );
  }
  highp float rand( const in vec2 uv ) {
      const highp float a = 12.9898, b = 78.233, c = 43758.5453;
      highp float dt = dot( uv.xy, vec2( a, b ) ), sn = mod( dt, PI );
      return fract( sin( sn ) * c );
  }
  #ifdef HIGH_PRECISION
      float precisionSafeLength( vec3 v ) {
          return length( v );
      }
  #else
      float precisionSafeLength( vec3 v ) {
          float maxComponent = max3( abs( v ) );
          return length( v / maxComponent ) * maxComponent;
      }
  #endif
  struct IncidentLight {
      vec3 color;
      vec3 direction;
      bool visible;
  };
  struct ReflectedLight {
      vec3 directDiffuse;
      vec3 directSpecular;
      vec3 indirectDiffuse;
      vec3 indirectSpecular;
  };
  struct GeometricContext {
      vec3 position;
      vec3 normal;
      vec3 viewDir;
      #ifdef USE_CLEARCOAT
          vec3 clearcoatNormal;
      #endif
  };
  vec3 transformDirection( in vec3 dir, in mat4 matrix ) {
      return normalize( ( matrix * vec4( dir, 0.0 ) ).xyz );
  }
  vec3 inverseTransformDirection( in vec3 dir, in mat4 matrix ) {
      return normalize( ( vec4( dir, 0.0 ) * matrix ).xyz );
  }
  mat3 transposeMat3( const in mat3 m ) {
      mat3 tmp;
      tmp[ 0 ] = vec3( m[ 0 ].x, m[ 1 ].x, m[ 2 ].x );
      tmp[ 1 ] = vec3( m[ 0 ].y, m[ 1 ].y, m[ 2 ].y );
      tmp[ 2 ] = vec3( m[ 0 ].z, m[ 1 ].z, m[ 2 ].z );
      return tmp;
  }
  float luminance( const in vec3 rgb ) {
      const vec3 weights = vec3( 0.2126729, 0.7151522, 0.0721750 );
      return dot( weights, rgb );
  }
  bool isPerspectiveMatrix( mat4 m ) {
      return m[ 2 ][ 3 ] == - 1.0;
  }
  vec2 equirectUv( in vec3 dir ) {
      float u = atan( dir.z, dir.x ) * RECIPROCAL_PI2 + 0.5;
      float v = asin( clamp( dir.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
      return vec2( u, v );
  }
  float w0( float a ) {
      return ( 1.0 / 6.0 ) * ( a * ( a * ( - a + 3.0 ) - 3.0 ) + 1.0 );
  }
  float w1( float a ) {
      return ( 1.0 / 6.0 ) * ( a *  a * ( 3.0 * a - 6.0 ) + 4.0 );
  }
  float w2( float a ) {
      return ( 1.0 / 6.0 ) * ( a * ( a * ( - 3.0 * a + 3.0 ) + 3.0 ) + 1.0 );
  }
  float w3( float a ) {
      return ( 1.0 / 6.0 ) * ( a * a * a );
  }
  float g0( float a ) {
      return w0( a ) + w1( a );
  }
  float g1( float a ) {
      return w2( a ) + w3( a );
  }
  float h0( float a ) {
      return - 1.0 + w1( a ) / ( w0( a ) + w1( a ) );
  }
  float h1( float a ) {
      return 1.0 + w3( a ) / ( w2( a ) + w3( a ) );
  }
  vec4 bicubic( sampler2D tex, vec2 uv, vec4 texelSize, vec2 fullSize, float lod ) {
      uv = uv * texelSize.zw + 0.5;
      vec2 iuv = floor( uv );
      vec2 fuv = fract( uv );
      float g0x = g0( fuv.x );
      float g1x = g1( fuv.x );
      float h0x = h0( fuv.x );
      float h1x = h1( fuv.x );
      float h0y = h0( fuv.y );
      float h1y = h1( fuv.y );
      vec2 p0 = ( vec2( iuv.x + h0x, iuv.y + h0y ) - 0.5 ) * texelSize.xy;
      vec2 p1 = ( vec2( iuv.x + h1x, iuv.y + h0y ) - 0.5 ) * texelSize.xy;
      vec2 p2 = ( vec2( iuv.x + h0x, iuv.y + h1y ) - 0.5 ) * texelSize.xy;
      vec2 p3 = ( vec2( iuv.x + h1x, iuv.y + h1y ) - 0.5 ) * texelSize.xy;
      vec2 lodFudge = pow( 1.95, lod ) / fullSize;
      return g0( fuv.y ) * ( g0x * textureLod( tex, p0, lod ) + g1x * textureLod( tex, p1, lod ) ) +
      g1( fuv.y ) * ( g0x * textureLod( tex, p2, lod ) + g1x * textureLod( tex, p3, lod ) );
  }
  vec4 textureBicubic( sampler2D sampler, vec2 uv, float lod ) {
      vec2 fLodSize = vec2( textureSize( sampler, int( lod ) ) );
      vec2 cLodSize = vec2( textureSize( sampler, int( lod + 1.0 ) ) );
      vec2 fLodSizeInv = 1.0 / fLodSize;
      vec2 cLodSizeInv = 1.0 / cLodSize;
      vec2 fullSize = vec2( textureSize( sampler, 0 ) );
      vec4 fSample = bicubic( sampler, uv, vec4( fLodSizeInv, fLodSize ), fullSize, floor( lod ) );
      vec4 cSample = bicubic( sampler, uv, vec4( cLodSizeInv, cLodSize ), fullSize, ceil( lod ) );
      return mix( fSample, cSample, fract( lod ) );
  }
  #if ( defined( USE_UV ) && ! defined( UVS_VERTEX_ONLY ) )
      varying vec2 vUv;
  #endif
  #ifdef USE_MAP
      uniform sampler2D map;
  #endif
  #ifdef USE_LIGHTMAP
      uniform sampler2D lightMap;
      uniform float lightMapIntensity;
  #endif
  vec3 BRDF_Lambert( const in vec3 diffuseColor ) {
      return RECIPROCAL_PI * diffuseColor;
  }
  vec3 F_Schlick( const in vec3 f0, const in float f90, const in float dotVH ) {
      float fresnel = exp2( ( - 5.55473 * dotVH - 6.98316 ) * dotVH );
      return f0 * ( 1.0 - fresnel ) + ( f90 * fresnel );
  }
  float F_Schlick( const in float f0, const in float f90, const in float dotVH ) {
      float fresnel = exp2( ( - 5.55473 * dotVH - 6.98316 ) * dotVH );
      return f0 * ( 1.0 - fresnel ) + ( f90 * fresnel );
  }
  vec3 Schlick_to_F0( const in vec3 f, const in float f90, const in float dotVH ) {
      float x = clamp( 1.0 - dotVH, 0.0, 1.0 );
      float x2 = x * x;
      float x5 = clamp( x * x2 * x2, 0.0, 0.9999 );
      return ( f - vec3( f90 ) * x5 ) / ( 1.0 - x5 );
  }
  float V_GGX_SmithCorrelated( const in float alpha, const in float dotNL, const in float dotNV ) {
      float a2 = pow2( alpha );
      float gv = dotNL * sqrt( a2 + ( 1.0 - a2 ) * pow2( dotNV ) );
      float gl = dotNV * sqrt( a2 + ( 1.0 - a2 ) * pow2( dotNL ) );
      return 0.5 / max( gv + gl, EPSILON );
  }
  float D_GGX( const in float alpha, const in float dotNH ) {
      float a2 = pow2( alpha );
      float denom = pow2( dotNH ) * ( a2 - 1.0 ) + 1.0;
      return RECIPROCAL_PI * a2 / pow2( denom );
  }
  vec3 BRDF_GGX( const in vec3 lightDir, const in vec3 viewDir, const in vec3 normal, const in vec3 f0, const in float f90, const in float roughness ) {
      float alpha = pow2( roughness );
      vec3 halfDir = normalize( lightDir + viewDir );
      float dotNL = saturate( dot( normal, lightDir ) );
      float dotNV = saturate( dot( normal, viewDir ) );
      float dotNH = saturate( dot( normal, halfDir ) );
      float dotVH = saturate( dot( viewDir, halfDir ) );
      vec3 F = F_Schlick( f0, f90, dotVH );
      float V = V_GGX_SmithCorrelated( alpha, dotNL, dotNV );
      float D = D_GGX( alpha, dotNH );
      return F * ( V * D );
  }
  #ifdef USE_IRIDESCENCE
      vec3 BRDF_GGX_Iridescence( const in vec3 lightDir, const in vec3 viewDir, const in vec3 normal, const in vec3 f0, const in float f90, const in float iridescence, const in vec3 iridescenceFresnel, const in float roughness ) {
          float alpha = pow2( roughness );
          vec3 halfDir = normalize( lightDir + viewDir );
          float dotNL = saturate( dot( normal, lightDir ) );
          float dotNV = saturate( dot( normal, viewDir ) );
          float dotNH = saturate( dot( normal, halfDir ) );
          float dotVH = saturate( dot( viewDir, halfDir ) );
          vec3 F = mix( F_Schlick( f0, f90, dotVH ), iridescenceFresnel, iridescence );
          float V = V_GGX_SmithCorrelated( alpha, dotNL, dotNV );
          float D = D_GGX( alpha, dotNH );
          return F * ( V * D );
      }
  #endif
  vec2 LTC_Uv( const in vec3 N, const in vec3 V, const in float roughness ) {
      const float LUT_SIZE = 64.0;
      const float LUT_SCALE = ( LUT_SIZE - 1.0 ) / LUT_SIZE;
      const float LUT_BIAS = 0.5 / LUT_SIZE;
      float dotNV = saturate( dot( N, V ) );
      vec2 uv = vec2( roughness, sqrt( 1.0 - dotNV ) );
      uv = uv * LUT_SCALE + LUT_BIAS;
      return uv;
  }
  float LTC_ClippedSphereFormFactor( const in vec3 f ) {
      float l = length( f );
      return max( ( l * l + f.z ) / ( l + 1.0 ), 0.0 );
  }
  vec3 LTC_EdgeVectorFormFactor( const in vec3 v1, const in vec3 v2 ) {
      float x = dot( v1, v2 );
      float y = abs( x );
      float a = 0.8543985 + ( 0.4965155 + 0.0145206 * y ) * y;
      float b = 3.4175940 + ( 4.1616724 + y ) * y;
      float v = a / b;
      float theta_sintheta = ( x > 0.0 ) ? v : 0.5 * inversesqrt( max( 1.0 - x * x, 1e-7 ) ) - v;
      return cross( v1, v2 ) * theta_sintheta;
  }
  vec3 LTC_Evaluate( const in vec3 N, const in vec3 V, const in vec3 P, const in mat3 mInv, const in vec3 rectCoords[ 4 ] ) {
      vec3 v1 = rectCoords[ 1 ] - rectCoords[ 0 ];
      vec3 v2 = rectCoords[ 3 ] - rectCoords[ 0 ];
      vec3 lightNormal = cross( v1, v2 );
      if( dot( lightNormal, P - rectCoords[ 0 ] ) < 0.0 ) return vec3( 0.0 );
      vec3 T1, T2;
      T1 = normalize( V - N * dot( V, N ) );
      T2 = - cross( N, T1 );
      mat3 mat = mInv * transposeMat3( mat3( T1, T2, N ) );
      vec3 coords[ 4 ];
      coords[ 0 ] = mat * ( rectCoords[ 0 ] - P );
      coords[ 1 ] = mat * ( rectCoords[ 1 ] - P );
      coords[ 2 ] = mat * ( rectCoords[ 2 ] - P );
      coords[ 3 ] = mat * ( rectCoords[ 3 ] - P );
      coords[ 0 ] = normalize( coords[ 0 ] );
      coords[ 1 ] = normalize( coords[ 1 ] );
      coords[ 2 ] = normalize( coords[ 2 ] );
      coords[ 3 ] = normalize( coords[ 3 ] );
      vec3 vectorFormFactor = vec3( 0.0 );
      vectorFormFactor += LTC_EdgeVectorFormFactor( coords[ 0 ], coords[ 1 ] );
      vectorFormFactor += LTC_EdgeVectorFormFactor( coords[ 1 ], coords[ 2 ] );
      vectorFormFactor += LTC_EdgeVectorFormFactor( coords[ 2 ], coords[ 3 ] );
      vectorFormFactor += LTC_EdgeVectorFormFactor( coords[ 3 ], coords[ 0 ] );
      float result = LTC_ClippedSphereFormFactor( vectorFormFactor );
      return vec3( result );
  }
  float G_BlinnPhong_Implicit( ) {
      return 0.25;
  }
  float D_BlinnPhong( const in float shininess, const in float dotNH ) {
      return RECIPROCAL_PI * ( shininess * 0.5 + 1.0 ) * pow( dotNH, shininess );
  }
  vec3 BRDF_BlinnPhong( const in vec3 lightDir, const in vec3 viewDir, const in vec3 normal, const in vec3 specularColor, const in float shininess ) {
      vec3 halfDir = normalize( lightDir + viewDir );
      float dotNH = saturate( dot( normal, halfDir ) );
      float dotVH = saturate( dot( viewDir, halfDir ) );
      vec3 F = F_Schlick( specularColor, 1.0, dotVH );
      float G = G_BlinnPhong_Implicit( );
      float D = D_BlinnPhong( shininess, dotNH );
      return F * ( G * D );
  }
  #if defined( USE_SHEEN )
      float D_Charlie( float roughness, float dotNH ) {
          float alpha = pow2( roughness );
          float invAlpha = 1.0 / alpha;
          float cos2h = dotNH * dotNH;
          float sin2h = max( 1.0 - cos2h, 0.0078125 );
          return ( 2.0 + invAlpha ) * pow( sin2h, invAlpha * 0.5 ) / ( 2.0 * PI );
      }
      float V_Neubelt( float dotNV, float dotNL ) {
          return saturate( 1.0 / ( 4.0 * ( dotNL + dotNV - dotNL * dotNV ) ) );
      }
      vec3 BRDF_Sheen( const in vec3 lightDir, const in vec3 viewDir, const in vec3 normal, vec3 sheenColor, const in float sheenRoughness ) {
          vec3 halfDir = normalize( lightDir + viewDir );
          float dotNL = saturate( dot( normal, lightDir ) );
          float dotNV = saturate( dot( normal, viewDir ) );
          float dotNH = saturate( dot( normal, halfDir ) );
          float D = D_Charlie( sheenRoughness, dotNH );
          float V = V_Neubelt( dotNV, dotNL );
          return sheenColor * ( D * V );
      }
  #endif
  uniform bool receiveShadow;
  uniform vec3 ambientLightColor;
  uniform vec3 lightProbe[ 9 ];
  vec3 shGetIrradianceAt( in vec3 normal, in vec3 shCoefficients[ 9 ] ) {
      float x = normal.x, y = normal.y, z = normal.z;
      vec3 result = shCoefficients[ 0 ] * 0.886227;
      result += shCoefficients[ 1 ] * 2.0 * 0.511664 * y;
      result += shCoefficients[ 2 ] * 2.0 * 0.511664 * z;
      result += shCoefficients[ 3 ] * 2.0 * 0.511664 * x;
      result += shCoefficients[ 4 ] * 2.0 * 0.429043 * x * y;
      result += shCoefficients[ 5 ] * 2.0 * 0.429043 * y * z;
      result += shCoefficients[ 6 ] * ( 0.743125 * z * z - 0.247708 );
      result += shCoefficients[ 7 ] * 2.0 * 0.429043 * x * z;
      result += shCoefficients[ 8 ] * 0.429043 * ( x * x - y * y );
      return result;
  }
  vec3 getLightProbeIrradiance( const in vec3 lightProbe[ 9 ], const in vec3 normal ) {
      vec3 worldNormal = inverseTransformDirection( normal, viewMatrix );
      vec3 irradiance = shGetIrradianceAt( worldNormal, lightProbe );
      return irradiance;
  }
  vec3 getAmbientLightIrradiance( const in vec3 ambientLightColor ) {
      vec3 irradiance = ambientLightColor;
      return irradiance;
  }
  float getDistanceAttenuation( const in float lightDistance, const in float cutoffDistance, const in float decayExponent ) {
      #if defined ( LEGACY_LIGHTS )
          if ( cutoffDistance > 0.0 && decayExponent > 0.0 ) {
              return pow( saturate( - lightDistance / cutoffDistance + 1.0 ), decayExponent );
          }
          return 1.0;
      #else
          float distanceFalloff = 1.0 / max( pow( lightDistance, decayExponent ), 0.01 );
          if ( cutoffDistance > 0.0 ) {
              distanceFalloff *= pow2( saturate( 1.0 - pow4( lightDistance / cutoffDistance ) ) );
          }
          return distanceFalloff;
      #endif
  }
  float getSpotAttenuation( const in float coneCosine, const in float penumbraCosine, const in float angleCosine ) {
      return smoothstep( coneCosine, penumbraCosine, angleCosine );
  }
  #if 1 > 0
      struct DirectionalLight {
          vec3 direction;
          vec3 color;
      };
      uniform DirectionalLight directionalLights[ 1 ];
      void getDirectionalLightInfo( const in DirectionalLight directionalLight, const in GeometricContext geometry, out IncidentLight light ) {
          light.color = directionalLight.color;
          light.direction = directionalLight.direction;
          light.visible = true;
      }
  #endif
  #if 0 > 0
      struct PointLight {
          vec3 position;
          vec3 color;
          float distance;
          float decay;
      };
      uniform PointLight pointLights[ 0 ];
      void getPointLightInfo( const in PointLight pointLight, const in GeometricContext geometry, out IncidentLight light ) {
          vec3 lVector = pointLight.position - geometry.position;
          light.direction = normalize( lVector );
          float lightDistance = length( lVector );
          light.color = pointLight.color;
          light.color *= getDistanceAttenuation( lightDistance, pointLight.distance, pointLight.decay );
          light.visible = ( light.color ! = vec3( 0.0 ) );
      }
  #endif
  #if 0 > 0
      struct SpotLight {
          vec3 position;
          vec3 direction;
          vec3 color;
          float distance;
          float decay;
          float coneCos;
          float penumbraCos;
      };
      uniform SpotLight spotLights[ 0 ];
      void getSpotLightInfo( const in SpotLight spotLight, const in GeometricContext geometry, out IncidentLight light ) {
          vec3 lVector = spotLight.position - geometry.position;
          light.direction = normalize( lVector );
          float angleCos = dot( light.direction, spotLight.direction );
          float spotAttenuation = getSpotAttenuation( spotLight.coneCos, spotLight.penumbraCos, angleCos );
          if ( spotAttenuation > 0.0 ) {
              float lightDistance = length( lVector );
              light.color = spotLight.color * spotAttenuation;
              light.color *= getDistanceAttenuation( lightDistance, spotLight.distance, spotLight.decay );
              light.visible = ( light.color ! = vec3( 0.0 ) );
          }
          else {
              light.color = vec3( 0.0 );
              light.visible = false;
          }
      
      }
  #endif
  #if 0 > 0
      struct RectAreaLight {
          vec3 color;
          vec3 position;
          vec3 halfWidth;
          vec3 halfHeight;
      };
      uniform sampler2D ltc_1;
      uniform sampler2D ltc_2;
      uniform RectAreaLight rectAreaLights[ 0 ];
  #endif
  #if 0 > 0
      struct HemisphereLight {
          vec3 direction;
          vec3 skyColor;
          vec3 groundColor;
      };
      uniform HemisphereLight hemisphereLights[ 0 ];
      vec3 getHemisphereLightIrradiance( const in HemisphereLight hemiLight, const in vec3 normal ) {
          float dotNL = dot( normal, hemiLight.direction );
          float hemiDiffuseWeight = 0.5 * dotNL + 0.5;
          vec3 irradiance = mix( hemiLight.groundColor, hemiLight.skyColor, hemiDiffuseWeight );
          return irradiance;
      }
  #endif
  #ifndef FLAT_SHADED
      varying vec3 vNormal;
      #ifdef USE_TANGENT
          varying vec3 vTangent;
          varying vec3 vBitangent;
      #endif
  #endif
  struct PhysicalMaterial {
      vec3 diffuseColor;
      float roughness;
      vec3 specularColor;
      float specularF90;
      #ifdef USE_CLEARCOAT
          float clearcoat;
          float clearcoatRoughness;
          vec3 clearcoatF0;
          float clearcoatF90;
      #endif
      #ifdef USE_IRIDESCENCE
          float iridescence;
          float iridescenceIOR;
          float iridescenceThickness;
          vec3 iridescenceFresnel;
          vec3 iridescenceF0;
      #endif
      #ifdef USE_SHEEN
          vec3 sheenColor;
          float sheenRoughness;
      #endif
      #ifdef IOR
          float ior;
      #endif
      #ifdef USE_TRANSMISSION
          float transmission;
          float transmissionAlpha;
          float thickness;
          float attenuationDistance;
          vec3 attenuationColor;
      #endif
  };
  vec3 clearcoatSpecular = vec3( 0.0 );
  vec3 sheenSpecular = vec3( 0.0 );
  float IBLSheenBRDF( const in vec3 normal, const in vec3 viewDir, const in float roughness ) {
      float dotNV = saturate( dot( normal, viewDir ) );
      float r2 = roughness * roughness;
      float a = roughness < 0.25 ? -339.2 * r2 + 161.4 * roughness - 25.9 : -8.48 * r2 + 14.3 * roughness - 9.95;
      float b = roughness < 0.25 ? 44.0 * r2 - 23.7 * roughness + 3.26 : 1.97 * r2 - 3.27 * roughness + 0.72;
      float DG = exp( a * dotNV + b ) + ( roughness < 0.25 ? 0.0 : 0.1 * ( roughness - 0.25 ) );
      return saturate( DG * RECIPROCAL_PI );
  }
  vec2 DFGApprox( const in vec3 normal, const in vec3 viewDir, const in float roughness ) {
      float dotNV = saturate( dot( normal, viewDir ) );
      const vec4 c0 = vec4( - 1, - 0.0275, - 0.572, 0.022 );
      const vec4 c1 = vec4( 1, 0.0425, 1.04, - 0.04 );
      vec4 r = roughness * c0 + c1;
      float a004 = min( r.x * r.x, exp2( - 9.28 * dotNV ) ) * r.x + r.y;
      vec2 fab = vec2( - 1.04, 1.04 ) * a004 + r.zw;
      return fab;
  }
  vec3 EnvironmentBRDF( const in vec3 normal, const in vec3 viewDir, const in vec3 specularColor, const in float specularF90, const in float roughness ) {
      vec2 fab = DFGApprox( normal, viewDir, roughness );
      return specularColor * fab.x + specularF90 * fab.y;
  }
  #ifdef USE_IRIDESCENCE
      void computeMultiscatteringIridescence( const in vec3 normal, const in vec3 viewDir, const in vec3 specularColor, const in float specularF90, const in float iridescence, const in vec3 iridescenceF0, const in float roughness, inout vec3 singleScatter, inout vec3 multiScatter ) {
  #else
      void computeMultiscattering( const in vec3 normal, const in vec3 viewDir, const in vec3 specularColor, const in float specularF90, const in float roughness, inout vec3 singleScatter, inout vec3 multiScatter ) {
      #endif
      vec2 fab = DFGApprox( normal, viewDir, roughness );
      #ifdef USE_IRIDESCENCE
          vec3 Fr = mix( specularColor, iridescenceF0, iridescence );
      #else
          vec3 Fr = specularColor;
      #endif
      vec3 FssEss = Fr * fab.x + specularF90 * fab.y;
      float Ess = fab.x + fab.y;
      float Ems = 1.0 - Ess;
      vec3 Favg = Fr + ( 1.0 - Fr ) * 0.047619;
      vec3 Fms = FssEss * Favg / ( 1.0 - Ems * Favg );
      singleScatter += FssEss;
      multiScatter += Fms * Ems;
  }
  #if 0 > 0
      void RE_Direct_RectArea_Physical( const in RectAreaLight rectAreaLight, const in GeometricContext geometry, const in PhysicalMaterial material, inout ReflectedLight reflectedLight ) {
          vec3 normal = geometry.normal;
          vec3 viewDir = geometry.viewDir;
          vec3 position = geometry.position;
          vec3 lightPos = rectAreaLight.position;
          vec3 halfWidth = rectAreaLight.halfWidth;
          vec3 halfHeight = rectAreaLight.halfHeight;
          vec3 lightColor = rectAreaLight.color;
          float roughness = material.roughness;
          vec3 rectCoords[ 4 ];
          rectCoords[ 0 ] = lightPos + halfWidth - halfHeight;
          rectCoords[ 1 ] = lightPos - halfWidth - halfHeight;
          rectCoords[ 2 ] = lightPos - halfWidth + halfHeight;
          rectCoords[ 3 ] = lightPos + halfWidth + halfHeight;
          vec2 uv = LTC_Uv( normal, viewDir, roughness );
          vec4 t1 = texture2D( ltc_1, uv );
          vec4 t2 = texture2D( ltc_2, uv );
          mat3 mInv = mat3(
          vec3( t1.x, 0, t1.y ), vec3(    0, 1, 0 ), vec3( t1.z, 0, t1.w )
          );
          vec3 fresnel = ( material.specularColor * t2.x + ( vec3( 1.0 ) - material.specularColor ) * t2.y );
          reflectedLight.directSpecular += lightColor * fresnel * LTC_Evaluate( normal, viewDir, position, mInv, rectCoords );
          reflectedLight.directDiffuse += lightColor * material.diffuseColor * LTC_Evaluate( normal, viewDir, position, mat3( 1.0 ), rectCoords );
      }
  #endif
  void RE_Direct_Physical( const in IncidentLight directLight, const in GeometricContext geometry, const in PhysicalMaterial material, inout ReflectedLight reflectedLight ) {
      float dotNL = saturate( dot( geometry.normal, directLight.direction ) );
      vec3 irradiance = dotNL * directLight.color;
      #ifdef USE_CLEARCOAT
          float dotNLcc = saturate( dot( geometry.clearcoatNormal, directLight.direction ) );
          vec3 ccIrradiance = dotNLcc * directLight.color;
          clearcoatSpecular += ccIrradiance * BRDF_GGX( directLight.direction, geometry.viewDir, geometry.clearcoatNormal, material.clearcoatF0, material.clearcoatF90, material.clearcoatRoughness );
      #endif
      #ifdef USE_SHEEN
          sheenSpecular += irradiance * BRDF_Sheen( directLight.direction, geometry.viewDir, geometry.normal, material.sheenColor, material.sheenRoughness );
      #endif
      #ifdef USE_IRIDESCENCE
          reflectedLight.directSpecular += irradiance * BRDF_GGX_Iridescence( directLight.direction, geometry.viewDir, geometry.normal, material.specularColor, material.specularF90, material.iridescence, material.iridescenceFresnel, material.roughness );
      #else
          reflectedLight.directSpecular += irradiance * BRDF_GGX( directLight.direction, geometry.viewDir, geometry.normal, material.specularColor, material.specularF90, material.roughness );
      #endif
      reflectedLight.directDiffuse += irradiance * BRDF_Lambert( material.diffuseColor );
  }
  void RE_IndirectDiffuse_Physical( const in vec3 irradiance, const in GeometricContext geometry, const in PhysicalMaterial material, inout ReflectedLight reflectedLight ) {
      reflectedLight.indirectDiffuse += irradiance * BRDF_Lambert( material.diffuseColor );
  }
  void RE_IndirectSpecular_Physical( const in vec3 radiance, const in vec3 irradiance, const in vec3 clearcoatRadiance, const in GeometricContext geometry, const in PhysicalMaterial material, inout ReflectedLight reflectedLight) {
      #ifdef USE_CLEARCOAT
          clearcoatSpecular += clearcoatRadiance * EnvironmentBRDF( geometry.clearcoatNormal, geometry.viewDir, material.clearcoatF0, material.clearcoatF90, material.clearcoatRoughness );
      #endif
      #ifdef USE_SHEEN
          sheenSpecular += irradiance * material.sheenColor * IBLSheenBRDF( geometry.normal, geometry.viewDir, material.sheenRoughness );
      #endif
      vec3 singleScattering = vec3( 0.0 );
      vec3 multiScattering = vec3( 0.0 );
      vec3 cosineWeightedIrradiance = irradiance * RECIPROCAL_PI;
      #ifdef USE_IRIDESCENCE
          computeMultiscatteringIridescence( geometry.normal, geometry.viewDir, material.specularColor, material.specularF90, material.iridescence, material.iridescenceFresnel, material.roughness, singleScattering, multiScattering );
      #else
          computeMultiscattering( geometry.normal, geometry.viewDir, material.specularColor, material.specularF90, material.roughness, singleScattering, multiScattering );
      #endif
      vec3 totalScattering = singleScattering + multiScattering;
      vec3 diffuse = material.diffuseColor * ( 1.0 - max( max( totalScattering.r, totalScattering.g ), totalScattering.b ) );
      reflectedLight.indirectSpecular += radiance * singleScattering;
      reflectedLight.indirectSpecular += multiScattering * cosineWeightedIrradiance;
      reflectedLight.indirectDiffuse += diffuse * cosineWeightedIrradiance;
  }
  #define RE_Direct				RE_Direct_Physical
  #define RE_Direct_RectArea		RE_Direct_RectArea_Physical
  #define RE_IndirectDiffuse		RE_IndirectDiffuse_Physical
  #define RE_IndirectSpecular		RE_IndirectSpecular_Physical
  float computeSpecularOcclusion( const in float dotNV, const in float ambientOcclusion, const in float roughness ) {
      return saturate( pow( dotNV + ambientOcclusion, exp2( - 16.0 * roughness - 1.0 ) ) - 1.0 + ambientOcclusion );
  }
  #ifdef USE_NORMALMAP
      uniform sampler2D normalMap;
      uniform vec2 normalScale;
  #endif
  #ifdef OBJECTSPACE_NORMALMAP
      uniform mat3 normalMatrix;
  #endif
  #if ! defined ( USE_TANGENT ) && ( defined ( TANGENTSPACE_NORMALMAP ) || defined ( USE_CLEARCOAT_NORMALMAP ) )
      vec3 perturbNormal2Arb( vec3 eye_pos, vec3 surf_norm, vec3 mapN, float faceDirection ) {
          vec3 q0 = dFdx( eye_pos.xyz );
          vec3 q1 = dFdy( eye_pos.xyz );
          vec2 st0 = dFdx( vUv.st );
          vec2 st1 = dFdy( vUv.st );
          vec3 N = surf_norm;
          vec3 q1perp = cross( q1, N );
          vec3 q0perp = cross( N, q0 );
          vec3 T = q1perp * st0.x + q0perp * st1.x;
          vec3 B = q1perp * st0.y + q0perp * st1.y;
          float det = max( dot( T, T ), dot( B, B ) );
          float scale = ( det == 0.0 ) ? 0.0 : faceDirection * inversesqrt( det );
          return normalize( T * ( mapN.x * scale ) + B * ( mapN.y * scale ) + N * mapN.z );
      }
  #endif
  #ifdef USE_ROUGHNESSMAP
      uniform sampler2D roughnessMap;
  #endif
  #ifdef USE_METALNESSMAP
      uniform sampler2D metalnessMap;
  #endif
  #if defined( USE_LOGDEPTHBUF ) && defined( USE_LOGDEPTHBUF_EXT )
      uniform float logDepthBufFC;
      varying float vFragDepth;
      varying float vIsPerspective;
  #endif
  
  // ---------------------------------------------------------
  
  
  
  void main() {
      // ----------------------------------------------------------------------------
      
      vec4 diffuseColor = vec4( diffuse, opacity );
      ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
      vec3 totalEmissiveRadiance = emissive;
      #if defined( USE_LOGDEPTHBUF ) && defined( USE_LOGDEPTHBUF_EXT )
          gl_FragDepthEXT = vIsPerspective == 0.0 ? gl_FragCoord.z : log2( vFragDepth ) * logDepthBufFC * 0.5;
      #endif
      #ifdef USE_MAP
          vec4 sampledDiffuseColor = texture2D( map, vUv );
          #ifdef DECODE_VIDEO_TEXTURE
              sampledDiffuseColor = vec4( mix( pow( sampledDiffuseColor.rgb * 0.9478672986 + vec3( 0.0521327014 ), vec3( 2.4 ) ), sampledDiffuseColor.rgb * 0.0773993808, vec3( lessThanEqual( sampledDiffuseColor.rgb, vec3( 0.04045 ) ) ) ), sampledDiffuseColor.w );
          #endif
          diffuseColor *= sampledDiffuseColor;
      #endif
      float roughnessFactor = roughness;
      #ifdef USE_ROUGHNESSMAP
          vec4 texelRoughness = texture2D( roughnessMap, vUv );
          roughnessFactor *= texelRoughness.g;
      #endif
      float metalnessFactor = metalness;
      #ifdef USE_METALNESSMAP
          vec4 texelMetalness = texture2D( metalnessMap, vUv );
          metalnessFactor *= texelMetalness.b;
      #endif
      // ---------------------------------
      // #include <normal_fragment_begin>
      
      float faceDirection = gl_FrontFacing ? 1.0 : - 1.0;
      #ifdef FLAT_SHADED
          vec3 fdx = dFdx( vViewPosition );
          vec3 fdy = dFdy( vViewPosition );
          vec3 normal = normalize( cross( fdx, fdy ) );
      #else
          vec3 normal = normalize( vNormal );
          #ifdef DOUBLE_SIDED
              normal = normal * faceDirection;
          #endif
          #ifdef USE_TANGENT
              vec3 tangent = normalize( vTangent );
              vec3 bitangent = normalize( vBitangent );
              #ifdef DOUBLE_SIDED
                  tangent = tangent * faceDirection;
                  bitangent = bitangent * faceDirection;
              #endif
              #if defined( TANGENTSPACE_NORMALMAP ) || defined( USE_CLEARCOAT_NORMALMAP )
                  mat3 vTBN = mat3( tangent, bitangent, normal );
              #endif
          #endif
      #endif
      // non perturbed normal for clearcoat among others
      vec3 geometryNormal = normal;
      
      
      // ---------------------------------
      
      // ---------------------------------
      // ---------------------------------
      // ---------------------------------
      // ---------------------------------
      // ---------------------------------
      // ---------------------------------
      // ---------------------------------
      // ---------------------------------
      // ---------------------------------
      
      // #include <normal_fragment_maps>
      
      #ifdef OBJECTSPACE_NORMALMAP
          // normal = texture2D( normalMap, vUv ).xyz * 2.0 - 1.0; // overrides both flatShading and attribute normals
          #ifdef FLIP_SIDED
              normal = - normal;
          #endif
          #ifdef DOUBLE_SIDED
              normal = normal * faceDirection;
          #endif
          normal = normalize( normalMatrix * normal );
          #elif defined( TANGENTSPACE_NORMALMAP )
          
          
          
          
          
          
          // float n = 0.0;
          // for (float i = 1.0; i <= 5.0; i += 1.0) {
          //   n += texture2D(noise, fract(vp.xy/5.0*i*rot(i)+i*0.3)).r / i;
          // } n *= 0.5;
          
          float texN = 0.5*texture2D( normalMap, abs(1.0-abs(1.0-abs(vUv*2.0)))).r;
                texN += 0.5*texture2D( normalMap, abs(1.0-abs(1.0-abs(vUv*3.0*rot(3.14*0.33))))).r;
          // texN += 0.2*texture2D( normalMap, fract(vUv*6.0*rot(3.14*0.66)) ).r;
          
          
          vec3 mapN;
          mapN.g = texN * 2.0 - 1.0;
          
          //  mapN.r = 0.5*texture2D( normalMap, fract(vUv*rot((vUv.x+vUv.y)*3.33)*vec2(10.0, 1.0))).r;
          
          // vec3 mapN = texture2D( normalMap, fract(vp.xy) ).xyz * 2.0 - 1.0;
          // vec3 mapN = vec3( n * 2.0 - 1.0);
          
          
          mapN.r = 0.0;
          mapN.g *= 4.5;
          mapN.b = 1.0+3.0;          // ***
          // mapN.xy *= normalScale;
          
          #ifdef USE_TANGENT
              normal = normalize( vTBN * mapN );
          #else
              normal = perturbNormal2Arb( - vViewPosition, normal, mapN, faceDirection );
              normal *= 0.95;
          #endif
          #elif defined( USE_BUMPMAP )
          normal = perturbNormalArb( - vViewPosition, normal, dHdxy_fwd(), faceDirection );
      #endif
      
      // ----------------------------------
      
      //	#include <lights_physical_fragment>
      
      PhysicalMaterial material;
      // material.diffuseColor = diffuseColor.rgb * ( 1.0 - metalnessFactor );
      
      vec3 dxy = max( abs( dFdx( geometryNormal ) ), abs( dFdy( geometryNormal ) ) );
      float geometryRoughness = max( max( dxy.x, dxy.y ), dxy.z );
      material.roughness = max( roughnessFactor, 0.0525 );// 0.0525 corresponds to the base mip of a 256 cubemap.
      
      material.roughness += geometryRoughness;
      material.roughness = min( material.roughness, 1.0 );
      material.roughness = 0.5;  // <<<
      
      #ifdef IOR
          material.ior = ior;
          #ifdef SPECULAR
              float specularIntensityFactor = specularIntensity;
              vec3 specularColorFactor = specularColor;
              #ifdef USE_SPECULARINTENSITYMAP
                  // specularIntensityFactor *= texture2D( specularIntensityMap, vUv ).a;
              #endif
              #ifdef USE_SPECULARCOLORMAP
                  // specularColorFactor *= texture2D( specularColorMap, vUv ).rgb;
              #endif
              // material.specularF90 = mix( specularIntensityFactor, 1.0, metalnessFactor );
          #else
              float specularIntensityFactor = 1.0;
              vec3 specularColorFactor = vec3( 1.0 );
              // material.specularF90 = 1.0;
              
          #endif
          material.specularColor = mix( min( pow2( ( material.ior - 1.0 ) / ( material.ior + 1.0 ) ) * specularColorFactor, vec3( 1.0 ) ) * specularIntensityFactor, diffuseColor.rgb, metalnessFactor );
      #else
          material.specularColor = mix( vec3( 0.04 ), diffuseColor.rgb, metalnessFactor+0.5 ); // <<<
          
          // material.specularF90 = 1.0;
      #endif
      #ifdef USE_CLEARCOAT
          material.clearcoat = clearcoat;
          material.clearcoatRoughness = clearcoatRoughness;
          material.clearcoatF0 = vec3( 0.04 );
          material.clearcoatF90 = 1.0;
          #ifdef USE_CLEARCOATMAP
              material.clearcoat *= texture2D( clearcoatMap, vUv ).x;
          #endif
          #ifdef USE_CLEARCOAT_ROUGHNESSMAP
              material.clearcoatRoughness *= texture2D( clearcoatRoughnessMap, vUv ).y;
          #endif
          material.clearcoat = saturate( material.clearcoat ); // Burley clearcoat model
          
          material.clearcoatRoughness = max( material.clearcoatRoughness, 0.0525 );
          material.clearcoatRoughness += geometryRoughness;
          material.clearcoatRoughness = min( material.clearcoatRoughness, 1.0 );
      #endif
      #ifdef USE_IRIDESCENCE
          material.iridescence = iridescence;
          material.iridescenceIOR = iridescenceIOR;
          #ifdef USE_IRIDESCENCEMAP
              material.iridescence *= texture2D( iridescenceMap, vUv ).r;
          #endif
          #ifdef USE_IRIDESCENCE_THICKNESSMAP
              material.iridescenceThickness = (iridescenceThicknessMaximum - iridescenceThicknessMinimum) * texture2D( iridescenceThicknessMap, vUv ).g + iridescenceThicknessMinimum;
          #else
              material.iridescenceThickness = iridescenceThicknessMaximum;
          #endif
      #endif
      #ifdef USE_SHEEN
          material.sheenColor = sheenColor;
          #ifdef USE_SHEENCOLORMAP
              material.sheenColor *= texture2D( sheenColorMap, vUv ).rgb;
          #endif
          material.sheenRoughness = clamp( sheenRoughness, 0.07, 1.0 );
          #ifdef USE_SHEENROUGHNESSMAP
              material.sheenRoughness *= texture2D( sheenRoughnessMap, vUv ).a;
          #endif
      #endif
      
      //--------------------------------------
      // #include <lights_fragment_begin>
      
      GeometricContext geometry;
      geometry.position = - vViewPosition;
      geometry.normal = normal;
      geometry.viewDir = ( isOrthographic ) ? vec3( 0, 0, 1 ) : normalize( vViewPosition );
      #ifdef USE_CLEARCOAT
          geometry.clearcoatNormal = clearcoatNormal;
      #endif
      #ifdef USE_IRIDESCENCE
          float dotNVi = saturate( dot( normal, geometry.viewDir ) );
          if ( material.iridescenceThickness == 0.0 ) {
              material.iridescence = 0.0;
          }
          else {
              material.iridescence = saturate( material.iridescence );
          }
          if ( material.iridescence > 0.0 ) {
              material.iridescenceFresnel = evalIridescence( 1.0, material.iridescenceIOR, dotNVi, material.iridescenceThickness, material.specularColor );
              // Iridescence F0 approximation
              
              material.iridescenceF0 = Schlick_to_F0( material.iridescenceFresnel, 1.0, dotNVi );
          }
      #endif
      IncidentLight directLight;
      #if ( 0 > 0 ) && defined( RE_Direct )
          PointLight pointLight;
          #if defined( USE_SHADOWMAP ) && 0 > 0
              PointLightShadow pointLightShadow;
          #endif
          
      #endif
      #if ( 0 > 0 ) && defined( RE_Direct )
          SpotLight spotLight;
          vec4 spotColor;
          vec3 spotLightCoord;
          bool inSpotLightMap;
          #if defined( USE_SHADOWMAP ) && 0 > 0
              SpotLightShadow spotLightShadow;
          #endif
          
      #endif
      #if ( 1 > 0 ) && defined( RE_Direct )
          DirectionalLight directionalLight;
          #if defined( USE_SHADOWMAP ) && 0 > 0
              DirectionalLightShadow directionalLightShadow;
          #endif
          
          directionalLight = directionalLights[ 0 ];
          // DirectionalLight directionalLight;
          // directionalLight.direction = vec3(0.0,10.0,0.0);
          // directionalLight.color = vec3(20.0);
  
          getDirectionalLightInfo( directionalLight, geometry, directLight );
          #if defined( USE_SHADOWMAP ) && ( 0 < 0 )
              directionalLightShadow = directionalLightShadows[ 0 ];
              directLight.color *= ( directLight.visible && receiveShadow ) ? getShadow( directionalShadowMap[ 0 ], directionalLightShadow.shadowMapSize, directionalLightShadow.shadowBias, directionalLightShadow.shadowRadius, vDirectionalShadowCoord[ 0 ] ) : 1.0;
          #endif
          RE_Direct( directLight, geometry, material, reflectedLight );
      #endif
      #if ( 0 > 0 ) && defined( RE_Direct_RectArea )
          RectAreaLight rectAreaLight;
      #endif
      #if defined( RE_IndirectDiffuse )
          vec3 iblIrradiance = vec3( 0.0 );
          vec3 irradiance = getAmbientLightIrradiance( ambientLightColor );
          irradiance += getLightProbeIrradiance( lightProbe, geometry.normal );
          #if ( 0 > 0 )
              
          #endif
      #endif
      #if defined( RE_IndirectSpecular )
          vec3 radiance = vec3( 0.0 );
          vec3 clearcoatRadiance = vec3( 0.0 );
      #endif
      
      //--------------------------------
      
      vec3 totalDiffuse = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse;
      vec3 totalSpecular = reflectedLight.directSpecular + reflectedLight.indirectSpecular;
      #ifdef USE_TRANSMISSION
          material.transmission = transmission;
          material.transmissionAlpha = 1.0;
          material.thickness = thickness;
          material.attenuationDistance = attenuationDistance;
          material.attenuationColor = attenuationColor;
          #ifdef USE_TRANSMISSIONMAP
              material.transmission *= texture2D( transmissionMap, vUv ).r;
          #endif
          #ifdef USE_THICKNESSMAP
              material.thickness *= texture2D( thicknessMap, vUv ).g;
          #endif
          vec3 pos = vWorldPosition;
          vec3 v = normalize( cameraPosition - pos );
          vec3 n = inverseTransformDirection( normal, viewMatrix );
          vec4 transmission = getIBLVolumeRefraction(
          n, v, material.roughness, material.diffuseColor, material.specularColor, material.specularF90, pos, modelMatrix, viewMatrix, projectionMatrix, material.ior, material.thickness, material.attenuationColor, material.attenuationDistance );
          material.transmissionAlpha = mix( material.transmissionAlpha, transmission.a, material.transmission );
          totalDiffuse = mix( totalDiffuse, transmission.rgb, material.transmission );
      #endif


      vec3 outgoingLight = totalDiffuse  + totalSpecular + totalEmissiveRadiance;


      #ifdef USE_SHEEN
          float sheenEnergyComp = 1.0 - 0.157 * max3( material.sheenColor );
          outgoingLight = outgoingLight * sheenEnergyComp + sheenSpecular;
      #endif
      #ifdef USE_CLEARCOAT
          float dotNVcc = saturate( dot( geometry.clearcoatNormal, geometry.viewDir ) );
          vec3 Fcc = F_Schlick( material.clearcoatF0, material.clearcoatF90, dotNVcc );
          outgoingLight = outgoingLight * ( 1.0 - material.clearcoat * Fcc ) + clearcoatSpecular * material.clearcoat;
      #endif
      #ifdef OPAQUE
          diffuseColor.a = 1.0;
      #endif
      #ifdef USE_TRANSMISSION
          diffuseColor.a *= material.transmissionAlpha + 0.1;
      #endif
      gl_FragColor = vec4( outgoingLight, diffuseColor.a );
      #if defined( TONE_MAPPING )
          gl_FragColor.rgb = toneMapping( gl_FragColor.rgb );
      #endif
      gl_FragColor = linearToOutputTexel( gl_FragColor );
      #ifdef USE_FOG
          #ifdef FOG_EXP2
              float fogFactor = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );
          #else
              float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );
          #endif
          gl_FragColor.rgb = mix( gl_FragColor.rgb, fogColor, fogFactor );
      #endif
      #ifdef PREMULTIPLIED_ALPHA
          gl_FragColor.rgb *= gl_FragColor.a;
      #endif
      #ifdef DITHERING
          gl_FragColor.rgb = dithering( gl_FragColor.rgb );
      #endif
      
      // ----------------------------------------------------------------------------

      vec3  col = outgoingLight * 7.5 * 0.6 * 0.75 * 3.0; // ***
            texN = 0.5*texture2D( normalMap, fract(vp.yz*0.33)).r;
            texN += 0.5*texture2D( normalMap, fract(vp.xz*0.66*rot(3.14*0.33))).r;
      // vec3  sidecol1 = vec3(218.0, 202.0, 50.0)/255.0; // золотистый
      // vec3  sidecol2 = vec3(169.0, 155.0, 38.0)/255.0; // горчичный
      vec3  sidecol1 = vec3(85.0, 51.0, 3.0)/255.0 * (0.5 + texN*0.5); // коричневый
      vec3  sidecol2 = vec3(0.0, 0.0, 0.0)/255.0;                      // черный
      vec3  sidecol = mix(sidecol2, sidecol1, clamp(  (cos(vpc.x*0.3+texN*0.5)*0.4+0.6)  ,0.0,1.0) );
            if (abs(vn.b)<0.1) col = sidecol;

      float avg = dot(col,vec3(0.333));
            col = mix(col, vec3(209.0,209.0,181.0)/255.0*0.85, smoothstep(0.3,1.0,avg) );
            
      gl_FragColor = vec4(col, 1.0);



        }
  
      `;

    shader.uniforms.time = { value: 0 };
    shader.uniforms.noise = { value: 0 };
    shader.uniforms.mouse = { value: [0, 0] };
    mat.userData.shader = shader;

    shader.vertexShader = shaderVert;
    shader.fragmentShader = shaderFrag;
  };
}

const textureLoader = new THREE.TextureLoader();
const matcapTexture = textureLoader.load(perlin_noise);
const myMaterial = new THREE.MeshMatcapMaterial();
myMaterial.matcap = matcapTexture;
myMaterial.normalMap = matcapTexture;
materialPatchWater(myMaterial);

export function modifyObjectShader(object) {
  if (object.name.includes("Floor_0") && object.material) {
    object.material = object.material.clone();
    object.material.map = null;
    object.material.color = new THREE.Color(0x090908);
    object.material.normalMap = matcapTexture;
    object.material.normalScale && object.material.normalScale.set(0.1, 0.1);
    object.material.toneMapped = false;

    if (object.name !== "Floor_05") {
      materialPatchNoise2(object.material);
    } else {
      materialPatchNoise(object.material);
    }
    object.material.needsUpdate = true;
  }
  if (object.name === "water") {
    object.material = myMaterial;
  }
}

export function shaderTick() {
  const shader = myMaterial.userData.shader;
  if (shader) {
    shader.uniforms.time.value = performance.now() / 1000;
  }
}
