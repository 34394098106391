import { AnsterItem } from "../interfaces";

const data: Array<
  [
    //
    string,
    // Question
    string,
    // Propmps
    string,
    // answer
    boolean,
    // IDQuiz,
  ]
> = [
  [
    "Сезон 6 «Баттлы». Рестораны ",
    "Владелец малого бизнеса Алексей вдохновлялся баром Дмитрия Левицкого при открытии своего паба «Дублин»?",
    "Алексей действительно вдохновлялся баром Дмитрия Левицкого «Куклы пистолеты».",
    true
    // ,    1
  ],
  [
    "Сезон 6 «Баттлы». Рестораны",
    "Дежурная Оксана в баре Beer Yourself — это манекен в рыжем парике?",
    "«Дежурная Оксана» — это действительно манекен в баре,который достался на прокачку Дмитрию Зотову.",
    true
    // ,    2
  ],
  ["Сезон 6 «Баттлы». Рестораны",
    "Дмитрий Левицкий изменил название паба «Дублин» на «Жабы в гостях у лося»?",
    "Дмитрий Левицкий назвал паб «Жаба, лось и еще две жабы».",
    false
    // ,    3
  ],
  ["Сезон 6 «Баттлы». Рестораны",
    "Владелец малого бизнеса Геворг, чей бар Beer Yourself прокачивал Дмитрий Зотов, проявлял себя как душа компании, над его шутками смеялись все?",
    "Геворг оказался очень бережливым, он считал каждую копейку.",
    false
    // ,4
  ],
  ["Сезон 6 «Баттлы». Рестораны",
    "Дмитрий Зотов пришел оценивать работу своего соперника Дмитрия Левицкого вместе с бульдогом?",
    "Дмитрий Зотов действительно пришел в обновленный паб «Жаба, лось и еще две жабы» со своим бульдогом по кличке Фрэнк.",
    true
    // ,5
  ],
  ["Сезон 6 «Баттлы». Барбершопы",
    "Когда Дмитрий Андреев узнал, какой бизнес ему достался на прокачку, он был очень удивлен, поскольку в этом заведении работали сотрудники, которых Дмитрий недавно уволил у себя?",
    "Дмитрий действительно отправился прокачивать паб, в котором работали его бывшие сотрудники.",
    true
    // ,6
  ],
  ["Сезон 6 «Баттлы». Барбершопы",
    "Малый бизнес, который достался Антону Василенко, назывался «Барби и Барбершоп Империя», а слово «Барби» в нем отсылало к прекрасной основательнице заведения Дарье?",
    "Слово «Барби» в названии отсылало к наличию женского зала в заведении. ",
    false
    // ,7
  ],
  ["Сезон 6 «Баттлы». Барбершопы",
    "Чтобы взбодрить сотрудников барбершопа «Империя» в начале второго дня, Антон Василенко принес каждому кофе? ",
    "Чтобы взбодрить сотрудников барбершопа, Антон и его команда закрасили граффити в виде куклы на стене. ",
    false
    // ,8
  ],
  ["Сезон 6 «Баттлы». Барбершопы",
    "По мнению директора по обучению академии «Франт», женщина первым делом обращает внимание на цвет перехода, торчащие волоски и недобритость шеи, когда мужчина возвращается домой после стрижки?",
    "По мнению специалиста, женщина оценивает именно эти три параметра.",
    true
    // ,9
  ],
  ["Сезон 6 «Баттлы». Фитнес-клубы",
    "Фитнес-центр «Бомба», который достался на прокачку Александру Колмыкову, расположен в бомбоубежище?",
    "Фитнес-центр «Бомба» действительно работает в помещении действующего бомбоубежища.",
    true
    // ,10
  ],
  ["Сезон 6 «Баттлы». Фитнес-клубы",
    "Большой босс Николай Прянишников первым делом начал избавляться от хамоватых сотрудников в фитнес-центре «Джой»?",
    "Николай первым делом попросил избавиться от порванных перчаток, а конфликтов с сотрудниками у него не было.",
    false
    // ,11
  ],
  ["Сезон 6 «Баттлы». Фитнес-клубы",
    "Сотрудники фитнес-клуба «Бомба» хотели сдать на металлолом подарок от команды большого босса Александра Колмыкова?",
    "Сотрудники «Бомбы» действительно хотели сдать на металлолом аппарат для диагностики состава тела, который им подарила команда большого босса.",
    true
    // ,12
  ],
  ["Сезон 6 «Баттлы». Магазины одежды",
    "Антона Тюленева и Михаила Лабахуа объединяет любовь дарить клиентам подарки?",
    "Антона и Михаила объединяет то, что бренд «Юность» продавался в магазинах 21Shop.",
    false
    // ,13
  ],
  ["Сезон 6 «Баттлы». Магазины одежды",
    "Владелец бренда «Мурмуризм» Тимур решил провести вечеринку в честь выпуска новых свитеров в надежде продать хотя бы один из них?",
    "Тимур действительно решил провести вечеринку в качестве маркетингового хода, а деньги на ее организацию взял в долг у большого босса.",
    true
    // ,14
  ],
  ["Сезон 6 «Баттлы». Магазины одежды",
    "У команды большого босса Михаила Лабахуа произошел конфликт с продавцом Златой из малого бизнеса из-за того, что она не хотела стажироваться в магазине 21Shop?",
    "Злата отказывалась стажироваться, ее с трудом уговорили пойти на это.",
    true
    // ,15
  ],
  ["Сезон 6 «Баттлы». Магазины одежды",
    "Владелец малого бизнеса Тимур во время обучения в магазине «Юность» провалил задание по работе с покупателем, потому что не смог найти нужный размер?",
    "Тимур провалил задание потому, что настойчиво предлагал покупателю вещи на свой вкус, а выбор клиента оспаривал.",
    false
    // ,16
  ],
  ["Сезон 6 «Баттлы». Пиццерии",
    "Владелец пиццерии «Пульчинелла» по национальности был итальянцем?",
    "Иван действительно итальянец, ударение в его имени нужно делать на первый слог.",
    true
    // ,17
  ],
  ["Сезон 6 «Баттлы». Пиццерии",
    "Когда Федор Овчинников пришел в доставшуюся ему на прокачку пиццерию «Еж и Дюшес», его там встретила налоговая с проверкой?",
    "Федора встретил доставщик, который ел пиццу за барной стойкой.",
    false
    // ,18
  ],
  ["Сезон 6 «Баттлы». Пиццерии",
    "Жена владельца пиццерии «Еж и Дюшес» не хотела отказываться от названия заведения?",
    "Жена владельца пиццерии не хотела отказываться от роллов и суши в меню.",
    false
    // ,19
  ],
  ["Сезон 6 «Баттлы». Маникюрные салоны",
    "Владелица малого бизнеса Юля не хочет продвигать в своем салоне других мастеров, кроме себя, потому что мастера от нее уходят и уводят за собой клиентскую базу?",
    "Мастера действительно постоянно уходят от Юли и уводят клиентов, поэтому на стенах салона можно увидеть только ее сертификаты и дипломы.",
    true
    // ,20
  ],
  ["Сезон 6 «Баттлы». Маникюрные салоны",
    "Кристина Сержпинская предложила владелице студии «Перламутр» Татьяне в качестве первого шага к обновлению сменить свой образ?",
    "В качестве первого шага к обновлению Кристина предложила Татьяне оставить работу мастера навсегда. Над образом Татьяны большой босс действительно поработала, но позже.",
    false
    // ,21
  ],
  ["Сезон 6 «Баттлы». Маникюрные салоны",
    "В салоне, который прокачивала Кристина Сержпинская, впервые в истории сезона баттлов произошло чп — прорвало канализацию?",
    "ЧП действительно произошло, но только связано оно не с прорывом канализации, а с тем, что команда Кристины не успела закончить ремонт в срок.",
    false
    // ,22
  ],
  ["Сезон 6 «Баттлы». Маникюрные салоны",
    "На финале баттла маникюрных салонов произошла драка владельцев малого бизнеса?",
    "Татьяна и Юля вели себя вполне дружелюбно, но кое-что все же случилось: большой босс Кристина Сержпинская отказалась приходить на финальное голосование.",
    false
    // ,23
  ],
  ["Сезон 6 «Баттлы». Винодельни",
    "Большому боссу Евгению Емельянову предложили вино из винограда сорта «красностоп золотовский», как только он пришел на домашнюю винодельню «Дача Сердюка»?",
    "Евгению предложили вино именно из ростовского сорта винограда «красностоп золотовский».",
    true

    // ,24
  ],
  ["Сезон 6 «Баттлы». Винодельни",
    "Евгению Емельянову из «Шато Тамань» в оформлении вин «Дача Сердюка» не понравилось использование пивной пробки и не винной бутылки?",
    "Евгения смутило, что вина разливают в бутылку для портвейна и закрывают пивной пробкой.",
    true
    // ,25
  ],
  ["Сезон 6 «Баттлы». Винодельни",
    "По мнению владельца малого бизнеса Алексея Сердюка, рядом с цветущим виноградом нельзя сквернословить?",
    "По мнению Алексея, рядом с цветущим виноградом нельзя громко разговаривать.",
    false
    // ,26
  ],
  ["Сезон 6 «Баттлы». Винодельни",
    "Босс малого бизнеса Алексей Сердюк предложил разработать в «Шато Тамань» облегченную бутылку с краснокнижным животным на этикетке?",
    "Алексей предложил изготовить более легкую бутылку, чтобы сделать ее экологичнее, а на этикетке поместить изображения дельфина из Красной книги Краснодарского края в поддержку этого вида.",
    true
    // ,27
  ],
  ["Сезон 6 «Баттлы». Салоны красоты",
    "Владелица малого бизнеса Азиза пришла в салон «МильФей» с кнутом и пряниками в руках?",
    "Азиза действительно пришла с этими атрибутами в «МильФей», однако кнут использовать не пришлось, а вот чай с пряниками в конце пили все сотрудники.",
    true
    // ,28
  ],
  ["Сезон 6 «Баттлы». Салоны красоты",
    "Во время встречи Азизы и большого босса Елены Темиргалиевой в конце выпуска произошла ссора, из-за которой Азиза расплакалась?",
    "Азиза на этой встрече действительно плакала, но это были слезы счастья и благодарности большому боссу.",
    false
    // ,29
  ],
  ["Сезон 6 «Баттлы». Салоны красоты",
    "Владелица малого бизнеса Азиза не узнала известную модель Тайру Бэнкс на фотографиях в своем новом кабинете в офисе «МильФей»?",
    "Азиза не узнала на фотография другую известную модель — Наоми Кэмпбелл, которая присутствовала на открытии салона «МильФей» в Москва-Cити.",
    false
    // ,30
  ],
  ["Сезон 6 «Баттлы». Салоны красоты",
    "После неудачной попытки наладить контакт с сотрудниками салона «МильФей» Азизе помог справиться со стрессом бокал шампанского?",
    "Азизе помогла справиться со стрессом сессия со штатным психологом компании «МильФей».",
    false
    // ,31
  ],
  ["Сезон 6 «Теперь я босс! Своего дела». Шоколадный бизнес",
    "Сотрудники завода «Конти» решили запустить в производство новую конфету, которую придумал владелец малого бизнеса Илья на основе двух других конфет компании?",
    "Сотрудники действительно решили, что конфета достойна пойти в производство после доработки.",
    true
    // ,32
  ],
  ["Сезон 6 «Теперь я босс! Своего дела». Шоколадный бизнес",
    "Правда ли, что для продвижения продукции малого бизнеса большой босс Елена Симаненко устроила дегустацию шоколада «Межалини» в центре Костромы?",
    "Для продвижения продукции Елена отправила образцы шоколада «Межалини» в Китай на дегустацию фокус-группы местного дистрибьютора.",
    false
    // ,33
  ],
  ["Сезон 6 «Теперь я босс! Своего дела». Шоколадный бизнес",
    "Владелец малого бизнеса Илья смог удивить сотрудников «Конти» в лаборатории по созданию новых изделий тем, что показал, как вручную темперировать шоколад?",
    "Евгений действительно произвел своими навыками впечатление на сотрудников «Конти», так как он — профессиональный кондитер и умеет работать с шоколадом. ",
    true
    // ,34
  ],
  ["Сезон 6 «Теперь я босс! Под прикрытием». Лапшичные",
    "Правда ли, что, согласно прикрытию, большой босс Алексей Гисак раньше работал посудомойщиком? ",
    "Согласно легенде, Алексей раньше работал администратором тату-салона.",
    false
    // ,35
  ],
  ["Сезон 7 «Теперь я босс! Под прикрытием». Лапшичные",
    "Сотрудники лапшичной «Намотай» чуть не раскрыли прикрытие большого босса Алексея Гисака раньше времени?",
    "Сотрудники заметили, что у Алексея крашеная борода, боссу под прикрытием с трудом удалось придумать убедительную отговорку.",
    true
    // ,36
  ],
  ["Сезон 7 «Теперь я босс! Под прикрытием». Лапшичные",
    "Главная проблема в коллективе лапшичной «Намотай» — постоянные склоки и перекладывание ответственности друг на друга?",
    "Босс под прикрытием не только увидел, как сотрудники постоянно ругаются и ищут виноватых, но и прочувствовал это на себе.",
    true
    // ,37
  ],
  ["Сезон 7 «Теперь я босс! Под прикрытием». Отели",
    "Правда ли, что клетка в капсульном отеле 4U за зоной ресепшн нужна для собаки, которая там живет?",
    "Клетка в отеле нужна для хранения багажа, хотя администратор шутит, что она предназначена для посетителей, которые не слушаются.",
    false
    // ,38
  ],
  ["Сезон 7 «Теперь я босс! Под прикрытием». Отели",
    "В капсульном отеле 4U нет посудомоечной машины, поэтому гости моют посуду сами и не всегда хорошо?",
    "В отеле гости действительно моют посуду сами и не всегда тщательно, поэтому босс под прикрытием Юнис Теймурханлы нашел на кухне грязные столовые приборы.",
    true
    // ,39
  ],
  ["Сезон 7 «Теперь я босс! Под прикрытием». Отели",
    "Уборку помещений в гостиницах следует проводить при искусственном свете, чтобы были заметны все следы пребывания гостя?",
    "Убираться следует при дневном свете, именно так легче заметить все, от чего нужно избавиться.",
    false
    // ,40
  ],
  ["Сезон 7 «Теперь я босс! Своего дела». Мебель",
    "Отрезанный палец возле станка для распила стал самой шокирующей находкой для большого босса Максима Валецкого на производстве малого бизнеса?",
    "Максима шокировал газовый баллончик, стоящий рядом с огнетушителем, что является нарушением техники пожарной безопасности.",
    false
    // ,41
  ],
  ["Сезон 7 «Теперь я босс! Своего дела». Мебель",
    "Зарплата большого босса сильно впечатлила владельца малого бизнеса Сергея на производстве Mr.Doors?",
    "Сергея впечатлило количество заказов в месяц, которое выполняет Mr.Doors.",
    false
    
    // ,42
  ],
  ["Сезон 7 «Теперь я босс! Своего дела». Мебель",
    "Владелец малого бизнеса Сергей предложил использовать модный цвет Very Peri для фасадов на производстве Mr.Doors?",
    "Сергей действительно предложил использовать этот оттенок сиреневого.",
    true
    // ,43
  ],
  ["Сезон 7 «Теперь я босс! Своего дела». Клининг",
    "Когда большой босс Михаил Харламов пришел оценивать качество работы компании малого бизнеса, он первым делом отметил, что у сотрудников неправильно подобрана рабочая обувь?",
    "Михаил действительно обратил внимание на обувь сотрудниц, которая не соответствовала нормам.",
    true
    // ,44
  ],
  ["Сезон 7 «Теперь я босс! Своего дела». Клининг",
    "Сотрудники компании «Кит-Клининг» отмеряют нужное количество моющего средства при помощи мерного стакана?",
    "Сотрудники отмеряют нужное количество «бульками».",
    false
    // ,45
  ],
  ["Сезон 7 «Теперь я босс! Своего дела». Клининг",
    "Большой босс Михаил Харламов предложил компании «Кит-Клининг» продать старое оборудование и на вырученные деньги купить новое?",
    "Михаил предложил компании «Кит-Клининг» взять новое оборудование в лизинг.",
    false
    // ,46
  ],
  ["Сезон 7 «Теперь я босс! Своего дела». Клининг",
    "На осмотре аквапарка владелица малого бизнеса Екатерина и сотрудник «Сервис Престиж» заключили пари: кто лучше отмоет бортики бассейна. В случае победы Екатерина потребовала, чтобы на все ее вопросы сотрудник впредь отвечал: «Да, моя госпожа»? ",
    "В случае своей победы Екатерина хотела, чтобы сотрудник встал на колени и признал, что она — самый лучший босс.",
    false
    // ,47
  ],
  ["Сезон 7 «Теперь я босс! Под прикрытием». Салоны красоты",
    " Чтобы сделать большого босса Алексея Осипчука неузнаваемым, к его образу добавили бороду, шляпу, трость и монокль?",
    "Чтобы изменить облик Алексея, наши гримеры использовали очки, парик, цветные линзы и усы.",
    false
    // ,48
  ],
  ["Сезон 7 «Теперь я босс! Под прикрытием». Салоны красоты",
    "Босс под прикрытием Алексей Осипчук чуть не раскрыл легенду раньше времени, сказав свое настоящее имя вместо придуманного?",
    "Алексей действительно случайно назвал свое настоящее имя, хотя изначально пришел в салон под выдуманным, но ему удалось придумать отмазку.",
    true
    // ,49
  ],
  ["Сезон 7 «Теперь я босс! Под прикрытием». Салоны красоты",
    "Между администратором, мастерами и клиенткой в салоне красоты «Забава» начался конфликт из-за некачественно оказанной услуги?",
    "Конфликт разгорелся из-за того, что администратор записала клиентку не к тому мастеру.",
    false
    // ,50
  ],
  ["Сезон 7 «Теперь я босс! Под прикрытием». Салоны красоты",
    "Мастер Светлана из салона «Забава» не смогла сделать клиентке модную стрижку волфкат, и боссу под прикрытием пришлось взяться за ножницы самому?",
    "Стрижка, которую хотела клиентка, действительно называется волфкат, ее филигранно выполнил Алексей Осипчук после провала мастера из салона «Забава».",
    true
    // ,51
  ],
  ["Сезон 7 «Теперь я босс! Своего дела». Фастфуд",
    "На профессиональном сленге работников «Вкусно — и точка» слово «пенек» означает слишком короткую картошку фри?",
    "«Пеньком» работники «Вкусно — и точка» называют нижнюю часть булочки для бургера. ",
    false
    // ,52
  ],
  ["Сезон 7 «Теперь я босс! Своего дела». Фастфуд",
    "Босс из «Вкусно — и точка» заметил на котлете в вегетарианском бургере из заведения «Чепуха» мясные шкварки от грязного гриля?",
    "Мясные шкварки действительно попали на вегетарианскую котлету. Так происходит постоянно, потому что в этом заведении все готовят на одном гриле.",
    true
    // ,53
  ],
  ["Сезон 7 «Теперь я босс! Своего дела». Фастфуд",
    "Так называемая «черная мойка» в ресторане малого бизнеса «Чепуха» сделана из ведра?",
    "«Черная мойка» в заведении сделана из старой ванны.",
    false
    // ,54
  ],
  ["Сезон 7 «Теперь я босс! Своего дела». Фастфуд",
    "Александр обнаружил, что коктейль во «Вкусно — и точка» слишком густой и не тянется из трубочки?",
    "Александр действительно указал на эту проблему, а многие из наших зрителей даже согласились с ним в комментариях к выпуску.",
    true
    // ,55
  ],
  ["Сезон 7 «Теперь я босс! Своего дела». Кондитерские",
    "Большой босс Варвара Белецкая сделала один стандартный декор в виде медведя на всех изделиях малого бизнеса — «Кондитерская Миша»?",
    "Варвара предложила тематический декор: на медовик — пчелок, на морковный — морковки.",
    false
    // ,56
  ],
  ["Сезон 7 «Теперь я босс! Своего дела». Кондитерские",
    "Владелец малого бизнеса Михаил на фабрике «Север-Метрополь» напал на работника цеха, поэтому сотрудники предприятия вызвали охрану?",
    "Сотрудники вызвали охрану из-за того, что Михаил съел малину с пирожного.",
    false
    // ,57
  ],
  ["Сезон 7 «Теперь я босс! Своего дела». Кондитерские",
    "Владелец малого бизнеса Михаил решил поделиться с «Север-Метрополь» своим фирменным рецептом «Мишкин торт»?",
    "Михаил действительно поделился с большим бизнесом рецептом фирменного торта и показал все секреты приготовления.",
    true
    // ,58
  ],
  ["Категория «Общие вопросы»",
    "«Додо Пицца vs Pizza 22 см.» стал самым популярным выпуском проекта «Теперь я босс!» и набрал почти 4 млн просмотров?",
    "Самым популярным стал выпуск «Модная империя Lamoda. Как прокачать маленький дизайнерский шоурум?»",
    false
    // ,59
  ],
  ["Категория «Общие вопросы»",
    "Всего вышло 62 выпуска проекта «Теперь я босс!»?",
    "За семь сезонов мы действительно выпустили аж 62 выпуска! Смотри все выпуски www.sberbusiness.live!",
    true
    // ,60
  ],
  ["Категория «Общие вопросы»",
    "Новый формат шоу, премьера которого состоялась осенью 2022 года, называется «Теперь я босс! Под прикрытием».?",
    "Новый формат шоу действительно называется «Под прикрытием», он стартовал осенью 2022 года.",
    true
    // ,61
  ],
  ["Категория «Общие вопросы",
    "Чтобы узнать о дальнейшей судьбе малого бизнеса после участия в проекте «Теперь я босс!», нужно хорошенько изучить информацию в соцсетях?",
    "Вместо того, чтобы тратить время на поиски в интернете, мы предлагаем вам посмотреть рубрику «Бизнес после шоу» в сообществе «Теперь я босс!» ВКонтакте.",
    false
    // ,62
  ],
  ["Категория «Общие вопросы",
    "Статус России в мировом рейтинге легкости ведения бизнеса с 2011 года изменился в лучшую сторону?",
    "По последним данным, Россия находится на 28-й строчке рейтинга, а в 2011 году занимала лишь 124-е место из 190.",
    true
    // ,63
  ],
  ["Категория «Общие вопросы",
    "Не считая Москвы, Екатеринбург является лидером по условиям для развития малого и среднего бизнеса?",
    "Именно Екатеринбург занимает вторую строчку после Москвы по условиям для развития МСП.",
    true
    // ,64
  ],
  ["Категория «Общие вопросы",
    "В России около 3 млн компаний малого бизнеса?",
    "В России около 6 млн предприятий малого бизнеса. А более 3 млн из них обслуживают свой бизнес в Сбере.",
    false
    // ,65
  ],
  ["Категория «Общие вопросы",
    "В России доля женщин-предпринимателей — всего 17%?",
    "В России женщин-предпринимателей гораздо больше — 34,1%!",
    false
    // ,66
  ],
  ["Категория «Общие вопросы",
    "LTV или пожизненная ценность клиента — это затраты на поиск постоянного клиента?",
    "«Пеньком» работники «Вкусно — и точка» называют нижнюю часть булочки для бургера.",
    true
    // ,67
  ],
  // ["Категория «Общие вопросы",
  //   "Покупатель франшизы в среднем платит франчайзеру 6,5% роялти?",
  //   "Пожизненная ценность клиента — это прибыль, которую получает бизнес за все время работы с клиентом.",
  //   false
  // ],
  // ["Категория «Общие вопросы",
  //   "Аgile — это маркетинговая стратегия, направленная на привлечение максимально широкой аудитории?",
  //   "Это метод управления проектами, основанный на пошаговом выполнении задач.",
  //   false
  // ],
  // ["Категория «Общие вопросы",
  //   "Оставить онлайн-заявку на получение разных мер господдержки для малого и среднего бизнеса можно на сайте мсп.рф?",
  //   "На сайте мсп.рф доступны более 400 мер поддержки, для получения которых можно оставить онлайн-заявки.",
  //   true
  // ]
];

export const quizQuestions = data.reduce<Record<string, AnsterItem[]>>((r, n, index) => {
  const i = (index % 10) + 1;

  let arr = r[i];

  if (!arr) {
    arr = r[i] = [];
  }

  arr.push({
    category:n[0],
    question: n[1],
    prompt: n[2],
    answer: n[3],
    isZvuk: false
  });

  return r;
}, {});
