import React, { Component } from "react";
import PropTypes from "prop-types";
import { ensureAvatarMaterial, fetchAvatar } from "../utils/avatar-utils";
import { replaceHistoryState } from "../utils/history";
import { AvatarSettingsSidebar } from "./room/AvatarSettingsSidebar";
import { AvatarSetupModal } from "./room/AvatarSetupModal";
import AvatarPreview from "./avatar-preview";
import { AvatarEditorCustomIframe } from "../custom/avatar-editor/iframe";
import { fetchReticulumAuthenticated } from "../utils/phoenix-utils";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { upload } from "../utils/media-utils";

class GLTFBinarySplitterPlugin {
  constructor(parser) {
    this.parser = parser;
    this.gltf = null;
    this.bin = null;
  }

  beforeRoot() {
    const parser = this.parser;
    const { body } = parser.extensions.KHR_binary_glTF;
    const content = JSON.stringify(ensureAvatarMaterial(parser.json));

    this.gltf = new File([content], "file.gltf", {
      type: "model/gltf"
    });
    this.bin = new File([body], "file.bin", {
      type: "application/octet-stream"
    });

    // This plugin just wants to split gltf and bin from glb and
    // doesn't want to start the parse. But glTF loader plugin API
    // doesn't have an ability to cancel the parse. So overriding
    // parser.json with very light glTF data as workaround.
    parser.json = { asset: { version: "2.0" } };
  }

  afterRoot(result) {
    result.files = result.files || {};
    result.files.gltf = this.gltf;
    result.files.bin = this.bin;
  }
}

export default class ProfileEntryPanel extends Component {
  static propTypes = {
    containerType: PropTypes.oneOf(["sidebar", "modal"]),
    displayNameOverride: PropTypes.string,
    store: PropTypes.object,
    mediaSearchStore: PropTypes.object,
    messages: PropTypes.object,
    finished: PropTypes.func,
    history: PropTypes.object,
    avatarId: PropTypes.string,
    onClose: PropTypes.func,
    onBack: PropTypes.func,
    showBackButton: PropTypes.bool
  };

  static defaultProps = {
    containerType: "modal"
  };

  state = {
    avatarId: null,
    displayName: null,
    avatar: null,
    uploading: false,
    editor: false
  };

  constructor(props) {
    super(props);
    this.state = this.getStateFromProfile();
    if (props.avatarId) {
      this.state.avatarId = props.avatarId;
    }
    this.props.store.addEventListener("statechanged", this.storeUpdated);
    this.scene = document.querySelector("a-scene");

    setTimeout(() => {
      try {
        const avatarId = this.state.avatarId;
        if (avatarId !== undefined && avatarId !== null && avatarId.indexOf("http") !== -1) {
          const avatarList = ["XzDZTLN", "VXfVGrQ", "EpoWchx"];//"cUswb78", "KjV43EB", "5i36NSq", "xKdfgvJ", "fnwKznz", "a2EEScp", "WmJ7uxH", "LcLta7Z"];

          const randomElement = avatarList[Math.floor(Math.random() * avatarList.length)];

          this.setState({ avatarId: randomElement });
        }
      } catch (ex) {
        console.error(ex);
      }
    }, 1000);
  }

  getStateFromProfile = () => {
    const { displayName, avatarId } = this.props.store.state.profile;
    return { displayName, avatarId };
  };

  storeUpdated = () => this.setState(this.getStateFromProfile());

  /**
   * Сохранение аватарки
   */
  saveStateAndFinish = e => {
    e && e.preventDefault();

    const { displayName } = this.props.store.state.profile;
    const { hasChangedName } = this.props.store.state.activity;

    const hasChangedNowOrPreviously = hasChangedName || this.state.displayName !== displayName;
    this.props.store.update({
      activity: {
        hasChangedName: hasChangedNowOrPreviously,
        hasAcceptedProfile: true
      },
      profile: {
        displayName: this.state.displayName,
        avatarId: this.state.avatarId
      }
    });
    this.props.finished();
    this.scene.emit("avatar_updated");
  };

  stopPropagation = e => {
    e.stopPropagation();
  };

  setAvatarFromMediaResult = ({ detail: { entry, selectAction } }) => {
    if ((entry.type !== "avatar" && entry.type !== "avatar_listing") || selectAction !== "use") return;

    this.setState({ avatarId: entry.id });

    // Replace history state with the current avatar id since this component gets destroyed when we open the
    // avatar editor and we want the back button to work. We read the history state back via the avatarId prop.
    // We read the current state key from history since it could be "overlay" or "entry_step".
    replaceHistoryState(this.props.history, this.props.history.location.state.key, "profile", { avatarId: entry.id });
  };

  componentDidMount() {
    if (this.nameInput) {
      // stop propagation so that avatar doesn't move when wasd'ing during text input.
      this.nameInput.addEventListener("keydown", this.stopPropagation);
      this.nameInput.addEventListener("keypress", this.stopPropagation);
      this.nameInput.addEventListener("keyup", this.stopPropagation);
    }
    this.scene.addEventListener("action_selected_media_result_entry", this.setAvatarFromMediaResult);
    // This handles editing avatars in the entry_step, since this component remains mounted with the same avatarId
    this.scene.addEventListener("action_avatar_saved", this.refetchAvatar);

    this.refetchAvatar();
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.avatarId !== this.state.avatarId) {
      this.refetchAvatar();
    }
  }

  componentWillUnmount() {
    this.props.store.removeEventListener("statechanged", this.storeUpdated);
    if (this.nameInput) {
      this.nameInput.removeEventListener("keydown", this.stopPropagation);
      this.nameInput.removeEventListener("keypress", this.stopPropagation);
      this.nameInput.removeEventListener("keyup", this.stopPropagation);
    }
    this.scene.removeEventListener("action_selected_media_result_entry", this.setAvatarFromMediaResult);
    this.scene.removeEventListener("action_avatar_saved", this.refetchAvatar);
  }

  refetchAvatar = async () => {
    const avatar = await fetchAvatar(this.state.avatarId);
    if (this.state.avatarId !== avatar.avatar_id) return; // This is an old result, ignore it
    this.setState({ avatar });
  };

  createOrUpdateAvatar = avatar => {
    const AVATARS_API = "/api/v1/avatars";

    const instance = this;

    return fetchReticulumAuthenticated(
      avatar.avatar_id ? `${AVATARS_API}/${avatar.avatar_id}` : AVATARS_API,
      avatar.avatar_id ? "PUT" : "POST",
      { avatar }
    ).then(({ avatars = [] } = {}) => {
      const avatar1 = avatars[0];

      const uploadedAvatarId = avatar1?.avatar_id;

      if (!uploadedAvatarId) {
        console.error("Can not get new avatar");
        return;
      }

      instance.setState({ avatarId: uploadedAvatarId });
    });
  };

  async uploadAvatar(glb) {
    const gltfLoader = new GLTFLoader().register(parser => new GLTFBinarySplitterPlugin(parser));
    const gltfUrl = URL.createObjectURL(glb);
    const onProgress = console.log;

    const inputFiles = {};

    await new Promise((resolve, reject) => {
      // GLTFBinarySplitterPlugin saves gltf and bin in gltf.files
      gltfLoader.load(
        gltfUrl,
        result => {
          inputFiles.gltf = result.files.gltf;
          inputFiles.bin = result.files.bin;
          resolve(result);
        },
        onProgress,
        reject
      );
    });

    URL.revokeObjectURL(gltfUrl);

    inputFiles.thumbnail = new File([await this.preview.snapshot()], "thumbnail.png", {
      type: "image/png"
    });

    const filesToUpload = ["gltf", "bin", "base_map", "emissive_map", "normal_map", "orm_map", "thumbnail"].filter(
      k => inputFiles[k] === null || inputFiles[k] instanceof File
    );

    const fileUploads = await Promise.all(filesToUpload.map(f => inputFiles[f] && upload(inputFiles[f])));

    const baseAvatar = {
      name: "My Avatar",
      files: {}
    };

    const avatar = {
      ...baseAvatar,
      attributions: {
        creator: undefined
      },
      parent_avatar_listing_id: "",
      files: fileUploads
        .map((resp, i) => [filesToUpload[i], resp && [resp.file_id, resp.meta.access_token, resp.meta.promotion_token]])
        .reduce((o, [k, v]) => ({ ...o, [k]: v }), {})
    };

    await this.createOrUpdateAvatar(avatar);
  }

  onAvatarExported = glb => {
    this.closeAvatarEditor();
    this.setState({ uploading: true });
    /*
    const blobUrl = URL.createObjectURL(glb);
    console.log(blobUrl);

    //this.setState({ avatarId: blobUrl });

    const avatarObject = this.state.avatar;

    avatarObject.gltf_url = blobUrl;

    this.getStateFromProfile({avatar: avatarObject});
    */
    this.uploadAvatar(glb).then(() => {
      // console.log(avatar);

      this.setState({ uploading: false });
    });
  };

  closeAvatarEditor = () => {
    this.setState({ editor: false });
  };

  saveAvatarToProfile = () => {
    // console.log('Avatar saved');
  };

  render() {
    const avatarSettingsProps = {
      displayNameInputRef: inp => (this.nameInput = inp),
      disableDisplayNameInput: !!this.props.displayNameOverride,
      displayName: this.props.displayNameOverride ? this.props.displayNameOverride : this.state.displayName,
      displayNamePattern: this.props.store.schema.definitions.profile.properties.displayName.pattern,
      uploadingState: this.state.uploading,
      onChangeDisplayName: (e, badge = false) => {
        let val = "-";

        function stripHash(str) {
          if (str == undefined) {
            return str;
          }
          const pos = str.indexOf(" #");
          if (pos > -1) {
            return str.slice(0, pos);
          }
          return str;
        }

        if (badge) {
          val = stripHash(this.state.displayName) + " #" + e;
        } else {
          val = e.target.value;
        }
        this.setState({ displayName: val });
      },
      avatarPreview: (
        <AvatarPreview ref={p => (this.preview = p)} avatarGltfUrl={this.state.avatar && this.state.avatar.gltf_url} />
      ),
      onChangeAvatar: e => {
        e.preventDefault();
        this.props.mediaSearchStore.sourceNavigateWithNoNav("avatars", "use");
      },
      onEditAvatar: e => {
        e.preventDefault();
        //this.state.editor = true;
        this.setState({ editor: true });
        //this.props.mediaSearchStore.sourceNavigateWithNoNav("avatars", "use");
      },
      onSaveAvatar: e => {
        e.preventDefault();
        this.saveAvatarToProfile();
      },
      onSubmit: this.saveStateAndFinish,
      onClose: this.props.onClose,
      onBack: this.props.onBack
    };

    if (this.state.editor) {
      return <AvatarEditorCustomIframe onAvatarExported={this.onAvatarExported} onCancel={this.closeAvatarEditor} />;
    }

    if (this.props.containerType === "sidebar") {
      return <AvatarSettingsSidebar {...avatarSettingsProps} showBackButton={this.props.showBackButton} />;
    }

    return <AvatarSetupModal {...avatarSettingsProps} />;
  }
}
