import { degToRad } from "./spe-particles";
import sparkle from "../../assets/custom/textures/sparkle.png";

function randomNormal(min, max) {
  let rand = 0;
  for (let n = 0; n < 6; n++) rand += Math.random();
  return min + (max - min) * (rand / 6);
}

function randomUniform(min, max) {
  return min + (max - min) * Math.random();
}

AFRAME.registerComponent("firework-spawner", {
  init: function () {
    this.sceneEl = document.querySelector("a-scene");
  },

  tick: function (
    time,
    deltaTime // deltaTime: milliseconds
  ) {
    const deltaSeconds = deltaTime / 1000;
    const eventFrequency = 0.75;

    if (Math.random() < eventFrequency * deltaSeconds) {
      const angle = degToRad(270);
      const angleSpread = degToRad(90);
      const angleRadians = angle + randomNormal(-angleSpread, angleSpread);
      const radius = 20;
      const x = radius * Math.cos(angleRadians);
      const y = 15;
      const z = radius * Math.sin(angleRadians);

      const firework = document.createElement("a-entity");
      firework.setAttribute("firework", "");
      firework.setAttribute("position", { x: x, y: y, z: z });

      // random flight parameters
      const maxHeight = randomNormal(14, 18);
      firework.setAttribute("firework", "maxHeight", maxHeight);
      const riseTime = randomNormal(2, 3);
      firework.setAttribute("firework", "riseTime", riseTime);
      this.sceneEl.appendChild(firework);

      // chance of multi-firework
      // 0.10 = 1/10 double, 1/100 triple, 1/1000 quadruple, etc.
      // 0.20 =  1/5 double,  1/25 triple,  1/125 quadruple, etc.
      while (Math.random() < 0.25) {
        const fireworkExtra = document.createElement("a-entity");
        fireworkExtra.setAttribute("firework", "");
        // synchronize position, height, time to explosion
        fireworkExtra.setAttribute("position", { x: x, y: y, z: z });
        fireworkExtra.setAttribute("firework", "maxHeight", maxHeight);
        fireworkExtra.setAttribute("firework", "riseTime", riseTime);
        this.sceneEl.appendChild(fireworkExtra);
      }
    }
  }
});

AFRAME.registerComponent("firework", {
  schema: {
    color: { type: "string", default: "" },
    maxHeight: { type: "number", default: 4.5 },
    riseTime: { type: "number", default: 2.5 } // time to reach maxHeight
  },

  randomColor: function () {
    const colorList = ["red", "orange", "gold", "green", "cyan", "#0088FF", "violet"];
    const colorIndex = Math.floor(colorList.length * Math.random());
    return colorList[colorIndex];
  },

  init: function () {
    // set random color if none already set
    if (this.data.color == "") this.data.color = this.randomColor();
    this.particleBurst = document.createElement("a-entity");
    this.particleBurst.setAttribute("spe-particles", "");

    this.particleBurst.setAttribute("spe-particles", "texture", sparkle);
    this.particleBurst.setAttribute("spe-particles", "blending", "additive");
    this.particleBurst.setAttribute("spe-particles", "distribution", "sphere");
    this.particleBurst.setAttribute("spe-particles", "radius", 0.01);

    // particles both shrink and fade during last moments
    this.particleBurst.setAttribute("spe-particles", "opacity", [1000, 1000, 1000, 0]);
    // this.particleBurst.setAttribute("spe-particles", "size", [1, 1, 1, 0]);

    const particleCount = Math.floor(randomUniform(100, 999));
    this.particleBurst.setAttribute("spe-particles", "particleCount", particleCount);

    // change of particle burst vs. particle stream
    if (Math.random() < 0.8) this.particleBurst.setAttribute("spe-particles", "activeMultiplier", particleCount);
    else this.particleBurst.setAttribute("spe-particles", "activeMultiplier", particleCount / 128);

    // note: when distribution = 'sphere', only first component of velocity vectors are used
    const baseVelocity = randomUniform(1, 2);
    this.particleBurst.setAttribute("spe-particles", "velocity", { x: baseVelocity, y: 0, z: 0 });
    this.particleBurst.setAttribute("spe-particles", "velocitySpread", { x: 0.5, y: 0, z: 0 });
    this.particleBurst.setAttribute("spe-particles", "drag", 1.0);
    this.particleBurst.setAttribute("spe-particles", "randomizeVelocity", true);

    // add a bit of gravity
    this.particleBurst.setAttribute("spe-particles", "accelerationDistribution", "box");
    this.particleBurst.setAttribute("spe-particles", "acceleration", { x: 0, y: -0.2, z: 0 });
    this.particleBurst.setAttribute("spe-particles", "accelerationSpread", { x: 0, y: 0.2, z: 0 });

    // how long will particles last?
    this.burstDuration = randomUniform(1.0, 3.0);
    this.particleBurst.setAttribute("spe-particles", "maxAge", this.burstDuration);
    this.particleBurst.setAttribute("spe-particles", "maxAgeSpread", this.burstDuration / 4);
    // duration = maximum amount of time to emit particles;
    //        	  should be less than smallest max age to prevent second burst
    this.particleBurst.setAttribute("spe-particles", "duration", 0.5);

    // default: steady color
    let colorArray = ["white", this.data.color, this.data.color, this.data.color];
    // random chance of color shifting
    if (Math.random() < 0.25) {
      let color2 = this.data.color;
      // make sure second color is different from first
      while (color2 == this.data.color) {
        color2 = this.randomColor();
      }
      colorArray = ["white", this.data.color, color2, color2];
    }
    this.particleBurst.setAttribute("spe-particles", "color", colorArray);

    // disable burst effect for now;
    //   will be enabled (in tick method) after reaching max height
    this.particleBurst.setAttribute("spe-particles", "enabled", false);

    this.el.appendChild(this.particleBurst);

    this.elapsedTime = 0;
    this.burstStart = false;
  },

  tick: function (time, deltaTime) {
    this.elapsedTime += deltaTime / 1000;

    // firework has reached max height, start burst effect
    if (this.elapsedTime > this.data.riseTime && this.burstStart == false) {
      this.burstStart = true;
      this.particleBurst.setAttribute("spe-particles", "enabled", true);

    }

    // firework finished; remove self from scene
    //  need to take into account particle age variation (1.25 factor)
    if (this.elapsedTime > this.data.riseTime + this.burstDuration * 1.25) {
      const element = this.el;
      element.parentNode.removeChild(element);
    }
  }
});
