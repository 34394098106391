import { HoloVideoObjectThreeJS } from "../HoloMediaPlayer/HoloMediaPlayer/holomediaplayer";
import { IHoloVideoObjectThreeJS } from "../HoloMediaPlayer/HoloMediaPlayer/interfaces";
import { COLLIDABLE_MODELS } from "../collidable-model/constants";
import { HoloVideoObjectStates } from "../HoloMediaPlayer/HoloMediaPlayer/constants";
// import { HoloMediaMultiPlayer } from "../HoloMediaPlayer/HoloMediaMultiPlayer";
import { VolumetricComponentInstance } from "./interfaces";
import { stepByStepVolumetricPlayer } from "../HoloMediaPlayer/StepByStepVolumetricPlayer";

/**
 * Набор всех управляемых волюметриков.
 * Включая один, должны все другие отключиться.
 */
// export const triggableVolumetrics = new Map<VolumetricComponentInstance["el"]["eid"], VolumetricComponentInstance>();

/**
 * Регистрируем компонент для GLTF-загрузчика.
 * Это надо для обработки объектов, добавленных в сцену через Spoke.
 */
AFRAME.GLTFModelPlus.registerComponent("volumetric", "volumetric", (el, _componentName, componentData) => {
  el.setAttribute("volumetric", componentData);
});

const isEnoughMemory = () => {
  return false;
};

const isSafari = () => {
  let isSafari = false;

  const ua = navigator.userAgent.toLowerCase();

  if (ua.includes("safari") && !ua.includes("chrome")) {
    isSafari = true;
  }

  return isSafari;
};

AFRAME.registerComponent("volumetric", {
  schema: {
    src: { type: 'string' },
  },

  init(this: VolumetricComponentInstance) {},

  remove(this: VolumetricComponentInstance) {
    this.hvo?.close();
    // triggableVolumetrics.delete(this.el.eid);

    stepByStepVolumetricPlayer.removeComponent(this);
  },

  play(this: VolumetricComponentInstance) {
    /**
     * Проверяем есть ли тригер-зона у волюметрика (родительский компонент с нужным ивентом)
     */
    this.hasPlayTrigger =
      this.el.parentElement?.object3DMap?.mesh?.userData.eventId === COLLIDABLE_MODELS.volumetricPlay.eventId;

    const group = this.el.object3D;

    if (!group) {
      return;
    }

    // const eid: number = this.el.eid;

    const startBox = new THREE.Mesh(
      new THREE.BoxBufferGeometry(1, 3, 1),
      new THREE.MeshStandardMaterial({ transparent: true, opacity: this.hasPlayTrigger ? 0 : 0.1 })
    );

    /**
     * В Spoke пропорции модели часто задаются !== 1
     */
    const xScaleDiff = 1 / group.scale.x;
    const yScaleDiff = 1 / group.scale.y;
    const zScaleDiff = 1 / group.scale.z;

    startBox.scale.set(xScaleDiff, yScaleDiff, zScaleDiff);

    startBox.position.set(0, 1 * yScaleDiff, 0);

    group.add(startBox);

    const holoSrc = this.data.src;

    const hvo: IHoloVideoObjectThreeJS = new (HoloVideoObjectThreeJS as any)(
      // @ts-expect-error
      window.renderHolder,
      (mesh: typeof startBox) => {
        /**
         * В плейере задано 0.01
         */
        mesh.scale.set(1, 1, 1);
        group.add(mesh);

        /**
         * Здесь не нужен плей, так как он управляется передаваемым
         * параметром autoplay
         */
        // if (!hasPlayTrigger) {
        //   hvo.play();
        // }
      }
    );

    // const hvo2: IHoloVideoObjectThreeJS = new (HoloVideoObjectThreeJS as any)(
    //   // @ts-expect-error
    //   window.renderHolder,
    //   (mesh: typeof startBox) => {
    //     /**
    //      * В плейере задано 0.01
    //      */
    //     mesh.scale.set(1, 1, 1);
    //     // group.add(mesh);

    //     /**
    //      * Здесь не нужен плей, так как он управляется передаваемым
    //      * параметром autoplay
    //      */
    //     // if (!hasPlayTrigger) {
    //     //   hvo.play();
    //     // }
    //   }
    // );
    // hvo2.open("https://sber180-static.obs.ru-moscow-1.hc.sbercloud.ru/hcap/vlad/vlad.hcap", {
    //   keepAllMeshesInMemory: true,
    //   autoloop: true,
    //   autoplay: true
    // });

    hvo.setLogLevel(0);

    /**
     * Передаем плейер в компонент. Получить его можно через DOM
     * $0.components.volumetric.hvo
     */
    this.hvo = hvo;

    if (hvo.hvo) {
      // This check improves iOS webview compatibility
      hvo.hvo.isSafari = isSafari();

      if (!this.hasPlayTrigger) {
        hvo.open(holoSrc, {
          // keepAllMeshesInMemory: isEnoughMemory(),
          keepAllMeshesInMemory: true,
          autoloop: true,
          audioEnabled: true,
          // autoplay: !this.hasPlayTrigger
          autoplay: true
        });
      }

      // if(hasPlayTrigger) {
      //   hvo.pause();
      // }

      this.startBox = startBox;
    }

    // this.hvoMultiPlayer = new HoloMediaMultiPlayer({
    //   component: this,
    //   hvoPlayers: [hvo, hvo2]
    // });

    /**
     * Если есть триггер-компонент,
     * то добавляем волюметрик в очередь воспроизведения по событию
     */
    if (this.hasPlayTrigger) {
      stepByStepVolumetricPlayer.addComponent(this);
    }
  },

  tick(this: VolumetricComponentInstance) {
    const eid: number = this.el.eid;

    const player = this.hvo;

    if (player) {
      /**
       * Когда начинается плей
       */
      if (player.hvo?.curMesh !== null && this.startBox) {
        const group = APP.world.eid2obj.get(eid);

        /**
         * Удаляем стартбокс
         */
        group?.remove(this.startBox);
        delete this.startBox;
      }

      /**
       * Апдейт кадра плейера
       */
      if (player.hvo?.state === HoloVideoObjectStates.Playing) {
        player.update();
      }
    }
  }
});

export {};
