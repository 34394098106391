import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./assets/stylesheets/quiz-ui.scss";
// import { TOTAL_ANSWERS } from "../constants";
import { SberZvukBlock } from "./sberzvuk/Sberzvuk";
import { FirstQuiz } from "./first-quiz/FirstQuiz";
import { metrikaReachGoal } from "../utils/metrika";
import { MainViewportModal } from "../react-components/modals/MainViewportModal";
import { COLLIDABLE_MODELS } from "../collidable-model/constants";
import {
  CollisionEventDetail,
  onCollisionCollect,
  onCollisionCollectProps
} from "../Collect/helpers/onCollisionCollect";
import { useCollect } from "../Collect/hooks/useCollect";
// import { useInteractionAuth } from "../Collect/hooks/useInteractionAuth";
import { Question } from "./Question";
import { quizQuestions } from "./data/quizQuestions";
import { AnsterItem } from "./interfaces";
import { MyVotes, vote } from "../boss-voting";
import { isAuthed } from "../UnauthorizedAlert/helpers";

const CollectCollisionEventName = COLLIDABLE_MODELS.quiz.eventId;

/**
Object.values(a).reduce((r, n, index) => {

  const i = ((index) % 10) + 1;

  let arr = r[i]

  if(!arr) {
      arr = r[i] = []
  }

  arr.push(n)

  return r;
}, {})
 */

// quizQuestions = Object.values(quizQuestions)
//   .slice(0, 3)
//   .reduce<typeof quizQuestions>((a, b, index) => {
//     a[index + 1] = b;

//     return a;
//   }, {});

const TOTAL_ANSWERS = Object.keys(quizQuestions).length;

const litresPromocode = "SBERDEMODAY";

const disableMovement = () => {
  window.APP.store.state.preferences.disableMovement = true;
  window.APP.store.state.preferences.disableTeleporter = true;
  window.APP.store.state.preferences.snapRotationDegrees = 0;
};

const enableMovement = () => {
  window.APP.store.state.preferences.disableMovement = false;
  window.APP.store.state.preferences.disableTeleporter = false;
  window.APP.store.state.preferences.snapRotationDegrees = 45;
};

// const QUIZ_STORAGE_KEY = "quiz_new";

type QuizProps = {
  myVotes?: MyVotes;
};

export const Quiz: React.FC<QuizProps> = ({ myVotes }) => {
  const finded = useMemo(() => myVotes?.questions || [], [myVotes?.questions]);

  const [quiz, setQuiz] = useState<{ quiz: AnsterItem; id: string } | null>(null);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [quizTip, setQuizTip] = useState(false);

  // const { modal: authModal, isAuthed } = useInteractionAuth();

  const {
    // finded,
    // findedSetter,
    collectedAll
  } = useCollect({
    finded,
    // storageKey: QUIZ_STORAGE_KEY,
    collectCollisionEventName: CollectCollisionEventName,
    total: TOTAL_ANSWERS
  });

  const handleQuizClose = useCallback(() => {
    setQuiz(null);
    enableMovement();
    setIsClicked(false);
    if (collectedAll === TOTAL_ANSWERS) {
      setQuizCompleted(true);
      metrikaReachGoal("all-quiz-completed");
      disableMovement();
      return;
    } else if (collectedAll === 1) {
      metrikaReachGoal("first-quiz-encountered");
      setQuizTip(true);
      disableMovement();
      setIsClicked(false);
      return;
    }
    // else if (collectedAll === TOTAL_ANSWERS / 2) {
    //   setPromocode(litresPromocode);
    //   metrikaReachGoal("windows-litres");
    //   disableMovement();
    //   return;
    // }
  }, [collectedAll, litresPromocode]);

  const handleAnswer = (
    id: `${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10}`,
    // Фактический ответ пользователя
    answer: boolean
  ) => {
    /**
     * Нам здесь надо получить сам вопрос, и если пользователь ответил правильно,
     * то сохранить это в хранилище
     */

    // if(quiz.answer === answer) {
    if (answer) {
      console.log("Success");

      /**
       * Сохраняем результат в хранилище
       */

      // let quiz_success: string[] = [];

      // try {
      //   const quiz_success_str = localStorage.getItem("quiz_success");

      //   if (quiz_success_str) {
      //     quiz_success = JSON.parse(quiz_success_str);
      //   }
      // } catch (error) {
      //   console.error;
      // }

      // if (!Array.isArray(quiz_success)) {
      //   quiz_success = [];
      // }

      // quiz_success.push(id);

      // localStorage.setItem("quiz_success", JSON.stringify(quiz_success));

      // dispatchEventCollectionsUpdated();

      vote(`answer${id}`).then(() => {
        // showModal(finded.length + 1);
      });
    } else {
      console.log("Failure");
    }

    setIsClicked(true);
  };

  const handleTipClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setQuizTip(false);
    enableMovement();
  };

  const onCollected = useCallback<onCollisionCollectProps["onCollected"]>(
    findedId => {
      const quizes = quizQuestions[findedId];

      if (!quizes || !quizes.length) {
        return;
      }

      const quizIndex = Math.round(Math.random() * (quizes.length - 1));

      const quiz = quizes[quizIndex];

      if (!quiz) {
        return;
      }

      // TODO Remove
      console.log("Finded quiz", quiz);

      /**
       * Отмечаем, что нашел вопрос
       */
      vote(`question${findedId}`).then(() => {
        // showModal(finded.length + 1);
        setQuiz({
          id: findedId,
          quiz
        });
        disableMovement();
      });
    },
    [finded]
  );

  /**
   * Навешиваем обработчик события коллизии
   */
  useEffect(() => {
    const onCollision = (event: CustomEvent<CollisionEventDetail>) => {
      onCollisionCollect({
        event,
        collectAnimationName: "QuizTriggerCollected",
        finded,
        // findedSetter,
        isAuthed,
        onCollected
      });
    };

    window.addEventListener(CollectCollisionEventName, onCollision);

    return () => {
      window.removeEventListener(CollectCollisionEventName, onCollision);
    };
  }, [finded, onCollected]);

  const handleSberQuizFinished = (close = false) => {
    enableMovement();
    if (close) {
      setQuizCompleted(false);
    }
  };

  const handleCounterClick = () => {
    const answeredCount = collectedAll;
    if (answeredCount === TOTAL_ANSWERS) {
      setQuizCompleted(state => !state);
      return;
    }
    if (answeredCount <= TOTAL_ANSWERS && answeredCount >= 1) {
      setQuizTip(state => !state);
      return;
    }
  };

  return (
    <>
      {/* {authModal} */}
      <div onClick={handleCounterClick} className={styles.quizCounterContainer}>
        <div className={styles.iconQuiz}>
          <div className={styles.counterQuiz}>
            <span className={styles.currQuiz}>{collectedAll}</span>/{TOTAL_ANSWERS}
          </div>
        </div>
      </div>
      <MainViewportModal>
        <>
          {quiz && (
            <Question
              onClick={handleAnswer}
              isClicked={isClicked}
              onClose={handleQuizClose}
              quiz={quiz.quiz}
              id={quiz.id}
            />
          )}
          {quizCompleted && (
            <SberZvukBlock onClose={() => handleSberQuizFinished(true)} onClick={() => handleSberQuizFinished(false)} />
          )}
          {quizTip && (
            <FirstQuiz
              TOTAL_ANSWERS={TOTAL_ANSWERS}
              onClick={handleTipClose}
              collectedAll={collectedAll}
              closeHandler={handleTipClose}
            />
          )}
        </>
      </MainViewportModal>
    </>
  );
};
