import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import constants from "./vendor/hackweek-avatar-maker/src/constants";

type AvatarEditorCustomIframeProps = {
  onAvatarExported: (glb: Blob) => void;
  onCancel: () => void;
};

export const AvatarEditorCustomIframe: React.FC<AvatarEditorCustomIframeProps> = ({ onAvatarExported, onCancel }) => {
  /**
   * Хендлер на сохранение (загрузку) аватара
   */
  useEffect(() => {
    const handler = (
      event: MessageEvent<{
        type?: string;
        glb?: Blob;
      }>
    ) => {
      const { type, glb } = event.data || {};

      if (type === constants.avatarExported && glb) {
        onAvatarExported(glb);
      }
    };

    window.addEventListener("message", handler);

    return () => {
      window.removeEventListener("message", handler);
    };
  }, [onAvatarExported]);

  /**
   * Хендлер на отмену
   */
  useEffect(() => {
    const handler = (
      event: MessageEvent<{
        type?: string;
      }>
    ) => {
      const { type } = event.data || {};

      if (type === constants.resetView) {
        onCancel();
      }
    };

    window.addEventListener("message", handler);

    return () => {
      window.removeEventListener("message", handler);
    };
  }, [onAvatarExported]);

  return ReactDOM.createPortal(
    <iframe
      src={(process.env.BASE_ASSETS_PATH || location.origin).replace(/\/?$/, "") + "/avatar-editor.html"}
      style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        zIndex: 10000,
        top: 0,
        left: 0,
        border: "none"
      }}
    />,
    window.document.body
  );
};
