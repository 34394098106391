import React from "react";
import { Modal } from "../modal/Modal";
import { Button } from "../input/Button";
import { Column } from "../layout/Column";
// import { FormattedMessage } from "react-intl";
import { useHub } from "../../custom/hooks/useHub";

export function SafariMicModal() {
  const { name: hubName = "" } = useHub() || {}

  return (
    <Modal style={{ textAlign: "center", pointerEvents: "all" }} title={"Требуется доступ к микрофону"}>
      <Column center padding>
        <div style={{ textAlign: "center" }}>
          Для того, чтобы стать полноценным участником {hubName} необходимо дать доступ к микрофону. Не переживай, ты
          сам выберешь когда его включить{" "}
        </div>
        <div style={{ textAlign: "center" }}>
          Если предложения дать доступ к микрофону от браузера не поступило,{" "}
          <a
            target={"_blank"}
            href="https://support.apple.com/ru-ru/guide/safari/ibrw7f78f7fe/mac"
            rel="noopener noreferrer"
          >
            воспользуйся инструкцией
          </a>{" "}
          и перезагрузи страницу
        </div>
        <Button
          preset="accept"
          onClick={() => {
            location.reload();
          }}
        >
          Перезагрузить
        </Button>
      </Column>
    </Modal>
  );
}
