import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../modal/Modal";
// import { BackButton } from "../input/BackButton";
import { AvatarSettingsContent } from "./AvatarSettingsContent";
import { FormattedMessage } from "react-intl";
import { useHub } from "../../custom/hooks/useHub";

export function AvatarSetupModal({ className, onBack, ...rest }) {
  const { name: hubName = "" } = useHub() || {};

  return (
    <Modal
      beforeTitle={" "}
      // title={hubName}
      titleNode={
        <h1
          style={{
            fontSize: "1.7rem",
            textAlign: "center"
          }}
        >
          Добро пожаловать в метавселенную<br /> &quot;Теперь я босс!&quot;
        </h1>
      }
      className={className}
    >
      <AvatarSettingsContent {...rest} />
    </Modal>
  );
}

AvatarSetupModal.propTypes = {
  className: PropTypes.string,
  onBack: PropTypes.func
};
