AFRAME.registerComponent("custom-materials", {
  init: function () {
    if (this.el.object3D) {
      this.applyMaterials();
    }
    this.el.addEventListener("model-loaded", this.applyMaterials.bind(this));
  },
  applyMaterials: function () {
    const colorTweakDarken = new THREE.Color(0x222222);
    const colorTweakMedium = new THREE.Color(0x333333);
    // const colorTweakLight = new THREE.Color(0x777777);
    const colorTweak = new THREE.Color(0x444444);
    this.el.object3D.traverse(o => {
      if (o.isSkinnedMesh) {
        if (o.material.isMeshStandardMaterial) {
          const prevMaterial = o.material;
          o.material = new THREE.MeshLambertMaterial();
          THREE.MeshBasicMaterial.prototype.copy.call(o.material, prevMaterial);
          if (o.material.map) {
            o.material.map.encoding = THREE.LinearEncoding;
          }
          switch (o.parent.parent.name) {
            case "Armature_rich":
              o.material.color = colorTweakDarken;
              break;
            case "beauty":
              if (o.material.name !== "Wolf3D_Hair.001") {
                o.material.color = colorTweakMedium; //new THREE.Color(0x777777);
              }
              break;
            case "businessman":
              o.material.color = colorTweakDarken; //new THREE.Color(0x777777);
              break;
            case "Armature":
              if (["Wolf3D_Outfit_Top.001"].includes(o.material.name)) {
                o.material.color = colorTweakDarken;
              } else {
                o.material.color = colorTweak;
              }
              o.material.color = colorTweakDarken;
              break;
            case "itgeek":
              o.material.color = colorTweakDarken;
              break;
          }
          o.material.toneMapped = false;
        }
      }
    });
  }
});

AFRAME.GLTFModelPlus.registerComponent("custom-materials", "custom-materials");
