/* eslint-disable @calm/react-intl/missing-formatted-message */
import React from "react";
import styles from "./styles.scss";

// import getLink from "./assets/images/get_link_01.png";
// import getLinkActive from "./assets/images/get_link_02.png";

// import save from "./assets/images/save_01.png";
// import saveActive from "./assets/images/save_02.png";
import closeBtnFillerDefault from "./assets/images/cross_01.png";
import closeBtnFillerActive from "./assets/images/cross_02.png";

// import shareVk from "./assets/images/share_vk_01.png";
// import shareVkActive from "./assets/images/share_vk_02.png";

type StarConstructorModalProps = {
  pathToPhoto: string;
  closeHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  // imgIndex: number;
};

export const StarConstructorModal: React.FC<StarConstructorModalProps> = ({ pathToPhoto, closeHandler }) => {
  // const copyLink = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   // Копирование ссылки по клику

  // };

  // useEffect(() => {
  //   /**
  //    * Init copier
  //    */
  //   try {
  //     // @ts-expect-error
  //     const ClipboardJS = window.ClipboardJS;

  //     new ClipboardJS("#copyLink", {
  //       text: () => {
  //         return location.origin;
  //       }
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }

  //   /**
  //    * init sharer
  //    */
  //   // @ts-expect-error
  //   window.Sharer.init();
  // }, []);

  return (
    <div className={styles["star-constructor-container"]}>
      <div className={styles["main"]}>
        <div className={styles["main__slider"]}>
          <iframe src={pathToPhoto} />
        </div>

        <button className={styles.closeBtn} onClick={closeHandler} aria-label="Закрыть окно">
          <img
            className={`${styles.closeBtnFiller} ${styles.closeBtnFillerDefault}`}
            src={closeBtnFillerDefault}
            alt=""
          />
          <img
            className={`${styles.closeBtnFiller} ${styles.closeBtnFillerActive}`}
            src={closeBtnFillerActive}
            alt=""
          />
        </button>
      </div>
    </div>
  );
};
