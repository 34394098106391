import React, { useCallback, useEffect, useState } from "react";
import { getMyVotes, MyVotes, VOTED_EVENT_NAME } from "../../boss-voting";

export const useCallected = () => {
  const [myVotes, myVotesSetter] = useState<MyVotes | undefined>();

  const getVotes = useCallback(() => {
    getMyVotes()
      .then(r => {
        console.log("getMyVotes r", r);

        myVotesSetter(r);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    getVotes();

    window.addEventListener(VOTED_EVENT_NAME, getVotes);

    return () => {
      window.removeEventListener(VOTED_EVENT_NAME, getVotes);
    };
  }, [getVotes]);

  return {
    myVotes
  };
};
