// import { AElementECS } from "aframe";
import React, { useCallback, useEffect, useMemo, useState } from "react";
// import { AnimationClip, Group, Mesh, Object3D } from "three";
import { vote } from "../../../boss-voting";
// import { findAncestorWithComponent } from "../../../../utils/scene-graph";
import {
  CollisionEventDetail,
  onCollisionCollect,
  onCollisionCollectProps
} from "../../../Collect/helpers/onCollisionCollect";
import { useCollect } from "../../../Collect/hooks/useCollect";
// import { useInteractionAuth } from "../../../Collect/hooks/useInteractionAuth";
import { COLLIDABLE_MODELS } from "../../../collidable-model/constants";
import { metrikaReachGoal } from "../../../utils/metrika";
// import { IconCat } from "../icons/Cat/IconCat";
// import { Modal } from "../Modal";
import { CATS_STORAGE_KEY } from "./interfaces";
// import { CatsPrizeModal } from "./modals/Prize";

import styles from "./styles.scss";
import { isAuthed } from "../../../UnauthorizedAlert/helpers";

/* 
Придется применить разные стили, несмотря на одинаковые селекторы, т.к. 
в силу дизайна пришлось использовать разные медиа запросы и разные отступы.

В силу сжатых сроков решаем быстро, как будет время - поправлю и оптимизирую
*/
import openerStyles from "./styles-collect-open.scss";
import completeStyles from "./styles-collect-complete.scss";

import bigFrame from "../../../Collect/assets/images/big-frame.png";
import bottomLeftAngle from "../../../Collect/assets/images/bottom-left-angle.png";
import topRightAngle from "../../../Collect/assets/images/top-right-angle.png";
import closeBtnFillerDefault from "../../../Collect/assets/images/cross_01.png";
import closeBtnFillerActive from "../../../Collect/assets/images/cross_02.png";
import topLightning from "../../../Collect/assets/images/top-lightning.png";
import topStar from "../../../Collect/assets/images/top-star.png";
import bottomLightning from "../../../Collect/assets/images/bottom-lightning.png";
import bottomStar from "../../../Collect/assets/images/bottom-star.png";

const CollectCollisionEventName = COLLIDABLE_MODELS.cats2.eventId;

const STORAGE_KEY = CATS_STORAGE_KEY;

const CAT_COLLECTED_ANIMATION_NAME = "CatCollected";

/**
 * Сколько всего котов требуется
 */
const TOTAL = 10;

type CollectCatsModal = "first" | "any" | "all";

type CollectCats2Props = {
  catsCollected: string[];
};

export const CollectCats2: React.FC<CollectCats2Props> = ({ catsCollected: finded }) => {
  // const { modal: authModal, isAuthed } = useInteractionAuth();

  const {
    // finded,
    // findedSetter,
    collectedAll
  } = useCollect({
    finded,
    // storageKey: STORAGE_KEY,
    collectCollisionEventName: CollectCollisionEventName,
    total: TOTAL
  });

  const [modal, modalSetter] = useState<CollectCatsModal | null>(null);

  const showModal = useCallback((findedTotal: number, showAny = false) => {
    /**
     * В зависимости от количества найденных, выводим модалку
     */
    switch (findedTotal) {
      case 0:
        modalSetter(null);
        break;
      case 1:
        metrikaReachGoal("first-statuette-encountered");
        modalSetter("first");
        break;
      case TOTAL:
        modalSetter("all");
        break;
      default:
        showAny && modalSetter("any");
    }
  }, []);

  const showStatusModal = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      showModal(finded.length, true);
    },
    [finded, showModal]
  );

  const onCollected = useCallback<onCollisionCollectProps["onCollected"]>(
    findedId => {
      console.log("onCollected findedId", findedId);

      vote(`cat${findedId}`).then(() => {
        showModal(finded.length + 1);
      });
    },
    [showModal, finded]
  );

  /**
   * Навешиваем обработчик события коллизии
   */
  useEffect(() => {
    const onCollision = (event: CustomEvent<CollisionEventDetail>) => {
      onCollisionCollect({
        event,
        collectAnimationName: CAT_COLLECTED_ANIMATION_NAME,
        finded,
        // findedSetter,
        isAuthed,
        onCollected
      });
    };

    window.addEventListener(CollectCollisionEventName, onCollision);

    return () => {
      window.removeEventListener(CollectCollisionEventName, onCollision);
    };
  }, [finded, onCollected]);

  const closeModal = useCallback(() => {
    modalSetter(null);
  }, []);

  const modalContent = useMemo(() => {
    if (!modal) {
      return;
    }

    let innerContent: string | JSX.Element = "";

    if (collectedAll === TOTAL) {
      return (
        <div className={completeStyles[`modal-window`]} key={`${modal}-${collectedAll}`}>
          <div className={completeStyles["modal-window-frame"]}>
            <div
              className={completeStyles["top-right-angle"]}
              style={{ backgroundImage: `url(${topRightAngle})` }}
            ></div>
            <div
              className={completeStyles["bottom-left-angle"]}
              style={{ backgroundImage: `url(${bottomLeftAngle})` }}
            ></div>
            <div className={completeStyles["big-frame"]} style={{ backgroundImage: `url(${bigFrame})` }}></div>

            <img className={completeStyles["top-lightning"]} src={topLightning} alt="" />
            <img className={completeStyles["top-star"]} src={topStar} alt="" />

            <img className={completeStyles["bottom-lightning"]} src={bottomLightning} alt="" />
            <img className={completeStyles["bottom-star"]} src={bottomStar} alt="" />
          </div>

          <div className={completeStyles["modal-window-content"]}>
            <div className={completeStyles["modal-window-overlay"]}>
              <div className={completeStyles["modal-window-overlay-glow"]}></div>
            </div>

            <div className={completeStyles["modal-window-flex"]}>
              <p className={completeStyles["modal-window-text"]}>
                Молодец! <br />
                Ты собрал все звезды. <br />
              </p>
            </div>
          </div>

          <button className={completeStyles["close-btn"]} onClick={closeModal} aria-label="Закрыть окно">
            <span
              className={`${completeStyles["close-btn-filler"]} ${completeStyles["close-btn-filler-default"]}`}
              style={{ backgroundImage: `url(${closeBtnFillerDefault})` }}
            ></span>
            <span
              className={`${completeStyles["close-btn-filler"]} ${completeStyles["close-btn-filler-active"]}`}
              style={{ backgroundImage: `url(${closeBtnFillerActive})` }}
            ></span>
          </button>
        </div>
      );
    } else {
      return (
        <div className={openerStyles[`modal-window`]} key={`${modal}-${collectedAll}`}>
          <div className={openerStyles["modal-window-frame"]}>
            <div className={openerStyles["top-right-angle"]} style={{ backgroundImage: `url(${topRightAngle})` }}></div>
            <div
              className={openerStyles["bottom-left-angle"]}
              style={{ backgroundImage: `url(${bottomLeftAngle})` }}
            ></div>
            <div className={openerStyles["big-frame"]} style={{ backgroundImage: `url(${bigFrame})` }}></div>

            <img className={openerStyles["top-lightning"]} src={topLightning} alt="" />
            <img className={openerStyles["top-star"]} src={topStar} alt="" />

            <img className={openerStyles["bottom-lightning"]} src={bottomLightning} alt="" />
            <img className={openerStyles["bottom-star"]} src={bottomStar} alt="" />
          </div>

          <div className={openerStyles["modal-window-content"]}>
            <div className={openerStyles["modal-window-overlay"]}>
              <div className={openerStyles["modal-window-overlay-glow"]}></div>
            </div>

            <div className={openerStyles["modal-window-flex"]}>
              <p className={openerStyles["modal-window-text"]}>
                На&nbsp;карте раскидано 10&nbsp;дополнительных звезд. <br />
                Найди их&nbsp;всех!
              </p>
              <button onClick={closeModal} className={openerStyles["modal-window-btn"]}>
                Продолжить
              </button>
            </div>
          </div>

          <button className={openerStyles["close-btn"]} onClick={closeModal} aria-label="Закрыть окно">
            <span
              className={`${openerStyles["close-btn-filler"]} ${openerStyles["close-btn-filler-default"]}`}
              style={{ backgroundImage: `url(${closeBtnFillerDefault})` }}
            ></span>
            <span
              className={`${openerStyles["close-btn-filler"]} ${openerStyles["close-btn-filler-active"]}`}
              style={{ backgroundImage: `url(${closeBtnFillerActive})` }}
            ></span>
          </button>
        </div>
      );
    }
  }, [modal, collectedAll, closeModal]);

  return (
    <>
      <div className={styles.catsCollection} onClick={showStatusModal}>
        <div className={styles.counterCat}>
          <span className={styles.currCats}>{finded.length}</span>/{TOTAL}
        </div>
      </div>
      {modalContent}
    </>
  );
};
