import { TVoteId } from "../boss-voting";

const MetrikaID = 91375135;

type MetrikaGoal =
  | "photo-click"
  | "voted-for"
  | "all-statuettes-collected"
  | "all-quiz-completed"
  | "first-statuette-encountered"
  | "first-quiz-encountered"
  | TVoteId;

export const metrikaReachGoal = (goal: MetrikaGoal) => {
  // @ts-expect-error
  if (typeof ym === "function") {
    // @ts-expect-error
    ym(MetrikaID, "reachGoal", goal);
  }
};
