import { MeshBasicMaterial, MeshStandardMaterial } from "three";

AFRAME.registerComponent("tone-mapped", {
  schema: {
    enabled: { type: "boolean", default: true },
    metalness: { type: "float", default: 0.5 },
    roughness: { type: "float", default: 1 }
  },
  init: function () {
    if (!this.data.enabled) {
      if (this.el.object3D) {
        this.applyToneMapping();
      }
      this.el.addEventListener("model-loaded", this.applyToneMapping.bind(this));
    }
  },
  applyToneMapping: function () {
    this.el.object3D.traverse(o => {
      if (o.material) {
        if (o.material.isMeshPhysicalMaterial) {
          const prevMaterial = o.material;
          o.material = new MeshStandardMaterial();
          MeshBasicMaterial.prototype.copy.call(o.material, prevMaterial);
        }
        o.material.toneMapped = false;
        o.material.metalness = 0.7; // radio testing purpose
        o.material.needsUpdate = true;
      }
    });
  }
});

AFRAME.GLTFModelPlus.registerComponent("tone-mapped", "tone-mapped");
