import SPE from "./three/particles/spe";

const toVector3 = a => new THREE.Vector3(a.x, a.y, a.z);
const toColor = a => new THREE.Color(a);
const toVector2 = a => new THREE.Vector2(a.x, a.y);
const parseFloatArray = a => (typeof a === "string" ? a.split(",").map(y => parseFloat(y)) : a);
export const degToRad = a => (parseFloat(a) / 180) * Math.PI;
const radToDeg = a => (parseFloat(a) * 180) / Math.PI;
let uniqueEmitterID = 1;

// Use a custom diff because AFRAME.utils.diff() does not correctly diff arrays (https://github.com/aframevr/aframe/issues/3591)
function diff(a, b) {
  const delta = {};
  const keys = Object.keys(a);
  for (const k in b) {
    if (!keys.includes[k]) {
      keys.push(k);
    }
  }

  for (const k of keys) {
    const av = a[k];
    const bv = b[k];
    const isObj =
      av && bv && ((av.constructor == Object && bv.constructor === Object) || (Array.isArray(av) && Array.isArray(bv)));

    if ((isObj && !AFRAME.utils.deepEqual(av, bv)) || (!isObj && av !== bv)) {
      delta[k] = bv;
    }
  }

  return delta;
}
AFRAME.registerComponent("spe-particles", {
  schema: {
    enabled: {
      default: true,
    },
    frustumCulled: {
      default: false,
    },

    // GROUP ATTRIBUTES
    texture: {
      type: "map",
    },
    textureFrames: {
      type: "vec2",
      default: { x: 1, y: 1 },
    },
    textureFrameCount: {
      type: "int",
      default: -1,
    },
    textureFrameLoop: {
      type: "int",
      default: 1,
    },
    // "maximum number of particles for all emitters in this group (currently only one emitter per group)",
    // maxParticleCount: {
    //   default: 1000,
    // },
    blending: {
      default: "normal",
      oneOf: ["no", "normal", "additive", "subtractive", "multiply", "custom"],
      parse: x => (x || "no").toLowerCase()
    },
    hasPerspective: {
      default: true,
    },
    useTransparency: {
      default: true,
    },
    alphaTest: {
      default: 0,
      min: 0,
      max: 1,
    },
    depthWrite: {
      default: false,
    },
    depthTest: {
      default: true,
    },
    affectedByFog: {
      default: true,
    },
    emitterScale: {
      default: 100,
    },

    // EMITTER ATTRIBUTES
    relative: {
      default: "local",
      oneOf: ["local", "world"],
      parse: x => (x || "local").toLowerCase()
    },
    particleCount: {
      type: "int",
      default: 100,
    },
    duration: {
      default: -1,
    },
    distribution: {
      default: "BOX",
      oneOf: ["BOX", "SPHERE", "DISC"],
      parse: x => (x || "BOX").toUpperCase()
    },
    activeMultiplier: {
      default: 1,
      min: 0,
    },
    direction: {
      default: "forward",
      oneOf: ["forward", "backward"],
      parse: x => (x || "forward").toLowerCase()
    },
    maxAge: {
      default: 1,
    },
    maxAgeSpread: {
      default: 0,
    },
    positionDistribution: {
      default: "NONE",
      oneOf: ["NONE", "BOX", "SPHERE", "DISC"],
      parse: x => (x || "NONE").toUpperCase()
    },
    positionSpread: {
      type: "vec3",
    },
    positionOffset: {
      type: "vec3",
    },
    randomizePosition: {
      default: false,
    },
    radius: {
      default: 1,
      min: 0,
    },
    radiusScale: {
      type: "vec3",
      default: { x: 1, y: 1, z: 1 },
    },
    velocityDistribution: {
      default: "NONE",
      oneOf: ["NONE", "BOX", "SPHERE", "DISC"],
      parse: x => (x || "NONE").toUpperCase()
    },
    velocity: {
      type: "vec3",
    },
    velocitySpread: {
      type: "vec3",
    },
    randomizeVelocity: {
      default: false,
    },
    accelerationDistribution: {
      default: "NONE",
      oneOf: ["NONE", "BOX", "SPHERE", "DISC"],
      parse: x => (x || "NONE").toUpperCase()
    },
    acceleration: {
      type: "vec3",
    },
    accelerationSpread: {
      type: "vec3",
    },
    randomizeAcceleration: {
      default: false,
    },
    drag: {
      default: 0,
      min: 0,
      max: 1,
    },
    dragSpread: {
      default: 0,
    },
    randomizeDrag: {
      default: false,
    },
    wiggle: {
      default: 0,
    },
    wiggleSpread: {
      default: 0,
    },
    rotation: {
      default: 0,
      parse: x => degToRad(x),
      stringify: x => radToDeg(x)
    },
    rotationSpread: {
      default: 0,
      parse: x => degToRad(x),
      stringify: x => radToDeg(x)
    },
    rotationAxis: {
      type: "vec3",
    },
    rotationAxisSpread: {
      type: "vec3",
    },
    rotationStatic: {
      default: false,
    },
    // rotationPivot: {
    //   default: {x: Number.MAX_VALUE, y: Number.MAX_VALUE, z: Number.MAX_VALUE, },
    // },
    randomizeRotation: {
      default: false,
    },
    color: {
      type: "array",
      default: ["#fff"],
    },
    colorSpread: {
      type: "array",
      default: [],
      parse: x => (typeof x === "string" ? x.split(",").map(AFRAME.utils.coordinates.parse) : x),
      stringify: x => x.map(AFRAME.utils.coordinates.stringify).join(",")
    },
    randomizeColor: {
      default: false,
    },
    opacity: {
      type: "array",
      default: [1],
      parse: parseFloatArray
    },
    opacitySpread: {
      type: "array",
      default: [0],
      description: "spread in opacity over the particle's lifetime, max 4 elements",
      parse: parseFloatArray
    },
    randomizeOpacity: {
      default: false,
      description: "if true, re-randomize opacity when re-spawning a particle, can incur a performance hit"
    },
    size: {
      type: "array",
      default: [1],
      description: "array of sizes over the particle's lifetime, max 4 elements",
      parse: parseFloatArray
    },
    sizeSpread: {
      type: "array",
      default: [0],
      description: "spread in size over the particle's lifetime, max 4 elements",
      parse: parseFloatArray
    },
    randomizeSize: {
      default: false,
      description: "if true, re-randomize size when re-spawning a particle, can incur a performance hit"
    },
    angle: {
      type: "array",
      default: [0],
      description: "2D rotation of the particle over the particle's lifetime, max 4 elements",
      parse: parseFloatArray
    },
    angleSpread: {
      type: "array",
      default: [0],
      description: "spread in angle over the particle's lifetime, max 4 elements",
      parse: parseFloatArray
    },
    randomizeAngle: {
      default: false,
      description: "if true, re-randomize angle when re-spawning a particle, can incur a performance hit"
    }
  },

  multiple: true,

  init: function () {
    this.particleGroup = null;
    this.referenceEl = null;
    this.pauseTickId = undefined;
    this.emitterID = uniqueEmitterID++;
    this.pauseTick = this.pauseTick.bind(this);
    this.defaultTexture = new THREE.DataTexture(new Uint8Array(4).fill(255), 1, 1, THREE.RGBAFormat);
    this.defaultTexture.needsUpdate = true;
  },

  update: function (oldData) {
    const delta = diff(oldData, this.data);
    if (Object.keys(delta).some(x => !["enabled"].includes(x))) {
      this.removeParticleSystem();
      this.addParticleSystem();
    }

    if (this.data.enabled) {
      this.startParticles();
    } else {
      this.stopParticles();
    }

    // HACK - assume editor is up if isPlaying on this component is false
    if (!this.isPlaying) {
      this.setupPauseTick();
    } else {
      this.shutdownPauseTick();
    }
  },

  remove: function () {
    this.removeParticleSystem();
  },

  tick: function (time, timeDelta) {
    this.tickParticleSystem(timeDelta);
  },

  pause: function () {
    this.setupPauseTick();
  },

  play: function () {
    this.shutdownPauseTick();
  },

  setupPauseTick: function () {
    if (!this.pauseTickId) {
      this.pauseTick(true);
    }
  },

  shutdownPauseTick: function () {
    if (this.pauseTickId) {
      clearTimeout(this.pauseTickId);
      this.pauseTickId = undefined;
    }
  },

  pauseTick: function () {
    this.tickParticleSystem(33);
    this.pauseTickId = setTimeout(this.pauseTick, 33);
  },

  tickParticleSystem: function (dt) {
    if (this.data.relative === "world") {
      const newPosition = toVector3(this.data.positionOffset).applyMatrix4(this.el.object3D.matrixWorld);
      this.particleGroup.emitters[0].position.value = newPosition;
    }
    this.particleGroup && this.particleGroup.tick(dt / 1000);
  },

  addParticleSystem: function () {
    const data = this.data;
    const textureLoader = new THREE.TextureLoader();
    const particleTexture = data.texture ? textureLoader.load(data.texture) : this.defaultTexture;

    let blending = data.blending || "No";
    blending = blending.charAt(0).toUpperCase() + blending.substring(1).toLowerCase() + "Blending";

    console.assert(this.particleGroup === null);
    const groupOptions = {
      texture: {
        value: particleTexture,
        frames: toVector2(data.textureFrames),
        frameCount: data.textureFrameCount >= 0 ? data.textureFrameCount : undefined,
        loop: data.textureFrameLoop
      },
      maxParticleCount: data.particleCount, //data.maxParticleCount,
      blending: THREE[blending],
      hasPerspective: data.hasPerspective,
      transparent: data.useTransparency,
      alphaTest: data.alphaTest,
      depthWrite: data.depthWrite,
      depthTest: data.depthTest,
      fog: data.affectedByFog,
      scale: data.emitterScale
    };
    this.particleGroup = new SPE.Group(groupOptions);

    const emitterOptions = {
      type: SPE.distributions[data.distribution in SPE.distributions ? data.distribution : "BOX"],
      particleCount: data.particleCount,
      duration: data.duration >= 0 ? data.duration : null,
      // isStatic: true,
      activeMultiplier: data.activeMultiplier,
      direction: data.direction === "forward" ? 1 : -1,
      maxAge: {
        value: data.maxAge,
        spread: data.maxAgeSpread
      },
      position: {
        value:
          data.relative === "World"
            ? toVector3(this.data.positionOffset).applyMatrix4(this.el.object3D.matrixWorld)
            : toVector3(data.positionOffset).applyMatrix4(this.el.object3D.matrix),
        radius: data.radius,
        radiusScale: toVector3(data.radiusScale),
        spread: toVector3(data.positionSpread),
        distribution:
          SPE.distributions[
            data.positionDistribution in SPE.distributions ? data.positionDistribution : data.distribution
          ], // default to the base distribution
        randomise: data.randomizePosition
      },
      velocity: {
        value: toVector3(data.velocity),
        spread: toVector3(data.velocitySpread),
        distribution:
          SPE.distributions[
            data.velocityDistribution in SPE.distributions ? data.velocityDistribution : data.distribution
          ], // default to the base distribution
        randomise: data.randomizeVelocity
      },
      acceleration: {
        value: toVector3(data.acceleration),
        spread: toVector3(data.accelerationSpread),
        distribution:
          SPE.distributions[
            data.accelerationDistribution in SPE.distributions ? data.accelerationDistribution : data.distribution
          ], // default to the base distribution
        randomise: data.randomizeAcceleration
      },
      drag: {
        value: data.drag,
        spread: data.dragSpread,
        randomise: data.randomizeDrag
      },
      wiggle: {
        value: data.wiggle,
        spread: data.wiggleSpread
      },
      rotation: {
        axis: toVector3(data.rotationAxis),
        axisSpread: toVector3(data.rotationAxisSpread),
        angle: data.rotation,
        angleSpread: data.rotationSpread,
        static: data.rotationStatic,
        // center: data.rotationPivot,
        randomise: data.randomizeRotation
      },
      color: {
        value: data.color.length > 0 ? data.color.map(x => toColor(x)) : [toColor("#fff")],
        spread: data.colorSpread.length > 0 ? data.colorSpread.map(x => toVector3(x)) : undefined, // doesn't accept an empty array
        randomise: data.randomizeColor
      },
      opacity: {
        value: data.opacity,
        spread: data.opacitySpread,
        randomise: data.randomizeOpacity
      },
      size: {
        value: data.size,
        spread: data.sizeSpread,
        randomise: data.randomizeSize
      },
      angle: {
        value: data.angle,
        spread: data.angleSpread,
        randomise: data.randomizeAngle
      }
    };
    const emitter = new SPE.Emitter(emitterOptions);

    this.particleGroup.addEmitter(emitter);
    this.particleGroup.mesh.frustumCulled = data.frustumCulled; // TODO verify this

    // World emitters are parented to the world and we set their position each frame.
    // Local emitters are parented to the DOM entity
    this.referenceEl = data.relative === "world" ? this.el.sceneEl : this.el;
    this.referenceEl.setObject3D(this.getEmitterName(), this.particleGroup.mesh);
  },

  removeParticleSystem: function () {
    if (this.particleGroup) {
      this.referenceEl.removeObject3D(this.getEmitterName());
      this.particleGroup = null;
    }
  },

  startParticles: function () {
    this.particleGroup.emitters.forEach(em => em.enable());
  },

  stopParticles: function () {
    this.particleGroup.emitters.forEach(em => em.disable());
  },

  getEmitterName: function () {
    return "spe-particles" + this.emitterID;
  }
});
