import React, { useEffect, useRef } from "react";
// import IconQuiz from "../assets/images/quizz.png";
// import IconQuiz from "../assets/images/Statue.png";
// import IconStatue from "../assets/images/Statue.png";

import bigFrame from "../assets/images/big-frame.png";
import bottomLeftAngle from "../assets/images/bottom-left-angle.png";
import topRightAngle from "../assets/images/top-right-angle.png";
import closeBtnFillerDefault from "../assets/images/cross_01.png";
import closeBtnFillerActive from "../assets/images/cross_02.png";
import topLightning from "../assets/images/top-lightning.png";
import topStar from "../assets/images/top-star.png";
import bottomLightning from "../assets/images/bottom-lightning.png";
import bottomStar from "../assets/images/bottom-star.png";

import styles from "../assets/stylesheets/quiz-opener-ui.scss";

type FirstQuizProps = {
  TOTAL_ANSWERS: number;
  closeHandler: (event: React.MouseEvent | MouseEvent) => void;
  onClick: (event: React.MouseEvent | MouseEvent) => void;
  collectedAll: number;
};

export const FirstQuiz: React.FC<FirstQuizProps> = ({ onClick, closeHandler, TOTAL_ANSWERS, collectedAll }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const el = ref.current;

    if (!el) {
      return;
    }

    const onClick = (event: MouseEvent) => {
      event.stopPropagation();

      closeHandler(event);
    };

    el.addEventListener("click", onClick);

    return () => {
      el.removeEventListener("click", onClick);
    };
  }, [closeHandler]);

  return (
    <>
      <div ref={ref} className={styles[`modal-window`]}>
        <div className={styles["modal-window-frame"]}>
          <div className={styles["top-right-angle"]} style={{ backgroundImage: `url(${topRightAngle})` }}></div>
          <div className={styles["bottom-left-angle"]} style={{ backgroundImage: `url(${bottomLeftAngle})` }}></div>
          <div className={styles["big-frame"]} style={{ backgroundImage: `url(${bigFrame})` }}></div>

          <img className={styles["top-lightning"]} src={topLightning} alt="" />
          <img className={styles["top-star"]} src={topStar} alt="" />

          <img className={styles["bottom-lightning"]} src={bottomLightning} alt="" />
          <img className={styles["bottom-star"]} src={bottomStar} alt="" />
        </div>

        <div className={styles["modal-window-content"]}>
          <div className={styles["modal-window-overlay"]}>
            <div className={styles["modal-window-overlay-glow"]}></div>
          </div>
          <div className={styles["modal-window-flex"]}>
            <p className={styles["modal-window-text"]}>
              Как хорошо ты&nbsp;знаешь вселенную больших боссов? <br />
              Ищи знаки вопроса. Отвечай правильно. <br />
              За&nbsp;каждый правильный ответ&nbsp;&mdash; звезда!
            </p>
            <button onClick={onClick} className={styles["modal-window-btn"]}>Продолжить</button>
          </div>
        </div>

        <button className={styles["close-btn"]} onClick={onClick} aria-label="Закрыть окно">
          <span
            className={`${styles["close-btn-filler"]} ${styles["close-btn-filler-default"]}`}
            style={{ backgroundImage: `url(${closeBtnFillerDefault})` }}
          ></span>
          <span
            className={`${styles["close-btn-filler"]} ${styles["close-btn-filler-active"]}`}
            style={{ backgroundImage: `url(${closeBtnFillerActive})` }}
          ></span>
        </button>
      </div>
    </>
  );
  // return (
  //   <div ref={ref} className={styles.modalWrapper}>
  //     <div className={styles.modalContent}>
  //       <div className={styles.title}>Призы близко!</div>
  //       <img src={IconQuiz} />
  //       <div className={styles.total}>
  //         <b>{collectedAll}</b>/{TOTAL_ANSWERS}
  //       </div>
  //       <div className={styles.text}>
  //         <b>{collectedAll === 1 ? `Ты нашел первый вопрос!` : "Ты ответил на вопрос Квиза!"}</b>
  //         <br />Найди все вопросы и получи возможность принять участие в розыгрыше призов!
  //       </div>
  //       <div onClick={onClick} className={styles.searchQuiz}>
  //         Ух, интересно!
  //       </div>
  //     </div>
  //   </div>
  // );
};
