import React, { ChangeEvent } from "react";
import { SelectInputField } from "../../react-components/input/SelectInputField";

import { TextInputField } from "../../react-components/input/TextInputField";

type useBadgeDataProps = {
  disableDisplayNameInput: boolean;
  displayName: string | undefined;
  displayNamePattern?: string;
  onChangeDisplayName: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, display?: boolean) => void;
  displayNameInputRef?: React.Ref<any>;
  styles: Record<string, string>;
};

const badgeData = [
  "Эксперт",
  "Инвестор",
  "Стартапер",
  "Сотрудник корпорации",
  "Гость",
];

export const useBadgeData = (props: useBadgeDataProps) => {

  const {
    disableDisplayNameInput,
    displayName,
    displayNamePattern,
    onChangeDisplayName,
    displayNameInputRef,
    styles,
  } = props;

  let value: string = badgeData[4];

  const displayNamePieces = (displayName || '').split("#")

  if(displayNamePieces.length === 2) {
    value = displayNamePieces[1]
  }

  return (
    <>
      <TextInputField
        // @ts-expect-error
        disabled={disableDisplayNameInput}
        // label={<FormattedMessage id="avatar-settings-content.display-name-label" defaultMessage="Display Name" />}
        value={displayName}
        pattern={displayNamePattern}
        spellCheck="false"
        required
        onChange={onChangeDisplayName}
        // description={
        //     <FormattedMessage
        //         id="avatar-settings-content.display-name-description"
        //         defaultMessage="Alphanumerics, hyphens, underscores, and tildes. At least 3 characters, no more than 32"
        //     />
        // }
        ref={displayNameInputRef}
      />
      <SelectInputField
        className={styles.selectionInput}
        buttonClassName={styles.selectionInput}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          onChangeDisplayName(e, true);
        }}
        value={value}
        options={badgeData}
        description={undefined}
        error={false}
        fullWidth
        inputClassName={undefined}
        label={undefined}
      />
    </>
  );
};
