import React, { useMemo } from "react";
import { MyVotes } from "../../boss-voting";

import styles from "./styles.scss";

type CollectStarProps = {
  myVotes?: MyVotes;
};

export const CollectStar: React.FC<CollectStarProps> = ({ myVotes }) => {
  const finded = useMemo(() => {
    let finded = 0;

    finded += myVotes?.answers.length || 0;
    finded += myVotes?.cats.length || 0;
    finded += myVotes?.boss || 0;
    finded += myVotes?.photo || 0;
    finded += myVotes?.star || 0;

    return finded;
  }, [myVotes]);

  return (
    <>
      <div className={styles.catsCollection}>
        <div className={styles.counterCat}>
          <span className={styles.currCats}>{finded}</span>
        </div>
      </div>
    </>
  );
};
