import "aframe";
import { TYPE } from "three-ammo/constants";
import { COLLISION_LAYERS } from "../../../constants";


AFRAME.GLTFModelPlus.registerComponent("collidable-model", "collidable-model", (el, _componentName, componentData) => {  
  if(!componentData.eventId || !componentData.objectId) {
    console.error("collidable-model can not get data", componentData);
    return;
  }

  /**
   * Add mesh
   */
  const mesh = new THREE.Mesh(
    new THREE.BoxBufferGeometry(2, 2, 2),
    new THREE.MeshStandardMaterial({ transparent: true, opacity: 0 })
  );

  Object.assign(mesh.userData, {
    eventId: componentData.eventId,
    objectId: componentData.objectId,
  });

  /**
   * Добавляем меш, так как коллизии вычисляются именно по геометрии мешей
   */
  el.setObject3D("mesh", mesh);

  /**
   * Боди хелпер нужен, чтобы афрейм обрабатывал коллизии
   */
  el.setAttribute("body-helper", {
    mass: 0,
    type: TYPE.STATIC,
    collisionFilterGroup: COLLISION_LAYERS.INTERACTABLES,
    collisionFilterMask: COLLISION_LAYERS.DEFAULT_SPAWNER
  });

  /**
   * Подключаем афрейм-компонент
   */
  // el.setAttribute("collidable-model", {});
});
