import { modifyObjectShader } from "./shaders";
import glass from "../../assets/custom/textures/glass9.png";
import gold from "../../assets/custom/textures/gold2.jpg";
import silver from "../../assets/custom/textures/silver.jpg";
import silver2 from "../../assets/custom/textures/silver2.jpg";
import { Reflector } from "../../custom/visual-effects/three/radioreflector";
const txLoader = new THREE.TextureLoader();
const redColor = new THREE.Color(0xffce8f);

const glowVert = `

  varying vec3 vposition;
  varying vec2 vuv;

  void main() {
    vuv = uv;
    vposition = position;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }

`;

const glowFrag = `

  varying vec2 vuv;
  varying vec3 vposition;
  uniform vec3 color;
  uniform float height;
  uniform float time;

  void main() {
    vec3  glow = color;
          glow *= 0.5-abs(vposition.y/height);
          glow *= sin(time*3.0+vuv.x*3.1415*4.0)*0.25+1.0;
          glow *= step(0.0,vposition.y);
    gl_FragColor = vec4(glow, 1.0);	
  }

`;

const auraMat = new THREE.ShaderMaterial({
  uniforms: {
    height: { value: 7 },
    time: { value: 0 },
    color: { value: new THREE.Vector3(0.7, 0.7, 0) }
  },
  vertexShader: glowVert,
  fragmentShader: glowFrag,
  blending: THREE.AdditiveBlending,
  side: THREE.DoubleSide,
  transparent: true
});

export function modifyObjectMaterial(o) {
  if (o.name === "SKY") {
    o.scale.setScalar(10);
    o.position.setY(150);
    o.material.toneMapped = false;
    o.material.map.encoding = THREE.LinearEncoding;
    o.material.map.magFilter = o.material.map.minFilter = THREE.LinearFilter;
  }

  if (o.material) {
    if (o.material.map) {
      o.material.map.magFilter = THREE.LinearFilter;
      o.material.map.minFilter = THREE.LinearMipmapNearestFilter;
      o.material.map.anisotropy = o.el.sceneEl.renderer.capabilities.getMaxAnisotropy();
    }

    if (o.material.isMeshStandardMaterial) {
      o.material.metalness = 0.87;
    }

    if (o.material.transparent === true) {
      o.renderOrder = 2000;
    }

    if (o.material.name.includes("photoBudka_") || o.material.name.includes("vopros_")) {
      const prevMat = o.material;
      o.material = new THREE.MeshMatcapMaterial({ matcap: txLoader.load(silver2) });
      o.material.map = prevMat.map;
      o.material.color = new THREE.Color(0xffffff);
      o.material.toneMapped = false;
    }

    if (o.material.name.includes("Sphere024") || o.material.name.includes("geoSphere")) {
      o.material = new THREE.MeshMatcapMaterial({ matcap: txLoader.load(gold) });
      o.material.color = redColor;
    }

    if (o.material.name.includes("Floor_0") || o.material.name.includes("zdanie_") || o.name === "Box013") {
      if (o.material.name.includes("Floor_0")) {
        modifyObjectShader(o);
        return;
      }
      const prevMat = o.material;
      o.material = new THREE.MeshMatcapMaterial({ matcap: txLoader.load(silver) });
      o.material.map = prevMat.map;
    }

    modifyObjectShader(o);

    o.material.needsUpdate = true;
  }

  o.matrixNeedsUpdate = true;
}

export function loadCustomContent(scene) {
  const color = new THREE.Color(0x888800);
  if (APP.hub?.scene?.name === "boss-metaverse") {
    scene.traverse(o => {
      if (o.userData.eventId === "portal" || o.userData.eventId === "hub") {
        o.material.transparent = true;
        o.material.opacity = 0;
        o.position.set(0, 3, -33.5);
        o.renderOrder = 10000;
        o.matrixNeedsUpdate = true;
      }
      if (o.name === "Exit_glow") {
        o.material = auraMat;
      }
    });
  }
  if (APP.hub?.scene?.name === "Boss Metaverse 2") {
    scene.traverse(o => {
      if (o.isMesh && o.material) {
        if (o.material?.map) {
          o.material.map.encoding = THREE.LinearEncoding;
          o.material.map.magFilter = THREE.LinearFilter;
          o.material.map.minFilter = THREE.LinearFilter;
        }
        if (o.name.includes("Floor")) {
          o.layers.set(1000);
        }
        if (o.name.includes("Star") || o.name.includes("Light_objects") || o.name === "fence") {
          // o.material.metalness = 0.2;
          const prevMat = o.material;
          o.material = new THREE.MeshMatcapMaterial({ matcap: txLoader.load(silver) });
          o.material.map = prevMat.map;
          o.material.color = new THREE.Color(0xffffff);
          if (o.name.includes("Light_objects")) {
            o.layers.set(1000);
          }
        } else {
          if (!o.isSkinnedMesh) {
            if (
              o.name !== "portal2glb" ||
              o.parent.name !== "portal2glb" ||
              o.parent.name !== "Exit_glow" ||
              o.parent.parent.name !== "portal2glb"
            ) {
              const prevMat = o.material;
              o.material = new THREE.MeshMatcapMaterial({ matcap: txLoader.load(silver) });
              o.material.map = prevMat.map;
              o.material.color = new THREE.Color(0xffffff);
            }
          } else {
            // rodrigess tweaks
            if (o.material.metalnessMap) {
              o.material.metalnessMap = null;
            }
            if (o.material.roughnessMap) {
              o.material.roughnessMap = null;
            }
            o.frustumCulled = false;
            if (o.name === "head_geo") {
              o.material.color = new THREE.Color(0xaaaaaa);
              o.material.metalness = 1;
              o.material.roughness = 0.75;
            }
            if (o.name === "hairM_06") {
              o.material.color = new THREE.Color(0x090909);
            }
            o.material.metalness = 0.85;
            o.material.roughness = 0.75;
            if (o.material.name.includes("cloth_tex")) {
              o.material.color = new THREE.Color(0xeeeeee);
              o.material.metalness = 0.9;
              o.material.roughness = 1;
              o.material.needsUpdate = true;
              o.material.normalScale = new THREE.Vector3(0.9, 0.9);
            }
            if (o.name === "tefy_geo") {
              const prevMat = o.material;
              o.material = new THREE.MeshMatcapMaterial({ matcap: txLoader.load(silver) });
              o.material.map = prevMat.map;
              o.material.color = new THREE.Color(0xffffee);
            }
            o.material.roughness = 1;
            o.material.side = THREE.DoubleSide;
          }
        }
        if (o.name === "Exit_glow") {
          o.material = auraMat;
        }
        o.material.toneMapped = false;
        o.material.needsUpdate = true;
      }
      if (o.userData.eventId === "portal" || o.userData.eventId === "hub") {
        o.material.transparent = true;
        o.material.opacity = 0;
        o.position.setZ(19);
        o.renderOrder = 10000;
        o.matrixNeedsUpdate = true;
      }
    });
    const circle1 = new THREE.CircleGeometry(22, 64);
    const reflector = new Reflector(circle1, {
      textureWidth: 1024,
      textureHeight: 1024,
      color: 0xffffff
    });

    reflector.material.transparent = true;
    reflector.position.y = -1.75;
    reflector.rotation.x = -Math.PI / 2;
    reflector.name = "reflector";
    reflector.renderOrder = 1000;
    reflector.updateMatrix();
    window.reflector = reflector;
    scene.add(reflector);
  }
  if (scene.getObjectByName("Glass")) {
    scene.getObjectByName("Glass").traverse(o => {
      if (o.isMesh) {
        o.material = new THREE.MeshMatcapMaterial({ matcap: txLoader.load(glass) });
        o.material.transparent = true;
        o.material.color = color;
        o.material.opacity = 0.15;
      }
    });
  }
}

export function modifyVideoBrightness(scene) {
  const object3D = scene.getObjectByName("Video");
  if (object3D) {
    object3D.el.classList.remove("interactable");
    object3D.traverse(o => {
      if (o.isMesh) {
        if (o.material?.map) {
          o.material.map.encoding = THREE.LinearEncoding;
          o.material.map.magFilter = THREE.LinearFilter;
          o.material.map.minFilter = THREE.LinearFilter;
          o.material.needsUpdate = true;
        }
      }
    });
  }
}
