
export const HoloVideoObjectStates = {
  Closed: -1,
  Empty: 0,
  Opening: 1,
  Opened: 2,
  Playing: 3,
  Paused: 4
} as const;

