import React from "react";

import styles from "./styles-sent.scss";

import bigFrame from "./assets/images/big-frame.png";
import bottomLeftAngle from "./assets/images/bottom-left-angle.png";
import topRightAngle from "./assets/images/top-right-angle.png";
import closeBtnFillerDefault from "./assets/images/cross_01.png";
import closeBtnFillerActive from "./assets/images/cross_02.png";
import topLightning from "./assets/images/top-lightning.png";
import topStar from "./assets/images/top-star.png";
import bottomLightning from "./assets/images/bottom-lightning.png";
import bottomStar from "./assets/images/bottom-star.png";

/* 
handleCloseModalWindow - используем, чтобы закрыть окно
*/

type AskBossSentProps = {
  handleCloseModalWindow: () => void;
};

export const AskBossSent: React.FC<AskBossSentProps> = ({ handleCloseModalWindow }) => {
  return (
    <div className={styles[`modal-window`]}>
      <div className={styles["modal-window-frame"]}>
        <div className={styles["top-right-angle"]} style={{ backgroundImage: `url(${topRightAngle})` }}></div>
        <div className={styles["bottom-left-angle"]} style={{ backgroundImage: `url(${bottomLeftAngle})` }}></div>
        <div className={styles["big-frame"]} style={{ backgroundImage: `url(${bigFrame})` }}></div>

        <img className={styles["top-lightning"]} src={topLightning} alt="" />
        <img className={styles["top-star"]} src={topStar} alt="" />

        <img className={styles["bottom-lightning"]} src={bottomLightning} alt="" />
        <img className={styles["bottom-star"]} src={bottomStar} alt="" />
      </div>

      <div className={styles["modal-window-content"]}>
        <div className={styles["modal-window-overlay"]}>
          <div className={styles["modal-window-overlay-glow"]}></div>
        </div>
        <div className={styles["modal-window-flex"]}>
          <h5 className={styles["modal-window-caption"]}>
            Спасибо! <br />
            Ваш вопрос отправлен.
          </h5>
        </div>
      </div>

      <button className={styles["close-btn"]} onClick={handleCloseModalWindow} aria-label="Закрыть окно">
        <span
          className={`${styles["close-btn-filler"]} ${styles["close-btn-filler-default"]}`}
          style={{ backgroundImage: `url(${closeBtnFillerDefault})` }}
        ></span>
        <span
          className={`${styles["close-btn-filler"]} ${styles["close-btn-filler-active"]}`}
          style={{ backgroundImage: `url(${closeBtnFillerActive})` }}
        ></span>
      </button>
    </div>
  );
};
