import { MeshBasicMaterial, MeshStandardMaterial, MeshPhongMaterial, PMREMGenerator } from "three";

AFRAME.registerComponent("cube-env-map", {
  schema: {
    path: { default: "/assets/custom/env/default/" },
    extension: { default: "png" }
  },

  init: function () {
    this.el.addEventListener("model-loaded", this.applyEnvMap.bind(this));
  },

  applyEnvMap: async function () {
    const data = this.data;

    this.texture = await new THREE.CubeTextureLoader().load([
      data.path + "px." + data.extension,
      data.path + "nx." + data.extension,
      data.path + "py." + data.extension,
      data.path + "ny." + data.extension,
      data.path + "pz." + data.extension,
      data.path + "nz." + data.extension
    ]);
    // this.texture.needsPMREMUpdate = true;
    const mesh = this.el.getObject3D("mesh");
    const envMap = this.texture;
    if (!mesh) return;

    mesh.traverse(function (node) {
      if (node.material && "envMap" in node.material) {
        const prevMaterial = node.material;
        node.material = new MeshStandardMaterial();
        MeshBasicMaterial.prototype.copy.call(node.material, prevMaterial);
        node.material.envMap = envMap;
        node.material.roughness = 0.0;
        node.material.roughness = 0.8;
        node.material.needsUpdate = true;
      }
    });
  }
});
