/** @jsx createElementEntity */
import { Layers } from "../../components/layers";
import { createElementEntity, createRef } from "../../utils/jsx-entity";
import { ButtonSprite } from "../button/button";

import { textureLoader } from "../../utils/media-utils";
import makePhotoImg from "../../assets/custom/photshoot/make_photo_hover.png"
import cancelPhotoImg from "../../assets/custom/photshoot/cancel_photo.png"
import changeAvatarImg from "../../assets/custom/photshoot/change_avatar_hover.png"


// eslint-disable-next-line react/prop-types
export function Label({ text = {}, ...props }, ...children) {
  const value = children.join("\n");
  return <entity name="Label" text={{ value, ...text }} layers={1 << Layers.CAMERA_LAYER_UI} {...props} />;
}

const RENDER_WIDTH = 1280;
const RENDER_HEIGHT = 720;
const makeBtn = textureLoader.load(makePhotoImg);
const canceBtn = textureLoader.load(cancelPhotoImg);
const changeBtn = textureLoader.load(changeAvatarImg);

// We intentionally do not remove this model from the GLTF Cache
// preload(loadModel(cameraModelSrc, null, true));

export function CameraToolTransparent() {
  const cameraTool = {
    snapMenuRef:createRef(),
    snapRef:createRef(),
    cancelRef:createRef(),
    cameraRef:createRef(),
    countdownLblRef:createRef(),
    captureDurLblRef:createRef(),
    changeAvatar:createRef(),
  }

  const camera = new THREE.PerspectiveCamera(50, RENDER_WIDTH / RENDER_HEIGHT, 0.1, 30000);
  camera.layers.enable(Layers.CAMERA_LAYER_VIDEO_TEXTURE_TARGET);
  camera.layers.enable(Layers.CAMERA_LAYER_THIRD_PERSON_ONLY);
  camera.layers.enable(Layers.CAMERA_LAYER_PHOTOSHOOT);
  const buttonScale = [0.5, 0.5, 0.5];
  const buttonPosition = [0.0, -0.1, 0.0];
  // const uiZ = 0.05;
  return (
    <entity
      name="Camera Tool"
      cursorRaycastable
      cameraTool={cameraTool}
    >
      <entity name="Camera" ref={cameraTool.cameraRef} object3D={camera} position={[0, -0.2, 0]} rotation={[0, Math.PI, 0]}/>

      <entity name="Snap Menu" ref={cameraTool.snapMenuRef} rotation={[0, 0, 0]} position={[0, -0.05, 0]} scale={[0.5, 0.5, 0.5]}>
        <Label name="CountDown" ref={cameraTool.countdownLblRef} position={[0, -0.35, 0]} />
        <Label name="CaptureDur" ref={cameraTool.captureDurLblRef} position={[0, -0.35, 0]} />

        <ButtonSprite
          ref={cameraTool.snapRef}
          name={"makePhoto"}
          scale={buttonScale}
          position={buttonPosition}
          image={{
            texture: makeBtn, 
            ratio: 1/4,
            alphaMode:"mask"
          }}
        />
        <ButtonSprite
          ref={cameraTool.cancelRef}
          name={"cancelPhoto"}
          scale={buttonScale}
          position={buttonPosition}
          image={{
            texture: canceBtn, 
            ratio: 1/4,
            alphaMode:"mask"
          }}
        />

        <ButtonSprite
          ref={cameraTool.changeAvatar}
          name={"changeAvatar"}
          scale={buttonScale}
          position={[0.0, -0.24, 0.0]}
          image={{
            texture: changeBtn, 
            ratio: 1/4,
            alphaMode:"mask"
          }}
        />

      </entity>
    </entity>
  );
}
