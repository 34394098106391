/** @jsx createElementEntity */
import "react";
import { COLLISION_LAYERS } from "../constants";
import { createElementEntity, createRef } from "../utils/jsx-entity";
import { Button } from "./camera-tool";
import { BUTTON_TYPES } from "../systems/single-action-button-system";

export function VolumetricPrefab() {
  const volumetricRef = createRef();
  const deleteRef = createRef();

  const group = new THREE.Group();

  return (
    <entity
      name="Volumetric record"
      ref={volumetricRef}
      volumetric={{
        deleteRef
      }}
      networked
      networkedTransform
      cursorRaycastable
      remoteHoverTarget
      handCollisionTarget
      offersRemoteConstraint
      offersHandConstraint
      floatyObject
      destroyAtExtremeDistance
      holdable
      rigidbody={{
        gravity: -9.8,
        collisionGroup: COLLISION_LAYERS.INTERACTABLES,
        collisionMask:
          COLLISION_LAYERS.HANDS |
          COLLISION_LAYERS.ENVIRONMENT |
          COLLISION_LAYERS.INTERACTABLES |
          COLLISION_LAYERS.AVATAR
      }}
      physicsShape={{ halfExtents: [0.5, 0.05, 0.5] }}
      object3D={group}
    >
      <Button
        name="Volumetric Button"
        ref={deleteRef}
        // scale={buttonScale}
        position={[0, 0.3, -0.3]}
        rotation={[0, -3.14, 0]}
        width={0.4}
        height={0.2}
        type={BUTTON_TYPES.ACTION}
        text={"Delete"}
        visible={false}
      />
    </entity>
  );
}
