
// @ts-expect-error
var $jscomp = $jscomp || {};
var $jscomp = $jscomp || {};
$jscomp.scope = {};
$jscomp.createTemplateTagFirstArg = function (a: any) {
  return (a.raw = a);
};
$jscomp.createTemplateTagFirstArgWithRaw = function (a: any, d: any) {
  a.raw = d;
  return a;
};
$jscomp.ASSUME_ES5 = !1;
$jscomp.ASSUME_NO_NATIVE_MAP = !1;
$jscomp.ASSUME_NO_NATIVE_SET = !1;
$jscomp.SIMPLE_FROUND_POLYFILL = !1;
$jscomp.ISOLATE_POLYFILLS = !1;
$jscomp.defineProperty =
  $jscomp.ASSUME_ES5 || "function" == typeof Object.defineProperties
    ? Object.defineProperty
    : function (a: typeof Array.prototype | typeof Object.prototype, d: string, e: Record<string, unknown>) {
        if (a == Array.prototype || a == Object.prototype) return a;
        // @ts-expect-error
        a[d] = e.value;
        return a;
      };
$jscomp.getGlobal = function (a: any) {
  a = [
    "object" == typeof globalThis && globalThis,
    a,
    "object" == typeof window && window,
    "object" == typeof self && self,
    "object" == typeof global && global
  ];
  for (var d = 0; d < a.length; ++d) {
    var e = a[d];
    if (e && e.Math == Math) return e;
  }
  throw Error("Cannot find global object");
};
$jscomp.global = $jscomp.getGlobal(this);
$jscomp.IS_SYMBOL_NATIVE = "function" === typeof Symbol && "symbol" === typeof Symbol("x");
$jscomp.TRUST_ES6_POLYFILLS = !$jscomp.ISOLATE_POLYFILLS || $jscomp.IS_SYMBOL_NATIVE;
$jscomp.polyfills = {};
$jscomp.propertyToPolyfillSymbol = {};
$jscomp.POLYFILL_PREFIX = "$jscp$";
var $jscomp$lookupPolyfilledValue = function (a: any, d: any) {
  var e = $jscomp.propertyToPolyfillSymbol[d];
  if (null == e) return a[d];
  e = a[e];
  return void 0 !== e ? e : a[d];
};
$jscomp.polyfill = function (a: any, d: any, e: any, b: any) {
  d && ($jscomp.ISOLATE_POLYFILLS ? $jscomp.polyfillIsolated(a, d, e, b) : $jscomp.polyfillUnisolated(a, d, e, b));
};
$jscomp.polyfillUnisolated = function (a: any, d: any, e: any, b: any) {
  e = $jscomp.global;
  a = a.split(".");
  for (b = 0; b < a.length - 1; b++) {
    var f = a[b];
    if (!(f in e)) return;
    e = e[f];
  }
  a = a[a.length - 1];
  b = e[a];
  d = d(b);
  d != b &&
    null != d &&
    $jscomp.defineProperty(e, a, {
      configurable: !0,
      writable: !0,
      value: d
    });
};
$jscomp.polyfillIsolated = function (a: any, d: any, e: any, b: any) {
  var f = a.split(".");
  a = 1 === f.length;
  b = f[0];
  b = !a && b in $jscomp.polyfills ? $jscomp.polyfills : $jscomp.global;
  for (var k = 0; k < f.length - 1; k++) {
    var c = f[k];
    if (!(c in b)) return;
    b = b[c];
  }
  f = f[f.length - 1];
  e = $jscomp.IS_SYMBOL_NATIVE && "es6" === e ? b[f] : null;
  d = d(e);
  null != d &&
    (a
      ? $jscomp.defineProperty($jscomp.polyfills, f, {
          configurable: !0,
          writable: !0,
          value: d
        })
      : d !== e &&
        (($jscomp.propertyToPolyfillSymbol[f] = $jscomp.IS_SYMBOL_NATIVE
          ? $jscomp.global.Symbol(f)
          : $jscomp.POLYFILL_PREFIX + f),
        (f = $jscomp.propertyToPolyfillSymbol[f]),
        $jscomp.defineProperty(b, f, {
          configurable: !0,
          writable: !0,
          value: d
        })));
};
$jscomp.polyfill(
  "Object.is",
  function (a: any) {
    return a
      ? a
      : function (d: any, e: any) {
          return d === e ? 0 !== d || 1 / d === 1 / e : d !== d && e !== e;
        };
  },
  "es6",
  "es3"
);
$jscomp.polyfill(
  "Array.prototype.includes",
  function (a: any) {
    return a
      ? a
      : function (d: any, e: any) {
          var b = this;
          b instanceof String && (b = String(b));
          var f = b.length;
          e = e || 0;
          for (0 > e && (e = Math.max(e + f, 0)); e < f; e++) {
            var k = b[e];
            if (k === d || Object.is(k, d)) return !0;
          }
          return !1;
        };
  },
  "es7",
  "es3"
);
$jscomp.checkStringArgs = function (a: any, d: any, e: any) {
  if (null == a) throw new TypeError("The 'this' value for String.prototype." + e + " must not be null or undefined");
  if (d instanceof RegExp)
    throw new TypeError("First argument to String.prototype." + e + " must not be a regular expression");
  return a + "";
};
$jscomp.polyfill(
  "String.prototype.includes",
  function (a: any) {
    return a
      ? a
      : function (d: any, e: any) {
          return -1 !== $jscomp.checkStringArgs(this, d, "includes").indexOf(d, e || 0);
        };
  },
  "es6",
  "es3"
);
