import { loadCustomContent, modifyObjectMaterial, modifyVideoBrightness } from "./content";
import { modifyObjectShader, shaderTick } from "./shaders";

AFRAME.registerSystem("boss-metaverse-content-tweaks", {
  init() {
    this.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded);
  },
  onSceneLoaded() {
    if (APP.hub?.scene?.name === "boss-metaverse" || APP.hub?.scene?.name === "Boss Metaverse 2") {
      const scene = document.getElementById("environment-scene").object3D;
      scene.traverse(object => {
        modifyObjectMaterial(object);
      });
      loadCustomContent(scene);
      setTimeout(() => {
        modifyVideoBrightness(scene);
      }, 5000);
    }
  },
  tick(t) {
    shaderTick(t);
  },
  remove() {
    this.sceneEl.removeEventListener("environment-scene-loaded", this.onSceneLoaded);
  }
});
