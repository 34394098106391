import React from "react";

import styles from "../../assets/stylesheets/quiz-ui.scss";

type AnswerProps = {
  isClicked: boolean;
  onClick: () => void;
  correct: boolean;
  value: string;
  isZvuk: boolean;
};

export const Answer: React.FC<AnswerProps> = ({ isClicked, onClick, correct, value, isZvuk }) => {
  return (
    <button
      className={`${isClicked ? "" : isZvuk ? styles.answerZvuk : styles.answerDefault} ${
        !isClicked ? styles.answerBase : correct ? styles.answerTrue : styles.answerFalse
      }`}
      onClick={onClick}
      disabled={isClicked}
    >
      <span className={styles.answerOverlay}></span>
      {value}
    </button>
  );
};
