import { findAncestorWithComponent } from "../utils/scene-graph";

/**
 * Loops the given clip using this entity's animation mixer
 * @component loop-animation
 */
AFRAME.registerComponent("loop-animation", {
  schema: {
    paused: { type: "boolean", default: false },
    /* DEPRECATED: Use activeClipIndex instead since animation names are not unique */
    clip: { type: "string", default: "" },
    activeClipIndex: { type: "int", default: 0 },
    startOffset: { type: "number", default: 0 },
    timeScale: { type: "number", default: 1 },
    activeClipIndices: { type: "array" }
  },

  init() {
    this.mixerEl = findAncestorWithComponent(this.el, "animation-mixer");
    this.currentActions = [];

    if (!this.mixerEl) {
      console.warn("loop-animation component could not find an animation-mixer in its ancestors.");
      return;
    }
  },

  update(oldData) {
    if (this.mixerEl) {
      if (oldData.clip !== this.data.clip || oldData.activeClipIndex !== this.data.activeClipIndex) {
        this.updateClip();
      }

      if (oldData.paused !== this.data.paused) {
        for (let i = 0; i < this.currentActions.length; i++) {
          this.currentActions[i].paused = this.data.paused;
        }
      }
    }
  },

  updateClip() {
    const { animations } = this.mixerEl.components["animation-mixer"];
    const { clip: clipName, activeClipIndex } = this.data;
    const { activeClipIndices } = this.data;

    if (!animations || animations.length === 0) {
      return;
    }

    let clips = [];
    if (activeClipIndices && activeClipIndices.length > 0) {
      // Support for Spoke->Hubs activeClipIndices struct
      clips = activeClipIndices.map(index => animations[index]);
    } else {
      // Support for old Spoke->Hubs { clipName, activeClipIndex } struct. Still used for Blender imports.
      if (clipName !== "") {
        const clipNames = clipName.split(",");
        for (let i = 0; i < clipNames.length; i++) {
          const n = clipNames[i];
          const a = animations.find(({ name }) => name === n);
          if (a) {
            clips.push(a);
          } else {
            console.warn(`Could not find animation named '${n}' in ${this.el.className}`);
          }
        }
      } else {
        clips = [animations[activeClipIndex]];
      }
    }

    if (!(clips && clips.length)) return;

    this.currentActions.length = 0;

    for (let i = 0; i < clips.length; i++) {
      this._addClip(clips[i])
    }
  },

  // Установит новый клип анимации стерев все остальные другой
  setClip(clipName,startTime = 0){
    const newClip = this.getClipFromGlobal(clipName)
    if (!newClip) return;

    this.removeClipAll()
    this._addClip(newClip,startTime)
  },

  // Установит Клипы анимаций из массива анимаций
  setClips(clipNames,startTime = 0){if (!Array.isArray(clipNames)) return;
    this.removeClipAll()

    clipNames.forEach(element => {
      const newClip = this.getClipFromGlobal(element)
      if (!newClip) return;

      this._addClip(newClip,startTime)
    });
  },

  // Вернёт клип по имени или индексу
  getClip(clipSelector){
    let clip = false;
    if(typeof clipSelector === 'number'){clip = this.currentActions[clipSelector].getClip()}
    else if(typeof clipSelector === 'string'){clip = this.currentActions.find(element => clipSelector == element.getClip().name)}
    
    return (!clip) ? clip : clip.getClip();
  },

  // Вернёт клип по имени или индексу
  getCurrentActions(){ 
    return this.currentActions
  },

  // Вернёт клип ИЗ глобального массива по имени или индексу
  getClipFromGlobal(clipSelector){
    let clip = false;
    const { animations } = this.mixerEl.components["animation-mixer"];

    if(typeof clipSelector === 'number'){clip = animations[clipSelector]}
    else if(typeof clipSelector === 'string'){clip = animations.find(element => clipSelector == element.name)}
    
    return clip;
  },

   // Вернёт все клипы из глобального массива 
  getClipAllFromGlobal(){
    const { animations } = this.mixerEl.components["animation-mixer"];

    return animations
   },

  // Полностью очистит все клипы анимаций
  removeClipAll(){
    for (let i = 0; i < this.currentActions.length; i++) {
      this.currentActions[i].enabled = false;
      this.currentActions[i].stop();
    }
    this.currentActions.length = 0
  },

  // Добавит клип по его имени, к массиву воспроизведения
  addClip(clipName,startTime = 0){ if (!clipName && typeof clipName != 'string') return;
    const newClip = this.getClipFromGlobal(clipName)
    if (!newClip) return;

    this._addClip(newClip,startTime)
  },

  // Уберёт клип по его имени, из массива воспроизведения
  removeClip(clipName){ if (!clipName && typeof clipName != 'string') return;
    const clipKey = Object.keys(this.currentActions).find(key => this.currentActions[key].getClip().name === clipName)
    if(clipKey == undefined) return

    this._destroyClip(clipKey)
  },

  destroy() {
    for (let i = 0; i < this.currentActions.length; i++) {
      this.currentActions[i].enabled = false;
      this.currentActions[i].stop();
    }
    this.currentActions.length = 0;
  },

  // Добавит новый клип анимации к уже имеющимся
  _addClip(newClip,startTime = 0){
    if (!newClip) return;
    const { mixer } = this.mixerEl.components["animation-mixer"];
    const action = mixer.clipAction(newClip, this.el.object3D);
    
    action.enabled = true;
    action.time = startTime;
    action.timeScale = this.data.timeScale;
    action.setLoop(THREE.LoopRepeat, Infinity).play();

    this.currentActions.push(action);
  },

  // Удалит 1 клип из массива воспроизведения по ключу
  _destroyClip(clipKey){
    this.currentActions[clipKey].enabled = false;
    this.currentActions[clipKey].stop();
    this.currentActions.splice(clipKey, 1);
  },
});
