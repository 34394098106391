
export const COLLIDABLE_MODELS = {
  cats2: {
    eventId: 'cats-collision-2'
  },
  quiz: {
    eventId: 'quiz-collision'
  },
  volumetricPlay: {
    eventId: 'volumetric-play'
  },
  triggerZone: {
    eventId: 'trigger-zone'
  },
} as const
