import {
  CAMERA_MODE_FIRST_PERSON,
} from "../../../systems/camera-system";
import { CameraToolTransparent } from "../camera-tool-transparent";
import { renderAsEntity } from "../../../utils/jsx-entity";

const DEBUG_BBAA_COLOR = 0xff00ff;

export function PhotoshootTransparent() {
  const debugMaterial = new THREE.MeshBasicMaterial({
    color: DEBUG_BBAA_COLOR,
    transparent: true,
    opacity: 0.25,
    side: THREE.DoubleSide
  });

  AFRAME.registerComponent("photoshoot", {
    schema: {
      enabled: { default: true },
      debuggable: { default: false }
    },

    init() {
      const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      if (this.data.debuggable) {
        this.helper = new THREE.CameraHelper(camera);
        this.el.object3D.add(this.helper);
      }
      this.el.className = "photoshoot";
      const eid = renderAsEntity(APP.world, CameraToolTransparent());
      const obj = APP.world.eid2obj.get(eid);

      this.el.object3D.children[0].visible = false // Отключим 1й мешь элемент для отображения ( он же камера )

      this.menu = obj.getObjectByName("Snap Menu");
      this.el.object3D.add(obj);
    },

    tick() {

    },

    debug(debuggable) {
      this.data.debuggable = debuggable;
      this.helper.visible = debuggable;
    }
  });

  AFRAME.GLTFModelPlus.registerComponent("photoshoot-camera", "photoshoot", (el, componentName, componentData) => {
    el.setAttribute(componentName, { ...componentData });
  });

  AFRAME.registerComponent("photoshoot-zone", {
    schema: {
      enabled: { default: true },
      debuggable: { default: true },
      mode: { default: "FP" }
    },
    init: function () {
      const debugGeometry = new THREE.BoxGeometry();
      this.cameraMode = CAMERA_MODE_FIRST_PERSON;
      this.debugMesh = new THREE.Mesh(debugGeometry, debugMaterial);
      this.debugMesh.el = this.el.object3D.el;
      this.el.object3D.updateMatrixWorld(true);
    },

    isEnabled() {
      return this.data.enabled;
    },

    debug(debuggable) {
      this.data.debuggable = debuggable;
      this.el.object3D.visible = debuggable;
    },

    setCameraMode(mode) {
      this.cameraMode = mode;
      AFRAME.scenes[0].systems["hubs-systems"].cameraSystem.setMode(mode);
    },

  });

  AFRAME.GLTFModelPlus.registerComponent("photoshoot-zone", "photoshoot-zone", (el, componentName, componentData) => {
    el.setAttribute(componentName, { ...componentData });
  });
}