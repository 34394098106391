import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../react-components/input/Button";
import styles from "../../../react-components/room/AvatarSettingsContent.scss";

type AvatarSettingsContentCustomProps = {
  avatarPreview: JSX.Element | null;
  uploadingState: boolean;
  onEditAvatar: Function;
};

export const AvatarSettingsContentCustom: React.FC<AvatarSettingsContentCustomProps> = ({
  avatarPreview,
  uploadingState,
  onEditAvatar
}) => {
  return null;

  return (
    <>
      <div className={styles.avatarPreviewContainer}>
        {avatarPreview || <div />}
        {uploadingState && (
          //   <Button type="button" preset="basic" onClick={onChangeAvatar}>
          //   <FormattedMessage id="avatar-settings-content.change-avatar-button" defaultMessage="Change Avatar" />
          // </Button>
          <FormattedMessage id="avatar-settings-content.uploading-avatar-button" defaultMessage="Загрузка" />
        )}
      </div>

      {/* @ts-expect-error */}
      <Button type="button" disabled={uploadingState} preset="editor" onClick={onEditAvatar}>
        <FormattedMessage id="avatar-settings-content.avatar-editor-button" defaultMessage="Настроить аватар" />
      </Button>
    </>
  );
};
