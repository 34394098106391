// import { fetchReticulumAuthenticated } from "./phoenix-utils";
// import defaultAvatar from "../assets/models/DefaultAvatar.glb";
import business from "../assets/custom/models/businessman.glb";
// import beauty from "../assets/custom/models/beauty.glb";
import beauty2 from "../assets/custom/models/beauty2.glb";
import butterfly from "../assets/custom/models/butterfly.glb";
import itgeek from "../assets/custom/models/itgeek.glb";
import rich from "../assets/custom/models/rich.glb";
import { getHashValue } from "../custom/utils/url";

const names = ["Guest"];
// const names2 = [
//   "Baers-Pochard",
//   "Baikal-Teal",
//   "Barrows-Goldeneye",
//   "Blue-Billed",
//   "Blue-Duck",
//   "Blue-Winged",
//   "Brown-Teal",
//   "Bufflehead",
//   "Canvasback",
//   "Cape-Shoveler",
//   "Chestnut-Teal",
//   "Chiloe-Wigeon",
//   "Cinnamon-Teal",
//   "Comb-Duck",
//   "Common-Eider",
//   "Common-Goldeneye",
//   "Common-Merganser",
//   "Common-Pochard",
//   "Common-Scoter",
//   "Common-Shelduck",
//   "Cotton-Pygmy",
//   "Crested-Duck",
//   "Crested-Shelduck",
//   "Eatons-Pintail",
//   "Falcated",
//   "Ferruginous",
//   "Freckled-Duck",
//   "Gadwall",
//   "Garganey",
//   "Greater-Scaup",
//   "Green-Pygmy",
//   "Grey-Teal",
//   "Hardhead",
//   "Harlequin",
//   "Hartlaubs",
//   "Hooded-Merganser",
//   "Kelp-Goose",
//   "King-Eider",
//   "Lake-Duck",
//   "Laysan-Duck",
//   "Lesser-Scaup",
//   "Long-Tailed",
//   "Maccoa-Duck",
//   "Mallard",
//   "Mandarin",
//   "Marbled-Teal",
//   "Mellers-Duck",
//   "Merganser",
//   "Northern-Pintail",
//   "Orinoco-Goose",
//   "Paradise-Shelduck",
//   "Plumed-Whistler",
//   "Puna-Teal",
//   "Pygmy-Goose",
//   "Radjah-Shelduck",
//   "Red-Billed",
//   "Red-Crested",
//   "Red-Shoveler",
//   "Ring-Necked",
//   "Ringed-Teal",
//   "Rosy-Billed",
//   "Ruddy-Shelduck",
//   "Salvadoris-Teal",
//   "Scaly-Sided",
//   "Shelduck",
//   "Shoveler",
//   "Silver-Teal",
//   "Smew",
//   "Spectacled-Eider",
//   "Spot-Billed",
//   "Spotted-Whistler",
//   "Steamerduck",
//   "Stellers-Eider",
//   "Sunda-Teal",
//   "Surf-Scoter",
//   "Tufted-Duck",
//   "Velvet-Scoter",
//   "Wandering-Whistler",
//   "Whistling-duck",
//   "White-Backed",
//   "White-Cheeked",
//   "White-Winged",
//   "Wigeon",
//   "Wood-Duck",
//   "Yellow-Billed"
// ];

/**
 * Индекс текущего аватара
 */
let keyAvatar = 0;
let keyAvatarFirst = true;

function chooseRandom(arr) {
  // я незнаю почему но, эта функция вызывается несколько раз... по этому такой костыль
  if (keyAvatarFirst) {
    keyAvatar = Math.floor(Math.random() * arr.length);
    keyAvatarFirst = false;
  }
  return arr[keyAvatar];
}

const avatarsList = {
  businessman: business,
  butterfly: butterfly,
  // beauty: beauty,
  beauty2: beauty2,
  itgeek: itgeek,
  rich: rich
};

// const items = [business, butterfly, beauty2, itgeek, rich];
const items = Object.values(avatarsList);

/**
 * Возвращаяет индекс текущего аватара
*/
export function getAvatarIndex() {
  
  return keyAvatar
}


export function getDefaultAvatar() {
  if (getHashValue("avatar")) {
    return avatarsList[getHashValue("avatar")];
  }
  return chooseRandom(items);
}

function getCookie(name) {
  const cookieStr = document.cookie;
  if (!cookieStr) return "";
  const nameEQ = name + "=";
  const cookies = cookieStr.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length);
    }
  }
  return "";
}


export function generateRandomName() {
  return getCookie("nickname") || `${chooseRandom(names)}-${Math.floor(100 + Math.random() * 100)}`;
}

export function getNextAvatar() {
  keyAvatar++;
  if (keyAvatar >= items.length) {
    keyAvatar = 0;
  }
  return items[keyAvatar];
}

export async function fetchRandomDefaultAvatarId() {
  // const defaultAvatarEndpoint = "/api/v1/media/search?filter=default&source=avatar_listings";
  // const defaultAvatars = (await fetchReticulumAuthenticated(defaultAvatarEndpoint)).entries || [];
  // if (defaultAvatars.length === 0) {
  // If reticulum doesn't return any default avatars, just default to the duck model. This should only happen
  // when running against a fresh reticulum server, e.g. a local ret instance.
  return new URL(getDefaultAvatar(), location.href).href;
  // }
  // const avatarIds = defaultAvatars.map(avatar => avatar.id);
  // return chooseRandom(avatarIds);
}

export async function fetchNextAvatarId() {
  return new URL(getNextAvatar(), location.href).href;
}
