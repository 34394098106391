import { addComponent, hasComponent } from "bitecs";
import { RemoteHoverTarget } from "../../bit-components";

AFRAME.registerComponent("clickable", {
  init: function () {
    if (this.el.object3D) {
      this.makeClickable();
    }
  },
  makeClickable() {
    !hasComponent(APP.world, RemoteHoverTarget, this.el.eid) && addComponent(APP.world, RemoteHoverTarget, this.el.eid);
    this.el.classList.add("interactable");
    this.el.setAttribute("tags", { singleActionButton: true });
  }
});

AFRAME.GLTFModelPlus.registerComponent("clickable", "clickable", (el, componentName, componentData) => {
  el.setAttribute(componentName, { ...componentData });
});
