import { AElement } from "aframe";
import { useEffect } from "react";
import { CollisionEventDetail } from "../Collect/helpers/onCollisionCollect";
import { COLLIDABLE_MODELS } from "../collidable-model/constants";
// import { triggableVolumetrics } from "../volumetric/component";
import { IHoloVideoObjectThreeJS } from "../HoloMediaPlayer/HoloMediaPlayer/interfaces";
import { stepByStepVolumetricPlayer } from "../HoloMediaPlayer/StepByStepVolumetricPlayer";

const CollectCollisionEventName = COLLIDABLE_MODELS.volumetricPlay.eventId;

export const TriggableVolumetric: React.FC = () => {
  useEffect(() => {
    const onCollision = (event: CustomEvent<CollisionEventDetail>) => {
      /**
       * Находим дочерний компонент с волюметриком
       */
      const volumetricElement = event.detail.mesh.el?.querySelector("[volumetric]") as AElement | null;

      if (!volumetricElement) {
        console.error("Can not get volumetricElement");
        return;
      }

      const volumetricAttribute = volumetricElement.getAttribute("volumetric") as {
        src?: string;
      } | null;

      if (!volumetricAttribute?.src) {
        console.error("Can not get volumetric src");
        return;
      }

      /**
       * Получаем плейер найденного компонента
       */

      const hvo: IHoloVideoObjectThreeJS | null = volumetricElement.components.volumetric.hvo;

      if (!hvo || !hvo.hvo) {
        console.error("Can not get volumetric hvo");
        return;
      }

      stepByStepVolumetricPlayer.play(hvo);      
    };

    window.addEventListener(CollectCollisionEventName, onCollision);

    return () => {
      window.removeEventListener(CollectCollisionEventName, onCollision);
    };
  }, []);

  return null;
};
