import { isAuthed } from "../../UnauthorizedAlert/helpers";
import { StarConstructorGenerateEmageEventName } from "../interfaces";

AFRAME.registerComponent("generate-start", {
  init: function () {
    this.el.object3DMap?.mesh.addEventListener("custom-click", () => {

      if(!isAuthed()){
        return;
      }

      const event = new CustomEvent(StarConstructorGenerateEmageEventName)
      window.dispatchEvent(event);
    });
  }
});

AFRAME.GLTFModelPlus.registerComponent("generate-start", "generate-start", (el, componentName, componentData) => {
  console.log("generate-start el", el);
  el.setAttribute(componentName, componentData);
  el.setAttribute("clickable", {});
});
