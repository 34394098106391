import React, { FormEvent, MouseEvent, useCallback, useEffect, useState } from "react";

import styles from "./styles-form.scss";

// import entryScreenImg from "./assets/images/entryScreenImg.png";
import submitScreenImg from "./assets/images/submitScreenImg.png";
import closeBtnFillerDefault from "./assets/images/cross_01.png";
import closeBtnFillerActive from "./assets/images/cross_02.png";
import { askBossQuestion, vote } from "../../boss-voting";
import { AskBossSent } from "./AskBossSent";
import { ASK_BOSS_MESSAGE_MAX_LENGTH } from "../../boss-voting/interfaces";

/* 
	Я частично вставил это фотками, так как они опять прислали всё одним слоем.
	Вырезать было бы долго в условиях текущего дедлайна, плюс я посмотрел, где-то получилось бы вырезать криво, а это опять тратить время на фикс.
*/

// type FormEntryProps = {
//   handleEntrySubmit: (event: MouseEvent<HTMLButtonElement>) => void;
// };

// const FormEntry: React.FC<FormEntryProps> = ({ handleEntrySubmit }) => {
//   return (
//     <div className={styles["form-entry"]}>
//       <img className={styles["form-entry-bg"]} src={entryScreenImg} alt="" />
//       <button className={`${styles["form-entry-btn"]} ${styles["submit-btn"]}`} onClick={handleEntrySubmit}>
//         Написать вопрос
//       </button>
//     </div>
//   );
// };

const maxLength = ASK_BOSS_MESSAGE_MAX_LENGTH;

type FormSubmitProps = {
  sentSetter: React.Dispatch<React.SetStateAction<boolean>>;
};

const FormSubmit: React.FC<FormSubmitProps> = ({ sentSetter }) => {
  const [questionReq, setQuestionReq] = useState("");

  const [inRequest, inRequestSetter] = useState(false);

  const onSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      inRequestSetter(true);

      askBossQuestion(questionReq)
        .then(() => {
          vote("boss");

          sentSetter(true);
        })
        .catch((error: Error) => {
          console.error(error);
          alert(error.message);
        })
        .finally(() => {
          inRequestSetter(false);
        });
    },
    [questionReq]
  );

  useEffect(() => {
    vote("boss-modal-opened");
  }, []);

  return (
    <div className={styles["form-entry"]}>
      <img className={styles["form-entry-bg"]} src={submitScreenImg} alt="" draggable="false" />
      <form className={styles["form"]} onSubmit={onSubmit}>
        <p className={styles["form-text"]}>Не&nbsp;забудь указать, кому адресован твой вопрос.</p>
        <textarea
          className={styles["submit-input"]}
          maxLength={maxLength}
          rows={4}
          value={questionReq}
          onChange={e => setQuestionReq(e.target.value)}
        ></textarea>
        <span className={`${styles["form-note"]}`}>Ограничение до {maxLength} символов с пробелами</span>
        <button
          type="submit"
          className={`${styles["form-submit-btn"]} ${styles["submit-btn"]}`}
          disabled={!questionReq.length || inRequest}
        >
          Отправить вопрос
        </button>
      </form>
    </div>
  );
};

/* 
handleCloseModalWindow - используем, чтобы закрыть окно
*/

type AskBossFormProps = {
  handleCloseModalWindow: () => void;
};

export const AskBossForm: React.FC<AskBossFormProps> = ({ handleCloseModalWindow }) => {
  const [sent, sentSetter] = useState(false);

  return sent ? (
    <AskBossSent handleCloseModalWindow={handleCloseModalWindow} />
  ) : (
    <div className={styles["form-wrapper"]}>
      <div className={styles["form-overlay"]} onClick={handleCloseModalWindow}></div>
      <FormSubmit sentSetter={sentSetter} />
      <button className={styles["close-btn"]} onClick={handleCloseModalWindow} aria-label="Закрыть окно">
        <span
          className={`${styles["close-btn-filler"]} ${styles["close-btn-filler-default"]}`}
          style={{ backgroundImage: `url(${closeBtnFillerDefault})` }}
        ></span>
        <span
          className={`${styles["close-btn-filler"]} ${styles["close-btn-filler-active"]}`}
          style={{ backgroundImage: `url(${closeBtnFillerActive})` }}
        ></span>
      </button>
    </div>
  );
};
