import { getMeshes } from "../utils/aframe-utils";
import { changeHub } from "../change-hub";
import { getNextHub } from "../utils/get-next-hub";
import { COLLIDABLE_MODELS } from "../custom/collidable-model/constants";

const teleportUrls = new Set();
const portalUrls = new Set();

AFRAME.registerComponent("event-inspect-collider", {
  init() {
    this.mesh = new THREE.Mesh(
      new THREE.BoxGeometry(1.8, 1.8, 1.8),
      new THREE.MeshBasicMaterial({
        visible: false
      })
    );

    this.el.setObject3D("event-inspect-collider", this.mesh);
    this.tick = AFRAME.utils.throttleTick(this.tick, 500, this);
  },
  checkAndSendEvent(intersectMesh) {
    // console.log("checkAndSendEvent intersectMesh", intersectMesh);

    try {
      let mesh = intersectMesh;

      while (mesh) {
        const userData = mesh.userData;
        // console.log(userData);

        if (userData !== null && userData !== undefined) {
          const eventId = userData.eventId;
          const objectId = userData.objectId;

          const searchParams = new URLSearchParams(window.location.search);
          const start = searchParams.get("start"); // '2023-04-18T17:36:51.959Z'

          const startEventTime = new Date(start || "2023-04-19T16:45:00.0000000Z");
          const now = new Date();

          //TODO: Event backend via sendMessage
          switch (eventId) {
            case "hub":
              changeHub(userData.eventData, true);
              break;

            case "portal":
              if (portalUrls.has(objectId)) return

              portalUrls.add(objectId);

              if (now > startEventTime) {
                window.location.href = objectId;
                // changeHub(userData.objectId, true);
              }
              break;

            case "teleport":
              {
                if (!teleportUrls.has(objectId)) {
                  teleportUrls.add(objectId);

                  getNextHub(objectId)
                    .then(async hubId => {
                      hubId && (await changeHub(hubId));
                    })
                    .catch(console.error)
                    .finally(() => {
                      teleportUrls.delete(objectId);
                    });
                }
              }
              break;

            // case "quiz":
            //   {
            //     const solvedQuizes = JSON.parse(localStorage.getItem("quiz"));
            //     const isQuizSolved = solvedQuizes && Object.keys(solvedQuizes).includes(userData.quizId);
            //     if (isQuizSolved) {
            //       break;
            //     }
            //     const event = new CustomEvent("quiz_collision", { detail: { id: userData.quizId, mesh } });
            //     dispatchEvent(event);
            //   }
            //   break;

            case COLLIDABLE_MODELS.cats2.eventId:
            case COLLIDABLE_MODELS.quiz.eventId:
            case COLLIDABLE_MODELS.volumetricPlay.eventId:
            case COLLIDABLE_MODELS.triggerZone.eventId:
              {
                const event = new CustomEvent(eventId, { detail: { mesh } });
                dispatchEvent(event);
              }
              break;
            default:
              break;
          }
        }

        mesh = mesh.children[0];
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  tick() {
    const collisionEntities = Array.from(this.el.sceneEl.querySelectorAll("[body-helper]"));
    const meshes = getMeshes(collisionEntities);

    this.bbox = new THREE.Box3().setFromObject(this.mesh);

    for (const mesh of meshes) {
      if (mesh.isObject3D) {
        const bbox = new THREE.Box3().setFromObject(mesh);

        const isIntersect = this.bbox.intersectsBox(bbox);

        if (isIntersect) {
          this.checkAndSendEvent(mesh);
          break;
        }
      }
    }
  }
});
