import { ReactNode } from "react";
import ReactDOM from "react-dom";

type MainViewportModalProps = {
  children: ReactNode;
};

export const MainViewportModal: React.FC<MainViewportModalProps> = ({ children }) => {
  const container = window.document.querySelector(".mainViewport");

  if (!container) {
    return null;
  }

  return ReactDOM.createPortal(children, container);
};
